export const setQueueStatus = (status) =>
	({
		type    : 'SET_QUEUE_STATUS',
		payload : {
			status
		}
	});

export const setQueueSkillId = (skillId) =>
	({
		type    : 'SET_QUEUE_SKILL_ID',
		payload : {
			skillId : skillId || ''
		}
	});

export const updateQueueWaitState = (args) =>
	({
		type    : 'UPDATE_QUEUE_WAIT_STATE',
		payload : {
			length : args.length || 1,
			order  : args.order || 1
		}
	});
export const updateQueueWaitMinutes = (args) =>
	({
		type    : 'UPDATE_QUEUE_WAIT_MINUTES',
		payload : {
			max : args.max || 0,
			min : args.min || 0
		}
	});

export const promoted = (personalCallKey) =>
	({
		type    : 'PROMOTED',
		payload : {
			personalCallKey
		}
	});

export const setQueueError = (flag) =>
	({
		type    : 'SET_QUEUE_ERROR',
		payload : {
			flag
		}

	});

export const resetQueue = () =>
	({
		type    : 'RESET_QUEUE',
		payload : {}
	});

export const setDisconnectedFromServer = (flag) =>
	({
		type    : 'SET_DISCONNECTED_FROM_SERVER',
		payload : {
			flag
		}
	});