// firestore
import { firestore, firebaseTimestamp } from './lib/firebase';

// Daysjs
import dayjs from 'dayjs';

import { ROOM_CALLING, ROOM_ONLINE, CONSUMPTION_TAX, CONVERT_STRINGS, CODE_POS_CHANGE } from './const';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

/**
 * Create a function which will call the callback function
 * after the given amount of milliseconds has passed since
 * the last time the callback function was called.
 */
export const idle = (callback, delay) =>
{
	let handle;

	return () =>
	{
		if (handle)
		{
			clearTimeout(handle);
		}

		handle = setTimeout(callback, delay);
	};
};

/**
 * Error produced when a socket request has a timeout.
 */
export class SocketTimeoutError extends Error
{
	constructor(message)
	{
		super(message);

		this.name = 'SocketTimeoutError';

		if (Error.hasOwnProperty('captureStackTrace')) // Just in V8.
			Error.captureStackTrace(this, SocketTimeoutError);
		else
			this.stack = (new Error(message)).stack;
	}
}

export const background = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/bg_${subdomain}`;
};

export const backgroundTop = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/top_${subdomain}`;
};

export const backgroundTopSP = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/topsp_${subdomain}`;
};

export const logo = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/logo_${subdomain}`;
};

export const hiraToKata = (str) =>
{
	return str.replace(/[\u3041-\u3096]/g, (ch) =>
		String.fromCharCode(ch.charCodeAt(0) + 0x60)
	);
};

export const isZenkakuKana = (s) =>
{
	return !!s.match(/^[ァ-ヶー　]*$/); // eslint-disable-line no-irregular-whitespace, no-implicit-coercion
};

export const hostLeaveRoomUpdate = (roomId, forceLeave) =>
{
	if (roomId)
	{
		let forceLeaveVal = 0;

		if (forceLeave)
		{
			forceLeaveVal = Number(forceLeave);
		}

		firestore.collection('rooms').doc(roomId)
			.update({
				status          : 0,
				user            : '',
				hostId          : '',
				monitoring      : false,
				forceLeave      : forceLeaveVal,
				personalCallKey : '',
				skills          : []
			})
			.catch((e) =>
			{
				// eslint-disable-next-line no-console
				console.log(e);
			});
	}
};

export const setUpTargetRooms = (rooms, callPriority, targetPersonalCallKey, skillId) =>
{
	// call対象の部屋
	let roomsData = [];

	// ROOM_ONLINE, ROOM_CALLINGに加え、targetRoomSeachKeyとroomのpersonalCallKeyが一致する部屋
	if (targetPersonalCallKey)
	{
		roomsData = [
			...rooms.filter((r) => r.status === ROOM_ONLINE
				|| r.status === ROOM_CALLING || r.personalCallKey === targetPersonalCallKey)
		];
	}
	else if (skillId && skillId !== '_')
	{
		roomsData = [
			...rooms.filter((r) => r.skills.includes(skillId))
		];

		roomsData = [
			...roomsData.filter((r) => r.status === ROOM_ONLINE || r.status === ROOM_CALLING)
		];
	}
	// ROOM_ONLINE, ROOM_CALLINGのみ
	else
	{
		roomsData = [
			...rooms.filter((r) => r.status === ROOM_ONLINE || r.status === ROOM_CALLING)
		];
	}

	// 並べ替え
	// ログイン順
	if (Number(callPriority) === 1)
	{
		roomsData.sort((a, b) =>
		{
			if (a.hostLoginAt < b.hostLoginAt) { return -1; }
			if (a.hostLoginAt > b.hostLoginAt) { return 1; }

			return 0;
		});

		roomsData.sort((a, b) =>
		{
			if (a.priority < b.priority) { return -1; }
			if (a.priority > b.priority) { return 1; }

			return 0;
		});
	}
	// ランダム順
	else if (Number(callPriority) === 2)
	{

		let lastCalledRoomId = undefined;

		let maxLastCalledAt = null;

		roomsData.forEach((r) =>
		{
			if (r.lastCalledAt > maxLastCalledAt)
			{
				lastCalledRoomId = r.id;
				maxLastCalledAt = r.lastCalledAt;
			}
		});

		// lastCalledHost => moved to the end
		roomsData.forEach((item) =>
		{
			if (lastCalledRoomId && item.id === lastCalledRoomId)
			{
				item.randomValue = 101;
			}
			else
			{
				item.randomValue = Math.floor(Math.random() * 100);
			}
		});

		roomsData.sort((a, b) =>
		{
			if (a.randomValue < b.randomValue) { return -1; }
			if (a.randomValue > b.randomValue) { return 1; }

			return 0;
		});

		roomsData.sort((a, b) =>
		{
			if (a.priority < b.priority) { return -1; }
			if (a.priority > b.priority) { return 1; }

			return 0;
		});

	}
	// 待機時間順
	else if (Number(callPriority) === 3)
	{

		// has no value => first
		roomsData.forEach((item) =>
		{
			// no lastCalledAt
			if (!item.lastCalledAt)
			{
				item.lastCalledAt = 1;
			}
		});

		roomsData.sort((a, b) =>
		{
			if (a.index < b.index) { return -1; }
			if (a.index > b.index) { return 1; }

			return 0;
		});

		roomsData.sort((a, b) =>
		{
			if (a.lastCalledAt < b.lastCalledAt) { return -1; }
			if (a.lastCalledAt > b.lastCalledAt) { return 1; }

			return 0;
		});

		roomsData.sort((a, b) =>
		{
			if (a.priority < b.priority) { return -1; }
			if (a.priority > b.priority) { return 1; }

			return 0;
		});

	}
	else
	{

		roomsData.sort((a, b) =>
		{
			if (a.index < b.index) { return -1; }
			if (a.index > b.index) { return 1; }

			return 0;
		});

		roomsData.sort((a, b) =>
		{
			if (a.priority < b.priority) { return -1; }
			if (a.priority > b.priority) { return 1; }

			return 0;
		});
	}

	// 特定のhostをtargetPersonalCallKeyで指定している場合
	if (targetPersonalCallKey)
	{
		const targetRoom = roomsData.find(
			(r) => r.personalCallKey === targetPersonalCallKey
		);

		// 指定のhostがいるrooｍを最初に移動
		if (targetRoom)
		{
			// targetRoomを削除
			roomsData = roomsData.filter((r) => r.personalCallKey !== targetPersonalCallKey);

			// targetRoomを最初に追加
			roomsData.unshift(targetRoom);
		}
	}

	// call対象となるroomを確定(途中でroomが増えても追加しない)
	return roomsData;
};

const zenkakuToHankaku = (str) =>
{
	return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
	{
		return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
	});
};

export const handleGuestInputMethod = (input) =>
{
	if (input?.type === 'email')
	{

		const newValue = zenkakuToHankaku(input.value)
			.replace(/＠/g, '@')
			.replace(/。/g, '.');

		input.value = newValue;
	}
	else if (input?.type === 'phone')
	{

		const newValue = zenkakuToHankaku(input.value)
			.replace(/−/g, '')
			.replace(/―/g, '')
			.replace(/-/g, '')
			.replace(/ー/g, '')
			.replace(/\(/g, '')
			.replace(/（/g, '')
			.replace(/\)/g, '')
			.replace(/）/g, '');

		input.value = newValue;
	}

	return input;

};

export const showTimeFromUtfSeconds = (timestamp) =>
{
	if (timestamp)
	{
		return dayjs(timestamp*1000).format('YYYY/MM/DD HH:mm');
	}
	else
	{
		return '';
	}
};

export const getDateString = (timestamp) =>
{
	const date = timestamp ? new Date(timestamp) : new Date();
	const month = (`0${date.getMonth() + 1}`).slice(-2);
	const day = (`0${date.getDate()}`).slice(-2);
	const hour = (`0${date.getHours()}`).slice(-2);
	const min = (`0${date.getMinutes()}`).slice(-2);

	return `${date.getFullYear()}/${month}/${day} ${hour}:${min}`;
};

// set URL Parameters to Object
export const parseUrlParams = ({ paramsValues, keys }) =>
{
	const paramsValuesAr = paramsValues.split('&');

	const paramsObj = {};

	paramsValuesAr.forEach((item) =>
	{
		keys.forEach((key) =>
		{
			if (item.startsWith(`${key}=`))
			{
				const replacedStr = item.replace(`${key}=`, '');

				paramsObj[key] = decodeURIComponent(replacedStr);
			}
		});
	});

	return paramsObj;
};

// restore params at lobby
export const getLobbySearchText = ({ skillId, kokyakuId, operator }) =>
{
	let searchText = '?';

	let searchItemCount = 0;

	if (skillId)
	{
		searchText += `id=${skillId}`;
		searchItemCount += 1;
	}

	if (kokyakuId)
	{
		searchText += searchItemCount === 0
			? `kokyaku_id=${kokyakuId}`
			: `&kokyaku_id=${kokyakuId}`;
		searchItemCount += 1;
	}

	if (operator)
	{
		searchText += searchItemCount === 0
			? `operator=${operator}`
			: `&operator=${operator}`;
		searchItemCount += 1;
	}

	return searchText;
};

export const getDomainWithoutSubdomain = () =>
{
	const hostname = window.location.hostname;

	if (hostname.endsWith('localhost'))
	{
		return 'localhost:4443';
	}
	// has subdomain => remove
	else
	{
		const hostNameAr = hostname.split('.');

		if (hostNameAr.length > 2)
		{
			hostNameAr.shift();
		}
		const domain = hostNameAr.join('.');

		return domain;
	}
};

export const showNumberWithComma = (num) =>
{
	if (!num)
	{
		return '0';
	}
	else
	{
		return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
	}
};

export const showTaxIncludedAmount = (num) =>
{
	if (!num)
	{
		return '0';
	}
	else
	{
		return String(Math.floor(num*(1+CONSUMPTION_TAX))).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
	}
};

export const showDateTime = (timestamp) =>
{
	if (!timestamp)
	{
		return '';
	}
	else
	{
		return dayjs(timestamp).format('YYYY.MM.DD HH:mm');
	}

};

export const filterPriceListItem = (initialAr, condArray, sortKey) =>
{
	if (initialAr)
	{
		const resultAr = [];

		initialAr.forEach((item) =>
		{
			if (condArray.includes(item.showPage))
			{
				resultAr.push(item);
			}
		});

		if (sortKey)
		{
			resultAr.sort((a, b) =>
			{
				if (a[sortKey] < b[sortKey]) { return -1; }
				if (a[sortKey] > b[sortKey]) { return 1; }

				return 0;
			});
		}

		return resultAr;
	}
	else
	{
		return [];
	}
};

export const getSecondsNow = () =>
{
	const timeNow = firebaseTimestamp();

	const secondsNow = timeNow && timeNow.seconds ?
		timeNow.seconds : Math.floor(Date.now()/1000);

	return secondsNow;
};

export const getMilliSecondsNow = () =>
{
	const timeNow = firebaseTimestamp();
	const milliSecondsNow = timeNow && timeNow.seconds && timeNow.nanoseconds ?
		timeNow.seconds*1000 + timeNow.nanoseconds / 1000000 : Date.now();

	return milliSecondsNow;
};

export const uploadCallInfoFunc = async (args) =>
{
	// ステータスを待機中に設定
	const doc = await firestore.collection('calls').doc(args.id)
		.get();

	let data = null;

	// ステータスを待機中に設定
	if (doc.exists)
	{
		data = doc.data();
		data.timestamp = Date.now();
		data.duration = 0;
		data.status = args.status;
		data.calls.push({
			timestamp : Date.now(),
			user      : args.roomInfo?.user || '',
			hostId    : args.roomInfo?.hostId || '',
			roomId    : args.roomInfo?.roomId || '',
			status    : args.status
		});

		await firestore.collection('calls').doc(args.id)
			.update(data)
			.catch((e) =>
			{
				console.error(e); // eslint-disable-line no-console
			});
	}
	else
	{
		const milliSecondsNow = getMilliSecondsNow();

		data = {
			timestamp             : milliSecondsNow,
			os                    : args.lobbyPeer?.os || '',
			browser               : args.lobbyPeer?.browser || '',
			portalId              : args.lobbyPeer?.portalId || '',
			serviceParamKey       : args.lobbyPeer?.serviceParamKey || '',
			serviceBrokerParamKey : args.lobbyPeer?.serviceBrokerParamKey || '',
			guestInput1           : args.lobbyPeer?.guestInputs?.guestInput1
				? handleGuestInputMethod(args.lobbyPeer.guestInputs.guestInput1)
				: { type: '', key: '', valye: '' },
			guestInput2 : args.lobbyPeer?.guestInputs?.guestInput2
				? handleGuestInputMethod(args.lobbyPeer.guestInputs.guestInput2)
				: { type: '', key: '', valye: '' },
			kokyakuId            : args.lobbyPeer?.kokyakuId || '',
			skillId              : args.lobbyPeer?.skillId || '_',
			serviceGuest         : args.serviceGuest?.guestId || '',
			serviceTicket        : args.serviceGuest?.ticketId || '',
			ticketParamKey       : args.serviceGuest?.ticketParamKey || '',
			connectionId         : args.connectionGuest?.id || '',
			connectionAccountId  : args.connectionGuest?.accountId || '',
			connectionCustomerId : args.connectionGuest?.customerId || '',
			connectionUsageBasedBilling :
				args.connectionGuest?.usageBasedBilling ? true : false,
			duration  : 0,
			status    : args.status,
			uid       : args.roomInfo.uid || '',
			subdomain : args.roomInfo.subdomain || '',
			calls     : [ {
				timestamp : milliSecondsNow,
				user      : args.roomInfo.user || '',
				hostId    : args.roomInfo.hostId || '',
				roomId    : args.roomInfo.id || '',
				status    : args.status
			} ]
		};

		await firestore.collection('calls').doc(args.id)
			.set(data)
			.catch((e) =>
			{
				console.error(e); // eslint-disable-line no-console
			});

		return data;
	}

};

export const updateRoomStatusFunc = async ({
	room,
	status
}) =>
{
	if (room && room.name)
	{
		try
		{
			await firestore.collection('rooms').doc(room.name)
				.update(
					{
						status : status
					}
				);
		}
		catch
		{
			return false;
		}

		return true;
	}

	return false;

};

export const getGmoShopId = (domain) =>
{
	if (domain === 'online-call.com')
	{
		return process.env.REACT_APP_GMO_SHOP_ID_TRANSLATE_PROD;
	}
	else
	{
		return process.env.REACT_APP_GMO_SHOP_ID_TRANSLATE_DEV;
	}
};

export const getGmoTokenUrl = (domain) =>
{
	if (domain === 'online-call.com')
	{
		return 'https://static.mul-pay.jp/ext/js/token.js';
	}
	else
	{
		return 'https://stg.static.mul-pay.jp/ext/js/token.js';
	}
};

// チケットコードをそのままlocalStorageに保存するのを避けるための変換メソッド
// (crypto-jsを使いたいがエラーになりインストールできない)
export const convertTicketCode = (string) =>
{
	let error = false;

	let prevIndex = 0;

	let convertedText = string.substr(0, 4);

	for (let i = 4; i < string.length; i++)
	{
		const text = string.charAt(i);

		const currentIndex = CONVERT_STRINGS.findIndex((el) => el === text);

		if (currentIndex === -1)
		{
			error = true;
		}

		let newIndex = currentIndex + CODE_POS_CHANGE[i] + prevIndex;

		if (newIndex < 0)
		{
			newIndex = newIndex + CONVERT_STRINGS.length;
		}
		else
		{
			newIndex = newIndex % CONVERT_STRINGS.length;
		}

		// オリジナルの文字列のindex位置
		prevIndex = currentIndex;

		const newValue = CONVERT_STRINGS[newIndex];

		if (!newValue)
		{
			error = true;
		}

		convertedText = convertedText.concat(CONVERT_STRINGS[newIndex]);
	}

	if (error)
	{
		convertedText = '';
	}

	return convertedText;
};

export const restoreTicketCode = (string) =>
{
	let error = false;

	let prevIndex = 0;

	let convertedText = string.substr(0, 4);

	for (let i = 4; i < string.length; i++)
	{
		const text = string.charAt(i);

		const currentIndex = CONVERT_STRINGS.findIndex((el) => el === text);

		if (currentIndex === -1)
		{
			error = true;
		}

		let newIndex = currentIndex - CODE_POS_CHANGE[i] - prevIndex;

		if (newIndex < CONVERT_STRINGS.length*-1)
		{
			newIndex = newIndex + CONVERT_STRINGS.length*2;
		}
		else if (newIndex < 0)
		{
			newIndex = newIndex + CONVERT_STRINGS.length;
		}
		else
		{
			newIndex = newIndex % CONVERT_STRINGS.length;
		}

		// オリジナルの文字列のindex位置
		prevIndex = newIndex;

		const newValue = CONVERT_STRINGS[newIndex];

		if (!newValue)
		{
			error = true;
		}

		convertedText = convertedText.concat(CONVERT_STRINGS[newIndex]);
	}

	if (error)
	{
		convertedText = '';
	}

	return convertedText;
};

export const getGuestBackUrl = ({ guestHomeUrl, guestRedirectParams }) =>
{
	if (guestHomeUrl?.origin)
	{
		return guestHomeUrl?.origin;
	}
	else if (guestHomeUrl.user)
	{
		return guestHomeUrl.user;
	}
	else if (guestRedirectParams.portalId)
	{
		const domain = getDomainWithoutSubdomain();

		if (guestRedirectParams.portalKey)
		{
			return `https://${domain}/portal?id=${guestRedirectParams.portalId}&portalKey=${guestRedirectParams.portalKey}`;
		}
		else
		{
			return `https://${domain}/portal?id=${guestRedirectParams.portalId}`;
		}
	}
	else if (guestRedirectParams.serviceParamKey)
	{

		const domain = getDomainWithoutSubdomain();

		return `https://${domain}/service?id=${guestRedirectParams.serviceParamKey}&backFromCall=true`;
	}
	else if (guestRedirectParams.connectionKey
		&& guestRedirectParams.connectionParamKey)
	{

		const domain = getDomainWithoutSubdomain();

		return `https://${domain}/connect?account=${guestRedirectParams.connectionParamKey}&key=${guestRedirectParams.connectionKey}`;
	}
	else
	{
		return null;
	}
};

export const handleConnectFilePurchase = ({
	fileArray,
	priceList,
	priceListTerm,
	defaultExpiryTerm,
	paramKey
}) =>
{

	const purchaseList = [];

	const resultList = [];

	priceList.forEach((item) =>
	{
		purchaseList.push({ ...item, quantity: 0, expiryTerm: defaultExpiryTerm });
	});

	priceListTerm.forEach((item) =>
	{
		purchaseList.push({ ...item, quantity: 0 });
	});

	fileArray.forEach((fileItem) =>
	{

		let success = false;

		let entryKeyValue = '';

		const entryKey = fileItem['entry_key'];

		const days = fileItem['days'];

		const emailValue = fileItem['email'] || '';

		const customerId = fileItem['id'];

		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

		if (!customerId)
		{
			resultList.push({
				status     : 'noId',
				entryKey   : entryKey,
				customerId : customerId,
				email      : emailValue,
				quantity   : fileItem.quantity
			});
		}
		else if (!emailPattern.test(emailValue))
		{
			resultList.push({
				status     : 'invalidEmail',
				entryKey   : entryKey,
				customerId : customerId,
				email      : emailValue,
				quantity   : fileItem.quantity
			});
		}
		// keyなしの場合は時間制限なしの日数購入
		else if (!entryKey)
		{
			if (days && parseInt(days) > 0)
			{
				const automaticEntryKey = `flexDays-${days}`;

				const sameKeyIndex = purchaseList.findIndex(
					(row) => row.entryKey === automaticEntryKey);

				// 条件がなければ追加
				if (sameKeyIndex === -1)
				{
					purchaseList.push({
						entryKey   : automaticEntryKey,
						expiryTerm : {
							countFrom : 'purchase',
							key       : '',
							type      : 'day',
							value     : parseInt(days)
						},
						holesaleAmount    : 0,
						minutes           : -1,
						salesAmount       : 0,
						type              : 'term',
						quantity          : 0, // added in "if (success){}" part
						usageBasedBilling : true
					});
				}

				entryKeyValue = automaticEntryKey;
				success = true;
			}
			else
			{
				resultList.push({
					status     : 'noCondition',
					entryKey   : '',
					customerId : customerId,
					quantity   : fileItem.quantity
				});
			}
		}
		else if (entryKey.slice(0, 4) === paramKey.toUpperCase())
		{

			const sameKeyIndex = purchaseList.findIndex(
				(row) => row.entryKey === entryKey);

			if (sameKeyIndex === -1)
			{
				resultList.push({
					status     : 'noCondition',
					entryKey   : '',
					customerId : customerId,
					quantity   : fileItem.quantity
				});
			}
			else
			{
				entryKeyValue = entryKey;
				success = true;
			}
		}
		else
		{
			resultList.push({
				status     : 'wrongAccount',
				entryKey   : entryKey,
				customerId : customerId,
				quantity   : fileItem.quantity
			});
		}

		if (success)
		{

			const sameKeyIndex = purchaseList.findIndex(
				(row) => row.entryKey === entryKeyValue);

			if (fileItem.quantity === undefined || (typeof fileItem.quantity === 'string' && fileItem.quantity.trim() === ''))
			{
				purchaseList[sameKeyIndex].quantity += 1;

				resultList.push({
					status     : 'success',
					entryKey   : entryKeyValue,
					customerId : customerId,
					email      : emailValue,
					quantity   : 1
				});
			}
			else if (fileItem.quantity)
			{
				try
				{
					const quantityValue = Number(fileItem.quantity);

					if (quantityValue)
					{
						purchaseList[sameKeyIndex].quantity += quantityValue;

						resultList.push({
							status     : 'success',
							entryKey   : entryKeyValue,
							customerId : customerId,
							email      : emailValue,
							quantity   : quantityValue
						});
					}
					else
					{
						resultList.push({
							status     : 'noQuantity',
							entryKey   : entryKeyValue,
							customerId : customerId,
							quantity   : fileItem.quantity
						});
					}
				}
				catch
				{
					resultList.push({
						status     : 'noQuantity',
						entryKey   : entryKey,
						customerId : customerId,
						quantity   : fileItem.quantity
					});
				}
			}
			else
			{
				resultList.push({
					status     : 'noQuantity',
					entryKey   : entryKeyValue,
					customerId : customerId,
					quantity   : fileItem.quantity
				});
			}
		}

	});

	return { purchaseList, resultList };
};