// React
import React, { useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// Components
import MultiBtnPageSetting from '../MultiBtnPageSetting';
import MultiBtnsSetting from '../MultiBtnsSetting';

import randomString from 'random-string';

// Components
import PortalLoginIdsDialog from './PortalLoginIdsDialog';
import PortalIpAdressDialog from './PortalIpAddressDialog';

// firebase
import { firestore, storage, functions } from '../../../../lib/firebase';

// const
import { PORTAL_OPEN, PORTAL_SECURITY_KEY, PORTAL_LOGIN } from '../../../../const';

// util
import {
	getDomainWithoutSubdomain
} from '../../../../utils';

const styles = () =>
	({
		root : {
			width           : '100%',
			height          : 'calc(100% - 3rem)',
			minWidth        : '1100px',
			position        : 'relative',
			display         : 'flex',
			flexDirection   : 'column',
			borderRadius    : '20px',
			backgroundColor : '#e5e5e2'
		},
		wrapper : {
			width           : '100%',
			backgroundColor : 'white',
			boxShadow       : '3px 3px 10px #aaa',
			padding         : '0.5% 1%'
		},
		group : {
			marginBottom : '2%'
		},
		pageTitle : {
			color      : 'var(--text-color)',
			fontSize   : '1.2rem',
			lineHeight : '1.4rem'
		},
		top : {
			display       : 'flex',
			flexDirection : 'row'
		},
		topLeft : {
			width : '50%'
		},
		topRight : {
			width : '50%'
		},
		topData : {
			height     : '2rem',
			display    : 'flex',
			margin     : '4% 0',
			alignItems : 'center',
			lineHeight : 1
		},
		inputLabel : {
			width          : '20%',
			fontSize       : '0.8rem',
			whiteSpace     : 'nowrap',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'flex-end',
			justifyContent : 'center'
		},
		label : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		labelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabel : {
			color        : 'var(--text-color)',
			fontSize     : '0.7rem',
			lineHeight   : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.6rem',
			lineHeight   : '0.7rem',
			overflowWrap : 'break-word'
		},
		subLabelExSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.5rem',
			lineHeight   : '0.6rem',
			overflowWrap : 'break-word'
		},
		inputValue : {
			width    : '80%',
			fontSize : '0.8rem',
			padding  : '0 3%'
		},
		inputValueDual : {
			display        : 'flex',
			justifyContent : 'space-between'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '20%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#EAEAEA !important'
			}
		},
		fullWidth : {
			width : '100%'
		},
		inputList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '12rem'
		},
		inputListSmall : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 0.3rem',
			color        : 'var(--text-color)',
			minWidth     : '4rem'
		},
		inputListHalf : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			width        : '6rem'
		},
		inputHalf :
		{
			width   : '48%',
			display : 'flex'
		},
		inputHalfEnd :
		{
			justifyContent : 'end'
		},
		inputHalfStart :
		{
			justifyContent : 'flex-start'
		},
		portalUrlColumn : {
			fontSize : '0.7rem !important',
			padding  : '0.3rem 0.3rem !important',
			cursor   : 'pointer'
		},
		imageGroup : {
			width   : '100%',
			display : 'flex'
		},
		imageNameGroup : {
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'flex-start'
		},
		imageIcon : {
			color       : 'var(--text-color)',
			marginRight : '0.5rem'
		},
		imageBorder : {
			borderBottom : '1px solid var(--text-color)',
			padding      : '0 0.5rem'
		},
		imageName : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			whiteSpace : 'nowrap'
		},
		imageBox : {
			width          : '100%',
			height         : '10rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			padding        : '5% 3%',
			'&> img'       : {
				objectFit : 'contain'
			}
		},
		image : {
			width     : 'auto',
			height    : 'auto',
			display   : 'block',
			maxWidth  : '100%',
			maxHeight : '100%'
		},
		screenSetting :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		screen : {
			width : '50%'
		},
		actionGroup : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			paddingBottom  : '1rem'
		},
		inputBtn : {
			marginLeft      : '4%',
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.3rem',
			padding         : '0.1rem 2rem',
			fontSize        : '0.8rem',
			color           : 'var(--text-color)'
		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiSelect : {
			select : {
				'&:focus' : {
					borderRadius : '0.4rem'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

let securityKey = '';

const AdminPortalEditView = ({
	classes,
	closeCb,
	portal,
	portals,
	admin,
	errors,
	setErrors,
	setConfirmDialogState,
	setLoading,
	fetchPortals
}) =>
{
	const domain = getDomainWithoutSubdomain();

	const [ portalId, setPortalId ] = useState('');
	const [ portalName, setPortalName ] = useState('');
	const [ browserTitle, setBrowserTitle ] = useState();

	const [ disabled, setDisabled ] = useState(false);

	const [ authenticate, setAuthenticate ] = useState(PORTAL_OPEN);

	const [ authenticateExpireDays, setAuthenticateExipireDays ] = useState(30);

	const [ ipAddressRestriction, setIpAddressRestriction ] = useState(false);

	const [ ipAddresses, setIpAddresses ] = useState([]);

	const [ termsPage, setTermsPage ] = useState('default');

	// background
	const [ bgPCGradient, setBgPCGradient ] = useState('#EEEEEE, #EEEEEE');
	const [ bgSPGradient, setBgSPGradient ] = useState('#EEEEEE, #EEEEEE');

	// BG File for PC
	const [ uploadBgPCFile, setUploadBgPCFile ] = useState();
	const [ uploadBgPCUrl, setUploadBgPCUrl ] = useState('');

	// BG File for SP
	const [ uploadBgSPFile, setUploadBgSPFile ] = useState();
	const [ uploadBgSPUrl, setUploadBgSPUrl ] = useState('');

	const [ btnAreaPositionObj, setBtnAreaPositionObj ] = useState({
		PC : { top: '25', bottom: '', left: '', right: '', verticCenter: false, horizCenter: true },
		SP : { top: '25', bottom: '', verticCenter: false }
	});

	const [ talkingTimeLimit, setTalkingTimeLimit ] = useState(0);

	const [ hideTalkTime, setHideTalkTime ] = useState(false);

	// buttonSizeSetting
	const [ btnPosDirectionSelector, setBtnPosDirectionSelector ] = useState({ verticalPC: 'top', horizontalPC: 'center', verticalSP: 'top' });

	const [ btnAreaContentObj, setBtnAreaContentObj ] = useState({
		PC : {
			areaWidth         : '50vw',
			areaHeight        : '25vh',
			columns           : 2,
			btnMargin         : '2vh 2vw',
			btnPadding        : '2vh 4vw',
			btnFontSize       : '1.5vw',
			btnFontSizeSecond : '1vw'
		},
		SP : {
			areaWidth         : '90vw',
			areaHeight        : '25vh',
			columns           : 1,
			btnMargin         : '2vh 2vw',
			btnPadding        : '2vh 4vw',
			btnFontSize       : '2vh',
			btnFontSizeSecond : '1.5vh'
		}
	});

	const [ buttons, setButtons ] = useState([]);

	const [ backButtonActive, setBackButtonActive ] = useState(false);

	const [ backButtonText, setBackButtonText ] = useState('戻る');

	const [ backButtonUrl, setBackButtonUrl ] = useState('');

	const [ backButtonTextColor, setBackButtonTextColor ] = useState('FFF');

	const [ backButtonBGColor, setBackButtonBGColor ] = useState('B7B7B7');

	const [ backButtonTextMarginPC, setBackButtonTextMarginPC ] = useState('0 0 0 0');

	const [ backButtonTextMarginSP, setBackButtonTextMarginSP ] = useState('1vh 0 1vh 0');

	const [ showPortalLoginIdsDialog, setShowPortalLoginIdsDialog ] = useState(false);

	const [ showIpAddressDialog, setShowIpAddressDialog ] = useState(false);

	useEffect(() =>
	{
		if (!portal)
		{
			setPortalName('');
			setPortalId('');
			setBrowserTitle('');
			setTalkingTimeLimit(0);
			setHideTalkTime(false);
			setAuthenticate(PORTAL_OPEN);
			setAuthenticateExipireDays(30);
			securityKey = '';
			setDisabled(false);
			setIpAddressRestriction(false);
			setIpAddresses([]);
			setTermsPage('default');
			setBgPCGradient('#EEEEEE, #EEEEEE');
			setBgSPGradient('#EEEEEE, #EEEEEE');
			setUploadBgPCUrl('');
			setUploadBgSPUrl('');
			setBtnAreaPositionObj({
				PC : { top: '25', bottom: '', left: '', right: '', verticCenter: false, horizCenter: true },
				SP : { top: '25', bottom: '', verticCenter: false }
			});
			setButtons([]);
			setBtnAreaContentObj({
				PC : {
					areaWidth         : '50vw',
					areaHeight        : '25vh',
					columns           : 2,
					btnMargin         : '2vh 2vw',
					btnPadding        : '2vh 4vw',
					btnFontSize       : '1.5vw',
					btnFontSizeSecond : '1vw'
				},
				SP : {
					areaWidth         : '90vw',
					areaHeight        : '25vh',
					columns           : 1,
					btnMargin         : '2vh 2vw',
					btnPadding        : '2vh 4vw',
					btnFontSize       : '2vh',
					btnFontSizeSecond : '1.5vh'
				}
			});
			setBtnPosDirectionSelector({ verticalPC: 'top', horizontalPC: 'center', verticalSP: 'top' });

			setBackButtonActive(false);
			setBackButtonText('戻る');
			setBackButtonUrl('');
			setBackButtonTextColor('FFF');
			setBackButtonBGColor('B7B7B7');
			setBackButtonTextMarginPC('0 0 0 0 ');
			setBackButtonTextMarginSP('1vh 0 1vh 0');
		}
		else
		{
			setPortalName(portal.portalName ? portal.portalName : '');
			setPortalId(portal.portalId ? portal.portalId : '');
			setBrowserTitle(portal.browserTitle ? portal.browserTitle : '');
			setTalkingTimeLimit(portal.talkingTimeLimit ? portal.talkingTimeLimit : 0);
			setHideTalkTime(portal.hideTalkTime ? true : false);
			setDisabled(portal.disabled ? true: false);
			setAuthenticate(portal.authenticate ? portal.authenticate : PORTAL_OPEN);
			setAuthenticateExipireDays(
				(portal.authenticateExpireDays || portal.authenticateExpireDays === 0) ?
					portal.authenticateExpireDays : 30
			);
			securityKey = portal.securityKey ? portal.securityKey : '';
			setIpAddressRestriction(portal.ipAddressRestriction ? true: false);
			setIpAddresses(portal.ipAddresses ? portal.ipAddresses : []);
			setTermsPage(portal.termsPage ? portal.termsPage : 'default');

			setBgPCGradient(portal.bgPCGradient ? portal.bgPCGradient : '#EEEEEE, #EEEEEE');
			setBgSPGradient(portal.bgSPGradient ? portal.bgSPGradient : '#EEEEEE, #EEEEEE');
			setUploadBgPCUrl(portal.bgPCUrl ? portal.bgPCUrl : '');
			setUploadBgSPUrl(portal.bgSPUrl ? portal.bgSPUrl : '');
			setButtons(portal.buttons ? portal.buttons : []);
			setBtnAreaPositionObj(
				portal.btnAreaPosition && portal.btnAreaPosition.PC && portal.btnAreaPosition.SP
					? portal.btnAreaPosition
					: {
						PC : { top: '25', bottom: '', left: '', right: '', verticCenter: false, horizCenter: true },
						SP : { top: '25', bottom: '', verticCenter: false }
					});

			setBtnAreaContentObj(
				portal.btnAreaContent && portal.btnAreaContent.PC && portal.btnAreaContent.SP
					? portal.btnAreaContent
					: {
						PC : {
							areaWidth         : '50vw',
							areaHeight        : '25vh',
							columns           : 2,
							btnMargin         : '2vh 2vw',
							btnPadding        : '2vh 4vw',
							btnFontSize       : '1.5vw',
							btnFontSizeSecond : '1vw'
						},
						SP : {
							areaWidth         : '90vw',
							areaHeight        : '25vh',
							columns           : 1,
							btnMargin         : '2vh 2vw',
							btnPadding        : '2vh 4vw',
							btnFontSize       : '2vw',
							btnFontSizeSecond : '1.5vw'
						}
					});

			setBackButtonActive(portal.backButtonActive ? true: false);

			setBackButtonText(portal.backButtonText ? portal.backButtonText : '戻る');

			setBackButtonUrl(portal.backButtonUrl ? portal.backButtonUrl : '');

			setBackButtonTextColor(portal.backButtonTextColor ? portal.backButtonTextColor : 'FFF');
			setBackButtonBGColor(portal.backButtonBGColor ? portal.backButtonBGColor : 'B7B7B7');
			setBackButtonTextMarginPC(portal.backButtonTextMarginPC ? portal.backButtonTextMarginPC :'0 0 0 0 ');
			setBackButtonTextMarginSP(portal.backButtonTextMarginSP ? portal.backButtonTextMarginSP :'1vh 0 1vh 0');

			// set initial value of button position direction selector, from db data
			setBtnPosDirectionSelector(() =>
			{
				let verticalPCVal = 'top';

				let horizontalPCVal = 'left';

				let verticalSPVal = 'top';

				if (
					portal.btnAreaPosition
					&& portal.btnAreaPosition.PC
					&& portal.btnAreaPosition.SP
				)
				{
					if (portal.btnAreaPosition.PC.bottom)
					{
						verticalPCVal = 'bottom';
					}
					else if (portal.btnAreaPosition.PC.verticCenter)
					{
						verticalPCVal = 'center';
					}

					if (portal.btnAreaPosition.PC.right)
					{
						horizontalPCVal = 'right';
					}
					else if (portal.btnAreaPosition.PC.horizCenter)
					{
						horizontalPCVal = 'center';
					}

					if (portal.btnAreaPosition.SP.bottom)
					{
						verticalSPVal = 'bottom';
					}
					else if (portal.btnAreaPosition.SP.verticCenter)
					{
						verticalSPVal = 'center';
					}
				}

				return {
					verticalPC   : verticalPCVal,
					horizontalPC : horizontalPCVal,
					verticalSP   : verticalSPVal
				};
			});
		}
	// eslint-disable-next-line
	}, [ portal ]);

	const handleChangePortalId = (e) =>
	{
		const portalIdReg = new RegExp(/^[a-z0-9-_]+$/);

		const value = e.target.value;

		if (!value || portalIdReg.test(value))
		{
			setPortalId(value);
		}
	};

	const handleChangePortalName = (e) =>
	{
		setPortalName(e.target.value);
	};

	const handleChangeBrowserTitle = (e) =>
	{
		setBrowserTitle(e.target.value);
	};

	const handleTalkingTimeLimit = (e) =>
	{
		setTalkingTimeLimit(Number(e.target.value));
	};

	const handleChangeHideTalkTime = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setHideTalkTime(value);
	};

	const handleChangeDisabled = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setDisabled(value);
	};

	const handleChangeAuthenticate = (e) =>
	{
		setAuthenticate(e.target.value);

		if (e.target.value !== PORTAL_LOGIN)
		{
			setAuthenticateExipireDays(0);
		}
	};

	const handleChangeAuthenticateExpireDays = (e) =>
	{
		setAuthenticateExipireDays(Number(e.target.value));
	};

	const handleChangeIpAddressRestriction = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setIpAddressRestriction(value);
	};

	const handleChangeTermsPage = (e) =>
	{
		setTermsPage(e.target.value);
	};

	const uploadBgPC = async () =>
	{
		if (portalId)
		{
			let url = uploadBgPCUrl ? uploadBgPCUrl : '';

			if (uploadBgPCFile && uploadBgPCFile.name)
			{
				const storageref = storage.ref(`/images/portalsImages/${portalId}/logo_${uploadBgPCFile.name}`);
				const snapshot = await storageref.put(uploadBgPCFile);

				url = await snapshot.ref.getDownloadURL();
				setUploadBgPCUrl(url);
			}

			return url;
		}
	};

	const uploadBgSP = async () =>
	{
		if (portalId)
		{
			let url = uploadBgSPUrl ? uploadBgSPUrl : '';

			if (uploadBgSPFile && uploadBgSPFile.name)
			{
				const storageref = storage.ref(`/images/portalsImages/${portalId}/logo_${uploadBgSPFile.name}`);
				const snapshot = await storageref.put(uploadBgSPFile);

				url = await snapshot.ref.getDownloadURL();
				setUploadBgSPUrl(url);
			}

			return url;
		}
	};

	const handleSave = async () =>
	{
		if (!portalId)
		{
			setErrors({
				show   : true,
				errors : [ 'IDがありません' ]
			});

			return;
		}

		// eslint-disable-next-line
		const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

		if (backButtonUrl && !urlPattern.test(backButtonUrl))
		{
			setErrors({
				show   : true,
				errors : [ '戻るボタンのリンク先URLが正しくありません' ]
			});

			return;
		}

		setErrors({
			show   : false,
			errors : []
		});

		setLoading(true);

		const bgPCUrl = await uploadBgPC();
		const bgSPUrl = await uploadBgSP();

		// create
		if (!portal)
		{
			if (!admin.rights?.createUser)
			{
				setErrors({
					show   : true,
					errors : [ '作成権限がありません' ]
				});

				setLoading(false);

				return;
			}

			const sameIdIndex = portals.findIndex((pt) => pt.portalId === portalId);

			if (sameIdIndex === -1)
			{
				const securityKeyValue = randomString({
					length  : 16,
					numeric : true,
					letters : true,
					special : false,
					exclude : [ 'l', 'I' ]
				});

				firestore.collection('portals').doc(portalId)
					.set({
						portalId               : portalId,
						portalName             : portalName,
						browserTitle           : browserTitle,
						btnAreaContent         : btnAreaContentObj,
						btnAreaPosition        : btnAreaPositionObj,
						buttons                : buttons,
						bgPCGradient           : bgPCGradient,
						bgSPGradient           : bgSPGradient,
						bgPCUrl                : bgPCUrl,
						bgSPUrl                : bgSPUrl,
						disabled               : disabled,
						securityKey            : securityKeyValue,
						authenticate           : authenticate,
						authenticateExpireDays : authenticateExpireDays,
						ipAddressRestriction   : ipAddressRestriction,
						ipAddresses            : ipAddresses,
						termsPage              : termsPage,
						talkingTimeLimit       : talkingTimeLimit,
						hideTalkTime           : hideTalkTime,
						backButtonActive       : backButtonActive,
						backButtonText         : backButtonText,
						backButtonUrl          : backButtonUrl,
						backButtonTextColor    : backButtonTextColor,
						backButtonBGColor      : backButtonBGColor,
						backButtonTextMarginPC : backButtonTextMarginPC,
						backButtonTextMarginSP : backButtonTextMarginSP
					})
					.then(() =>
					{
						closeCb();
						setErrors({
							show   : false,
							errors : []
						});
						fetchPortals();
					})
					.catch(() =>
					{
						setErrors({
							show   : true,
							errors : [ '保存に失敗しました' ]
						});
						setLoading(false);
					});
			}
			else
			{
				setErrors({
					show   : true,
					errors : [ 'idが重複しています' ]
				});
				setLoading(false);
			}
		}
		// edit
		else
		{
			if (!admin.rights?.editUser)
			{
				setErrors({
					show   : true,
					errors : [ '編集権限がありません' ]
				});
				setLoading(false);

				return;
			}

			firestore.collection('portals').doc(portalId)
				.update({
					portalName             : portalName,
					browserTitle           : browserTitle,
					btnAreaContent         : btnAreaContentObj,
					btnAreaPosition        : btnAreaPositionObj,
					buttons                : buttons,
					bgPCGradient           : bgPCGradient,
					bgSPGradient           : bgSPGradient,
					bgPCUrl                : bgPCUrl,
					bgSPUrl                : bgSPUrl,
					disabled               : disabled,
					authenticate           : authenticate,
					authenticateExpireDays : authenticateExpireDays,
					ipAddressRestriction   : ipAddressRestriction,
					ipAddresses            : ipAddresses,
					termsPage              : termsPage,
					talkingTimeLimit       : talkingTimeLimit,
					hideTalkTime           : hideTalkTime,
					backButtonActive       : backButtonActive,
					backButtonText         : backButtonText,
					backButtonUrl          : backButtonUrl,
					backButtonTextColor    : backButtonTextColor,
					backButtonBGColor      : backButtonBGColor,
					backButtonTextMarginPC : backButtonTextMarginPC,
					backButtonTextMarginSP : backButtonTextMarginSP
				})
				.then(() =>
				{
					closeCb();
					setErrors({
						show   : false,
						errors : []
					});
					fetchPortals();
				})
				.catch(() =>
				{
					setErrors({
						show   : true,
						errors : [ '保存に失敗しました' ]
					});
					setLoading(false);
				});
		}
	};

	const deletePortal = async () =>
	{
		setLoading(true);

		const deleteLoginIdLoginIdAPI = functions.httpsCallable('deletePortalLoginId');

		const portalLoginIdsList = await firestore.collection('portalLoginIds')
			.where('portalId', '==', portalId)
			.get();

		try
		{
			if (portalLoginIdsList.docs)
			{
				for await (const lId of portalLoginIdsList.docs)
				{
					await deleteLoginIdLoginIdAPI(lId.id);
				}
			}
		}
		catch
		{

			setErrors({
				show   : true,
				errors : [ '削除に失敗しました' ]
			});

			return;
		}

		firestore.collection('portals').doc(portalId)
			.delete()
			.then(() =>
			{
				closeCb();
				setErrors({
					show   : false,
					errors : []
				});
				setConfirmDialogState(
					{
						show            : false,
						title           : '',
						message         : '',
						acceptMethod    : () => {},
						acceptMethodArg : undefined,
						closeButtonOnly : true
					}
				);
				fetchPortals();
			})
			.catch(() =>
			{
				setErrors({
					show   : true,
					errors : [ '削除に失敗しました' ]
				});
				setConfirmDialogState(
					{
						show            : false,
						title           : '',
						message         : '',
						acceptMethod    : () => {},
						acceptMethodArg : undefined,
						closeButtonOnly : true
					}
				);
				setLoading(false);
			});

	};

	const handleDelete = () =>
	{
		if (!admin.rights?.deleteUser)
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : '削除する権限がありません。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true
				}
			);
		}
		else
		{
			setConfirmDialogState({
				show            : true,
				title           : '確認',
				message         : 'ポータルページを削除しますか?',
				acceptMethod    : deletePortal,
				acceptMethodArg : {},
				closeButtonOnly : false
			});
		}
	};

	const handleCancel = () =>
	{
		if (closeCb)
		{
			closeCb();
			setErrors({
				show   : false,
				errors : []
			});
		}
	};

	let portalUrl = '';

	if (portalId)
	{
		if (authenticate === PORTAL_SECURITY_KEY && portal && securityKey)
		{
			portalUrl = `https://${domain}/portal?id=${portalId}&portalKey=${securityKey}`;
		}
		else if (portal)
		{
			portalUrl = `https://${domain}/portal?id=${portalId}`;
		}
	}

	return (
		<MuiThemeProvider theme={theme}>

			<PortalLoginIdsDialog
				show={showPortalLoginIdsDialog}
				closeMethod={() => setShowPortalLoginIdsDialog(false)}
				portalId={portalId}
				setConfirmDialogState={setConfirmDialogState}
				domain={domain}
			/>

			<PortalIpAdressDialog
				show={showIpAddressDialog}
				closeMethod={() => setShowIpAddressDialog(false)}
				portalId={portalId}
				ipAddresses={ipAddresses}
				setIpAddresses={setIpAddresses}
				setConfirmDialogState={setConfirmDialogState}
			/>

			<Box className={classes.root}>
				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Typography className={classes.pageTitle}>
						ポータルページ登録情報
					</Typography>
					{errors.show === true &&
					<Box className={classes.errorBox}>
						{errors.errors.map((err, index) =>
							(<Typography className={classes.errMsg} key={index}>
								・{err}
							</Typography>)
						)}
					</Box>
					}

					<Box className={classes.top}>
						<Box className={classes.topLeft}>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ID</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={portalId}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangePortalId}
										disabled={portal}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>名称</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={portalName}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangePortalName}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ブラウザタイトル</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={browserTitle}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeBrowserTitle}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>通話可能分数</Typography>
									<Typography className={classes.subLabelSmall}>
										(0は無制限)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input value={talkingTimeLimit} className={classes.inputField} onChange={handleTalkingTimeLimit} type='number' min='0'/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>アカウント</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={disabled}
										onChange={handleChangeDisabled}
									>
										<MenuItem value={false}>有効</MenuItem>
										<MenuItem value>無効</MenuItem>
									</Select>
								</Box>
							</Box>

						</Box>
						<Box className={classes.topRight}>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>IPアドレス制限</Typography>
								</Box>
								<Box className={`${classes.inputValue} ${classes.inputValueDual}`}>
									<Box className={`${classes.inputHalf} ${classes.inputHalfStart}`}>
										<Select
											classes={{ root: classes.inputListHalf }}
											value={ipAddressRestriction}
											onChange={handleChangeIpAddressRestriction}
										>
											<MenuItem value={false}>無効</MenuItem>
											<MenuItem value>有効</MenuItem>
										</Select>
									</Box>
									<Box className={`${classes.inputHalf} ${classes.inputHalfEnd}`}>
										{ ipAddressRestriction ?
											<Button
												variant='contained'
												color='primary'
												onClick={() => setShowIpAddressDialog(true)}
											>
												IPアドレス編集
											</Button>
											:
											<Button
												variant='contained'
												color='primary'
												disabled
											>
												IPアドレス編集
											</Button>
										}
									</Box>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>認証</Typography>
								</Box>
								<Box className={`${classes.inputValue} ${classes.inputValueDual}`}>
									<Box className={`${classes.inputHalf} ${classes.inputHalfStart}`}>
										<Select
											classes={{ root: classes.inputListHalf }}
											value={authenticate}
											onChange={handleChangeAuthenticate}
										>
											<MenuItem value={PORTAL_OPEN}>なし</MenuItem>
											<MenuItem value={PORTAL_SECURITY_KEY}>URLキー</MenuItem>
											<MenuItem value={PORTAL_LOGIN}>ログイン</MenuItem>
										</Select>
									</Box>
									<Box className={`${classes.inputHalf} ${classes.inputHalfEnd}`}>
										{ portalId && authenticate === PORTAL_LOGIN ?
											<Button
												variant='contained'
												color='primary'
												onClick={() => setShowPortalLoginIdsDialog(true)}
											>
												ログインID編集
											</Button>
											:
											<Button
												variant='contained'
												color='primary'
												disabled
											>
												ログインID編集
											</Button>
										}
									</Box>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ログイン有効期限(日)</Typography>
									<Typography className={classes.subLabelSmall}>
										(0は無制限)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={authenticateExpireDays}
										className={classes.inputField}
										onChange={(e) => handleChangeAuthenticateExpireDays(e)}
										type='number'
										min='0'
										max='2000'
										disabled={authenticate !== PORTAL_LOGIN}
									/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>利用規約</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={termsPage}
										onChange={(e) => handleChangeTermsPage(e)}
									>
										<MenuItem value={'default'}>サイト共通</MenuItem>
										<MenuItem value={'liveTranslate'}>LIVE通訳専用</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>通話時間表示</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={hideTalkTime}
										onChange={handleChangeHideTalkTime}
									>
										<MenuItem value={false}>有効</MenuItem>
										<MenuItem value>無効</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ポータルURL</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										disabled
										value={portalUrl}
										type='text'
										className={`${classes.inputField} ${classes.fullWidth} ${classes.portalUrlColumn}`}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>

				< MultiBtnPageSetting
					type={'portal'}
					bgPCGradient={bgPCGradient}
					setBgPCGradient={setBgPCGradient}
					bgSPGradient={bgSPGradient}
					setBgSPGradient={setBgSPGradient}
					uploadBgPCFile={uploadBgPCFile}
					setUploadBgPCFile={setUploadBgPCFile}
					uploadBgPCUrl={uploadBgPCUrl}
					setUploadBgPCUrl={setUploadBgPCUrl}
					uploadBgSPFile={uploadBgSPFile}
					setUploadBgSPFile={setUploadBgSPFile}
					uploadBgSPUrl={uploadBgSPUrl}
					setUploadBgSPUrl={setUploadBgSPUrl}
					btnAreaPositionObj={btnAreaPositionObj}
					setBtnAreaPositionObj={setBtnAreaPositionObj}
					btnPosDirectionSelector={btnPosDirectionSelector}
					setBtnPosDirectionSelector={setBtnPosDirectionSelector}
					btnAreaContentObj={btnAreaContentObj}
					setBtnAreaContentObj={setBtnAreaContentObj}
					backButtonActive={backButtonActive}
					setBackButtonActive={setBackButtonActive}
					backButtonText={backButtonText}
					setBackButtonText={setBackButtonText}
					backButtonUrl={backButtonUrl}
					setBackButtonUrl={setBackButtonUrl}
					backButtonTextColor={backButtonTextColor}
					setBackButtonTextColor={setBackButtonTextColor}
					backButtonBGColor={backButtonBGColor}
					setBackButtonBGColor={setBackButtonBGColor}
					backButtonTextMarginPC={backButtonTextMarginPC}
					setBackButtonTextMarginPC={setBackButtonTextMarginPC}
					backButtonTextMarginSP={backButtonTextMarginSP}
					setBackButtonTextMarginSP={setBackButtonTextMarginSP}
				/>

				<MultiBtnsSetting
					buttons={buttons}
					setButtons={setButtons}
					setBtnImageName={() => {}}
					setBtnImageFile={() => {}}
				/>

				<Box className={classes.actionGroup}>
					<Button
						onClick={handleSave}
						className={classes.inputBtn}
						variant='contained'
						disabled={
							(!portal && !admin.rights?.createUser)
							|| (portal && !admin.rights?.editUser)
						}
					>
						保存
					</Button>
					<Button
						onClick={handleDelete}
						className={classes.inputBtn}
						variant='contained'
						disabled={!portal || !admin.rights?.deleteUser}
					>
						削除
					</Button>
					<Button onClick={handleCancel} className={classes.inputBtn} variant='contained'>
						キャンセル
					</Button>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

AdminPortalEditView.propTypes =
{
	classes               : PropTypes.object.isRequired,
	portal                : PropTypes.object,
	fetchPortals          : PropTypes.func,
	portals               : PropTypes.array,
	admin                 : PropTypes.object,
	closeCb               : PropTypes.func.isRequired,
	errors                : PropTypes.object,
	setErrors             : PropTypes.func,
	setConfirmDialogState : PropTypes.func,
	setLoading            : PropTypes.func
};

export default withStyles(styles)(AdminPortalEditView);
