// React
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// Prop types
import PropTypes from 'prop-types';

// Redux
import { withRoomContext } from '../../../../RoomContext';
import { connect } from 'react-redux';
import * as roomActions from '../../../../actions/roomActions';
import * as meActions from '../../../../actions/meActions';
import * as intlActions from '../../../../actions/intlActions';

// Components
import JoinRoomNoticeDialog from '../../Common/JoinRoomNoticeDialog';
import ServiceGuestLogin from './ServiceGuestLogin';
import ConfirmDialog from '../../Common/ConfirmDialog';
import ServiceAppBar from '../Parts/ServiceAppBar';
import NoTalkTimeDialog from '../Parts/NoTalkTimeDialog';
import ServiceButton from './ServiceButton';

import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';

// material ui 
import Typography from '@material-ui/core/Typography';

// Firebase
import { firestore } from '../../../../lib/firebase';
// import { useAuthState } from 'react-firebase-hooks/auth';

// Daysjs
import dayjs from 'dayjs';

// const 
import { ROOM_ONLINE, DEFAULT_SERVICE_ACCOUNT_KEY } from '../../../../const';

// util
import { parseUrlParams, getDomainWithoutSubdomain } from '../../../../utils';

// locale
import * as locales from '../../../../translations/locales';
import { createIntl, useIntl, FormattedMessage } from 'react-intl';

// images
import englishImg from '../../../../images/flags/english.png'; // eslint-disable-line
import koreaImg from '../../../../images/flags/korea.png'; // eslint-disable-line
import chinaImg from '../../../../images/flags/china.png'; // eslint-disable-line

import homeImg from '../../../../images/service_navi/home.svg'; // eslint-disable-line
import purchaseImg from '../../../../images/service_navi/purchase.svg'; // eslint-disable-line

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const breakPointAspectRatio = 1;

const appBarHeight = 40;

const styles = (theme) =>
	({
		root :
		{
			width           : '100%',
			height          : '100%',
			overflowX       : 'auto',
			backgroundColor : '#EAEAEA'
		},
		content : {
			width          : '100%',
			height         : '100%',
			minWidth       : '320px',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'flex-start',
			position       : 'relative'
		},
		loginContent : {
			width    : '100%',
			height   : '100%',
			minWidth : '320px'
		},
		infomationArea : {
			width         : '98%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center',
			marginTop     : '25px'
		},
		informationBox : {
			position        : 'relative',
			padding         : '4px 10px',
			backgroundColor : 'rgba(0,0,0,0.55)',
			borderRadius    : '20px',
			width           : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			alignItems      : 'center'
		},
		informationBoxSP : {
			maxWidth : '650px',
			minWidth : '315px',
			width    : '94%'
		},
		informationBoxPC : {
			width    : '98%',
			maxWidth : '800px'
		},
		ticketNumber : {
			width          : '95%',
			display        : 'flex',
			justifyContent : 'end',
			paddingRight   : '1%',
			marginBottom   : '4px'
		},
		ticketNumberSP : {
			maxWidth : '650px'
		},
		ticketNumberPC : {
			maxWidth : '800px'
		},
		ticketNumberText : {
			paddingTop : '4px',
			color      : '#292929',
			fontSize   : '0.7rem',
			[theme.breakpoints.up('sm')] :
			{
				paddingTop : '2px',
				fontSize   : '0.8rem'
			}
		},
		ticketNumberBtn : {
			marginLeft      : '12px',
			color           : '#292929',
			fontSize        : '0.8rem',
			backgroundColor : '#FFF',
			padding         : '0 5px',
			lineHeight      : '20px',
			cursor          : 'pointer',
			borderRadius    : '4px',
			boxShadow       : '1px 1px rgba(0, 0, 0, 0.4)',
			marginBottom    : '1px',
			[theme.breakpoints.up('sm')] :
			{
				marginLeft : '16px'
			}
		},
		expiryDateNoLimit : {
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%'
		},
		expiryDateText : {
			color    : '#FFF',
			fontSize : '0.9rem',
			padding  : '3px 10px'
		},
		expiryDateTextNoLimit : {
			margin : '0 5%'
		},
		expiryDateTextWithLimit :
		{
			width        : '90%',
			borderBottom : '1px solid #FFF'
		},
		expiryDateTextFree : {
			textAlign : 'center'
		},
		remainingTime : {
			width        : '90%',
			padding      : '3px 10px',
			borderBottom : '1px solid #FFF'
		},
		remainingTimeLine : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		remainingTimeText : {
			color    : '#FFF',
			fontSize : '1rem'
		},
		remainingTimeTextFree : {
			width     : '100%',
			textAlign : 'center',
			color     : '#F4DA23',
			fontSize  : '1rem'
		},
		explanation : {
			color    : '#FFF',
			fontSize : '0.7rem',
			width    : '90%',
			padding  : '3px 10px'
		},
		explanationFree : {
			textAlign : 'center'
		},
		buttonSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		buttonBoxTitle : {
			color     : '#292929',
			fontSize  : '1rem',
			width     : '100%',
			textAlign : 'center'
		},
		buttonAreaPC :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			padding         : '20px 0 10px 0',
			marginTop       : '1%',
			width           : '98%',
			maxWidth        : '800px',
			backgroundColor : '#FFF',
			borderRadius    : '10px',
			visibility      : 'hidden' // hide first because ugly shape show up,
		},
		buttonAreaSP :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			padding         : '20px 0 10px 0',
			marginTop       : '1%',
			width           : '90%',
			maxWidth        : '650px',
			minWidth        : '300px',
			backgroundColor : '#FFF',
			borderRadius    : '10px',
			visibility      : 'hidden' // hide first because ugly shape show up
		},
		naviSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginTop      : '20px'
		},
		naviItemWrapper : {
			width          : '80%',
			maxWidth       : '500px',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		naviItem : {
			width          : '50%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		naviItemLink : {
			cursor : 'pointer'
		},
		naviImg : {
			height : '28px',
			width  : 'auto'
		},
		naviTextHome : {
			color    : '#cd2c82',
			fontSize : '0.7rem'
		},
		naviTextLink : {
			color    : 'var(--text-color)',
			fontSize : '0.7rem'
		},
		buttonRow :
		{
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%',
			padding        : '0 1%',
			margin         : '1% 0'
		},
		imageContentPC : {
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100vw',
			maxHeight      : '100%'
		},
		imageContentSP : {
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100%',
			maxHeight      : '100vh'
		},
		container : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			width           : '100%',
			height          : `calc( 100% - ${appBarHeight}px )`,
			backgroundColor : '#EAEAEA'
		},
		disabledScreen : {
			position        : 'fixed',
			top             : '0',
			left            : '0',
			height          : '100vh',
			width           : '100vw',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white'
		},
		disabledIndicator : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white',
			padding         : '20px 25px',
			border          : '1px solid var(--text-color)',
			borderRadius    : '10px',
			maxWidth        : '90vw',
			marginBottom    : '15%'
		},
		disabledText : {
			fontSize  : '1.5rem',
			color     : 'var(--text-color)',
			textAlign : 'center'
		},
		title : {
			textAlign : 'center'
		}
	});

const showNumberHint = (accountIdentifier, numberHint) =>
{
	if (accountIdentifier && numberHint)
	{
		return `${accountIdentifier.toUpperCase()}********${numberHint}`;
	}
	else
	{
		return '';
	}
};

const showExpiryTime = (timestamp) =>
{
	if (timestamp === 0 || timestamp === '0')
	{
		return '';
	}
	else if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY/MM/DD H:mm');
	}
	else
	{
		return '';
	}
};

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

let roomStatusListener = null;

let accountName = '';

let termsPage = 'default';

let brokerAccountParamKey = '';

let backFromCall = false;

let isServiceDevEnv = false;

const ServiceHome = ({
	classes,
	setJoinRoomNoticeDialogOpen,
	updateIntl,
	intlObj,
	serviceTicketLogin,
	setServiceTicketLogin,
	serviceGuestLoggedIn,
	setServiceGuestLoggedIn
}) =>
{
	const location = useLocation();
	const history = useHistory();

	const intl = useIntl();

	// const [ serviceLogin ] = useAuthState(auth);

	const [ serviceGuest, setServiceGuest ] = useState({});

	const [ serviceParamKey, setServiceParamKey ] = useState(null);

	const [ serviceConnectionData, setServiceConnectionData ] = useState({});

	const [ issueFreeTicket, setIssueFreeTicket ] = useState(false);

	const [ logoImageUrl, setLogoImageUrl ] = useState('');

	const [ notalkTime, setNoTalkTime ] = useState(false);

	// Image URls
	// const [ bgPCUrl, setBgPCUrl ] = useState('');
	// const [ bgSPUrl, setBgSPUrl ] = useState('');

	const [ buttons, setButtons ] = useState([]);

	// Button Area
	const [ btnAreaContent, setBtnAreaContent ] = useState({
		PC : {
			columns           : 2,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '1.5vw',
			btnFontSizeSecond : '1vw'
		},
		SP : {
			columns           : 1,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '2vw',
			btnFontSizeSecond : '1.5vw'
		}
	});

	const [ btnAreaRows, setBtnAreaRows ] = useState({
		PC : 1,
		SP : 1
	});

	// window size
	const [ aspectRatio, setAspectRatio ] = useState(window.innerWidth/window.innerHeight);

	// available room
	const [ roomAvailable, setRoomAvailable ] = useState([]);

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show    : false,
			title   : '',
			message : ''
		}
	);

	const buttonAreaRef = useRef();

	// const bgImgRef = useRef();
	// const bgImgAreaRef = useRef();

	const [ disabled, setDisabled ] = useState({ value: false, reason: '' });

	const [ free, setFree ] = useState(false);

	const updateDimensions = useCallback(() =>
	{

		if (buttonAreaRef.current)
		{
			buttonAreaRef.current.style.visibility = 'visible';
		}

		setAspectRatio(window.innerWidth/window.innerHeight);

	}, [ ]);

	useEffect(() =>
	{
		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);

	}, [ updateDimensions ]);

	useEffect(() =>
	{
		if (serviceGuestLoggedIn)
		{
			updateDimensions();
		}
	}, [ serviceGuestLoggedIn, updateDimensions ]);

	const fetchInfos = useCallback(() =>
	{
		firestore.collection('serviceAccounts')
			.where('paramKey', '==', serviceParamKey)
			.get()
			.then((accounts) =>
			{
				if (accounts.docs[0])
				{
					const serviceAccountData = accounts.docs[0].data();

					// // check login. check serviceParamKey by email address.
					// if (!serviceLogin || !serviceLogin.email.endsWith(`${serviceParamKey}.servicelogin.2nddoor.net`))
					// {
					// 	setServiceGuestLoggedIn(false);
					// }
					// else
					// {
					// 	setServiceGuestLoggedIn(true);
					// }

					accountName = serviceAccountData.accountName ? serviceAccountData.accountName : '';

					brokerAccountParamKey = serviceAccountData.brokerAccountParamKey ? serviceAccountData.brokerAccountParamKey : '';

					termsPage = serviceAccountData.termsPage ? serviceAccountData.termsPage : 'default';

					setLogoImageUrl(serviceAccountData.logoImageUrl ? serviceAccountData.logoImageUrl : '');

					if (serviceAccountData.browserTitle)
					{
						document.title = serviceAccountData.browserTitle;
					}

					// setBgPCUrl(serviceAccountData.bgPCUrl ? serviceAccountData.bgPCUrl : '');
					// setBgSPUrl(serviceAccountData.bgSPUrl ? serviceAccountData.bgSPUrl : '');

					setDisabled(serviceAccountData.disabled ? { value: true, reason: 'suspended' }: { value: false, reason: '' });

					setIssueFreeTicket(serviceAccountData.issueFreeTicket ? true : false);

					setBtnAreaContent(serviceAccountData.btnAreaContent
						?	serviceAccountData.btnAreaContent
						: {
							PC : {
								columns           : 2,
								btnMargin         : '15px 15px',
								btnPadding        : '15px 40px',
								btnFontSize       : '1.5vw',
								btnFontSizeSecond : '1vw'
							},
							SP : {
								columns           : 1,
								btnMargin         : '15px 15px',
								btnPadding        : '15px 40px',
								btnFontSize       : '2vw',
								btnFontSizeSecond : '1.5vw'
							}
						}
					);

					if (serviceAccountData.buttons)
					{
						// how many rows in btnArea
						const pcRows = serviceAccountData.btnAreaContent?.PC?.columns
							? Math.ceil(
								serviceAccountData.buttons.length
											/serviceAccountData.btnAreaContent.PC.columns
							) : 1;

						const spRows = serviceAccountData.btnAreaContent?.SP?.columns
							? Math.ceil(
								serviceAccountData.buttons.length
											/serviceAccountData.btnAreaContent.SP.columns
							) : 1;

						setBtnAreaRows(
							{
								PC : pcRows,
								SP : spRows
							});
					}

					setButtons(serviceAccountData.buttons ? serviceAccountData.buttons : []);

					const buttonSubdomains = [];

					serviceAccountData.buttons.forEach((b) =>
					{
						if (!buttonSubdomains.includes(b.subdomain))
						{
							buttonSubdomains.push(b.subdomain);
						}
					});

					roomStatusListener = firestore.collection('rooms').where('subdomain', 'in', buttonSubdomains)
						.onSnapshot((snapshot) =>
						{
							// ボタンごとにavailable/not availableをチェック
							// {'buttonId1': true, 'buttonId2': false}
							const subdomainSkillsAvalilable = [];

							snapshot.forEach((r) =>
							{
								if (r.exists)
								{
									const roomData = r.data();

									if (roomData.status === ROOM_ONLINE)
									{
										subdomainSkillsAvalilable.push({
											subdomain : roomData.subdomain,
											skills    : roomData.skills || []
										});
									}
								}
							});

							const roomAvailableAr = [];

							// buttonsに移し替える
							if (serviceAccountData?.buttons)
							{
								serviceAccountData.buttons.forEach((b, btnIndex) =>
								{

									if (b.skillId && b.skillId !== '_') // button with skillId
									{
										const subdomainSkillIndex = subdomainSkillsAvalilable
											.findIndex((item) =>
												item.subdomain === b.subdomain
																&& item.skills.includes(b.skillId));

										if (subdomainSkillIndex !== -1)
										{
											roomAvailableAr[btnIndex] = true;
										}
										else
										{
											roomAvailableAr[btnIndex] = false;
										}
									}
									else // button without skillId
									{
										const subdomainIndex = subdomainSkillsAvalilable
											.findIndex((item) => item.subdomain === b.subdomain);

										if (subdomainIndex !== -1)
										{
											roomAvailableAr[btnIndex] = true;
										}
										else
										{
											roomAvailableAr[btnIndex] = false;
										}
									}
								});

								setRoomAvailable([ ...roomAvailableAr ]);
							}

						});
				}
				else
				{
					setDisabled({ value: true, reason: 'notFound' });
				}
			});
	}, [ serviceParamKey ]);

	const calcRemainingMinutes = (addMinutes) =>
	{
		// if (serviceGuest.remainingSeconds)
		// {
		// 	return serviceGuest.remainingSeconds;
		// }
		// else
		if (serviceTicketLogin.duration === -1)
		{
			return '-';
		}
		else if (serviceTicketLogin.duration)
		{
			const consumedValue
				= serviceTicketLogin.consumedSeconds ? serviceTicketLogin.consumedSeconds : 0;

			const remainingSeconds
				= serviceTicketLogin.duration - consumedValue;

			if (!addMinutes)
			{
				addMinutes = 0;
			}

			if (remainingSeconds)
			{
				return (Math.ceil(remainingSeconds/60) + addMinutes).toString();
			}
			else
			{
				return (0 + addMinutes).toString();
			}
		}
		else
		{
			return 0;
		}
	};

	const handleJoin = (itemIndex) =>
	{
		if (buttons[itemIndex] && serviceGuestLoggedIn)
		{
			const targetBtn = buttons[itemIndex];

			const consumedValue
				= serviceTicketLogin.consumedSeconds ? serviceTicketLogin.consumedSeconds : 0;

			const remainingSeconds
				= serviceTicketLogin.duration - consumedValue;

			if (remainingSeconds || serviceTicketLogin.duration === -1)
			{

				setServiceConnectionData({
					serviceParamKey       : serviceParamKey,
					serviceAccountName    : accountName ? accountName : '',
					termsPage             : termsPage,
					serviceBrokerParamKey : brokerAccountParamKey ? brokerAccountParamKey : '',
					subdomain             : targetBtn.subdomain ? targetBtn.subdomain : '',
					skillId               : targetBtn.skillId ? targetBtn.skillId : '',
					backUrl               : targetBtn.backUrl ? targetBtn.backUrl : '',
					ticketDuration        : remainingSeconds,
					ticketConsumedSeconds : serviceTicketLogin.consumedSeconds
						? serviceTicketLogin.consumedSeconds : 0,
					serviceGuestId  : serviceGuest.id ? serviceGuest.id : '',
					serviceTicketId : serviceTicketLogin.login && serviceTicketLogin.ticketId ? serviceTicketLogin.ticketId : '',
					ticketParamKey  : serviceTicketLogin.login && serviceTicketLogin.ticketParamKey ? serviceTicketLogin.ticketParamKey : '',
					locale          : intlObj.locale ? intlObj.locale : '',
					free            : free
				});

				setJoinRoomNoticeDialogOpen(true);
			}
			else
			{
				setConfirmDialogState({
					show  : true,
					title : intl.formatMessage({
						id             : 'service.noTimeTitle',
						defaultMessage : 'No talk time'
					}),
					message : intl.formatMessage({
						id             : 'service.noTimeMessage',
						defaultMessage : 'The remaining time of ticket is not enough'
					})
				});
			}
		}
	};

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		if (paramsValue)
		{
			const paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'id', 'backFromCall', 'freeTicketGuest' ]
			});

			if (!paramsObj['id'])
			{
				setServiceParamKey(DEFAULT_SERVICE_ACCOUNT_KEY);
			}
			else
			{
				setServiceParamKey(paramsObj['id']);
			}

			const domain = getDomainWithoutSubdomain();

			const referrer = document.referrer;

			if (referrer && referrer.includes(domain) && paramsObj['backFromCall'] === 'true')
			{
				backFromCall = true;
			}
			else
			{
				backFromCall = false;
			}

			if (paramsObj['freeTicketGuest'])
			{
				// 通話後に再ログインさせない
				localStorage.removeItem('serviceConnectionId');
				// ログイン用のチケットコードを最初から入力しない
				localStorage.removeItem('loginTicketCode');
				backFromCall = false;
			}
		}
		else
		{
			setServiceParamKey(DEFAULT_SERVICE_ACCOUNT_KEY);
		}
	}, [ location.search ]);

	useEffect(() =>
	{
		if (serviceParamKey)
		{
			fetchInfos();
		}

		return () =>
		{
			if (roomStatusListener)
			{
				// stop listening
				try
				{
					roomStatusListener();
				}
				catch
				{
					// do nothing
				}
			}

			roomStatusListener = null;

		};
	}, [ fetchInfos, serviceParamKey ]);

	const setLocale = (lc) =>
	{
		const one = locales.loadOne(lc);

		updateIntl({
			locale   : one.locale[0],
			messages : one.messages,
			list   	 : locales.getList()
		});

		createIntl({
			locale   : one.locale[0],
			messages : one.messages
		});

		document.documentElement.lang = one.locale[0].toUpperCase();

		localStorage.setItem('guestLacale', lc);

	};

	const disconnect = () =>
	{
		localStorage.removeItem('serviceConnectionId');
		setServiceGuestLoggedIn(null);
	};

	useEffect(() =>
	{

		if (serviceTicketLogin?.duration)
		{
			const consumedValue
				= serviceTicketLogin.consumedSeconds ? serviceTicketLogin.consumedSeconds : 0;

			const remainingSeconds
				= serviceTicketLogin.duration - consumedValue;

			if (remainingSeconds || serviceTicketLogin.duration === -1)
			{
				setNoTalkTime(false);
			}
			else
			{
				setNoTalkTime(true);
				localStorage.removeItem('loginTicketCode');
			}
		}
		else
		{
			setNoTalkTime(true);
		}

	}, [ serviceTicketLogin.duration, serviceTicketLogin.consumedSeconds ]);

	useEffect(() =>
	{
		const domain = getDomainWithoutSubdomain();

		isServiceDevEnv = domain !== 'online-call.com';

	}, []);

	const moveToPurchasePage = () =>
	{
		history.push({
			pathname : '/purchase',
			search   : `?id=${serviceParamKey}`
		});
	};

	let btnFlagConds = {
		'english' : {
			alt : 'english',
			img : englishImg,
			text :
	<FormattedMessage
		id='service.english'
		defaultMessage='English'
	/>
		},
		'korea' : {
			alt : 'korean',
			img : koreaImg,
			text :
	<FormattedMessage
		id='service.korean'
		defaultMessage='한국어（Korean）'
	/>
		},
		'china' : {
			alt : 'chinese',
			img : chinaImg,
			text :
	<FormattedMessage
		id='service.chinese'
		defaultMessage='中文（Chinese）'
	/>
		}
	};

	if (isServiceDevEnv)
	{
		btnFlagConds = {
			...btnFlagConds,
			'uchida' : { ...btnFlagConds['english'] },
			'maruse' : { ...btnFlagConds['korea'] },
			'ooi'    : { ...btnFlagConds['korea'] },
			'rosso'  : { ...btnFlagConds['china'] }
		};
	}

	return (
		<MuiThemeProvider theme={theme}>
			{ disabled.value ?
				<div className={classes.disabledScreen}>
					<div className={classes.disabledIndicator}>
						<Typography className={classes.disabledText}>
							{ disabled.reason === 'notFound' ?
								<FormattedMessage
									id='error.noAccount'
									defaultMessage='Account not found. Please check the URL.'
								/>
								:
								<FormattedMessage
									id='error.unusable'
									defaultMessage='Currently unavailable. Please contact the service operating company.'
								/>
							}
						</Typography>
					</div>
				</div>
				:
				<>
					<ConfirmDialog
						show={confirmDialogState.show}
						title={confirmDialogState.title}
						message={confirmDialogState.message}
						accept={() => {}}
						cancel={() => setConfirmDialogState({ show: false, title: '', message: '' })}
						closeButtonOnly
					/>
					<div
						className={classes.root}
					>
						{ !serviceGuestLoggedIn ?
							<div className={classes.loginContent}>
								<ServiceGuestLogin
									logoImageUrl={logoImageUrl}
									setServiceGuestLoggedIn={setServiceGuestLoggedIn}
									setServiceGuest={setServiceGuest}
									setServiceTicketLogin={setServiceTicketLogin}
									serviceParamKey={serviceParamKey}
									setFree={setFree}
									issueFreeTicket={issueFreeTicket}
									backFromCall={backFromCall}
									intlObj={intlObj}
									setLocale={setLocale}
								/>
							</div>
							:
							<div className={classes.content}>
								<NoTalkTimeDialog
									open={notalkTime}
									serviceParamKey={serviceParamKey}
									type={'service'}
								/>
								<ServiceAppBar
									free={free}
									appBarHeight={appBarHeight}
								/>

								{ aspectRatio < breakPointAspectRatio ?
									<>
										<div className={classes.container}>
											<div className={classes.infomationArea}>
												{ serviceTicketLogin.ticketNumberHint &&
												<div className={`${classes.ticketNumber} ${classes.ticketNumberSP}`}>
													<Typography className={classes.ticketNumberText}>
														<FormattedMessage
															id='service1.ticketcode'
															defaultMessage='Ticket Code'
														/>
													&nbsp;&nbsp;
														{ showNumberHint(
															serviceTicketLogin.ticketParamKey,
															serviceTicketLogin.ticketNumberHint
														)}
													</Typography>
													<div
														className={classes.ticketNumberBtn}
														onClick={disconnect}
													>
														<FormattedMessage
															id='service1.disconnect'
															defaultMessage='Disconnect'
														/>
													</div>
												</div>
												}
												<div className={`${classes.informationBox} ${classes.informationBoxSP}`}>
													{ 	serviceTicketLogin.duration === -1 ?
														<div className={classes.expiryDateNoLimit}>
															<Typography className={`${classes.expiryDateText} ${classes.expiryDateTextNoLimit}`
															}
															>
																<FormattedMessage
																	id='service.period'
																	defaultMessage='Expiration'
																/>
															</Typography>
															<Typography className={`${classes.expiryDateText} ${classes.expiryDateTextNoLimit}`
															}
															>
																{showExpiryTime(serviceTicketLogin.expiryTimestamp)}
															</Typography>
														</div>
														: (serviceTicketLogin.expiryTimestamp
														||serviceTicketLogin.expiryTimestamp === 0)
															? <Typography className={free ? `${classes.expiryDateText} ${classes.expiryDateTextFree} ${classes.expiryDateTextWithLimit}`
																: `${classes.expiryDateText} ${classes.expiryDateTextWithLimit}`
															} >
																<FormattedMessage
																	id='service.period'
																	defaultMessage='Expiration'
																/>&nbsp;&nbsp;&nbsp;&nbsp;
																{showExpiryTime(serviceTicketLogin.expiryTimestamp)}
															</Typography>
															: null
													}
													{ serviceTicketLogin.duration === -1 ?
														null
														: free ?
															<Typography className={classes.remainingTime}>
																<Typography className={classes.remainingTimeTextFree}>
																	<FormattedMessage
																		id='freeservice3.vaildTime'
																		defaultMessage='Free use once [maximum 5 minutes]'
																	/>
																</Typography>
															</Typography>
															:
															<Typography className={classes.remainingTime}>
																<div className={classes.remainingTimeLine}>
																	<Typography className={classes.remainingTimeText}>
																		<FormattedMessage
																			id='service1.vaildTime'
																			defaultMessage='Currently available'
																		/>
																	</Typography>
																	<Typography className={classes.remainingTimeText}>
																		<FormattedMessage
																			id='paidservice.time'
																			defaultMessage='{minutesPurchased} minutes'
																			values={{
																				minutesPurchased : calcRemainingMinutes(0)
																			}}
																		/>
																	</Typography>
																</div>
															</Typography>
													}
													{ serviceTicketLogin.duration === -1 ?
														null
														: free ?
															<Typography
																className={`${classes.explanation} ${classes.explanationFree}`}
															>
																<FormattedMessage
																	id='freeservice3.caution'
																	defaultMessage='If the number of minutes used exceeds 5 minutes, the connection will be automatically disconnected.'
																/>
															</Typography>
															:
															<Typography className={classes.explanation}>
																<FormattedMessage
																	id='service1.caution'
																	defaultMessage='If the available minutes ended 0 during a call, the call will be extended by up to 2 minutes.'
																/>
															</Typography>
													}
												</div>
											</div>
											<div
												className={classes.buttonSection}
											>
												<div className={classes.buttonAreaSP} ref={buttonAreaRef}>
													<Typography className={classes.buttonBoxTitle}>
														<FormattedMessage
															id='service.selectLaunguage'
															defaultMessage='Select language and start interpreting'
														/>
													</Typography>
													<>
														{ [ ...Array(btnAreaRows.SP) ].map((_r, rowIndex) =>
														{
															return (
																<div className={classes.buttonRow} key={rowIndex}>
																	<>
																		{[ ...Array(btnAreaContent.SP.columns) ]
																			.map((_c, columnIndex) =>
																				(
																					<ServiceButton
																						type={'service'}
																						key={`${rowIndex}-${columnIndex}`}
																						rowIndex={rowIndex}
																						columnIndex={columnIndex}
																						buttons={buttons}
																						btnAreaContentVal={btnAreaContent.SP}
																						btnFlagConds={btnFlagConds}
																						roomAvailable={roomAvailable}
																						handleJoin={handleJoin}
																					/>
																				)
																			)
																		}
																	</>
																</div>
															);
														}
														)}
													</>

												</div>
											</div>

											<div className={classes.naviSection}>
												<div className={classes.naviItemWrapper}>
													<div className={classes.naviItem}>
														<img
															alt={'home'}
															src={homeImg}
															className={classes.naviImg}
														/>
														<Typography className={classes.naviTextHome}>
															<FormattedMessage
																id='service.homeTab'
																defaultMessage='Home'
															/>
														</Typography>
													</div>
													<div
														className={`${classes.naviItem} ${classes.naviItemLink}`}
														onClick={moveToPurchasePage}
													>
														<img
															alt={'purchase'}
															src={purchaseImg}
															className={classes.naviImg}
														/>
														<Typography className={classes.naviTextLink}>
															<FormattedMessage
																id='service.buyTicketTab'
																defaultMessage='Buy ticket'
															/>
														</Typography>
													</div>
												</div>
											</div>
										</div>

									</>
									:
									<>

										<div className={classes.container}>
											<div className={classes.infomationArea}>
												{ serviceTicketLogin.ticketNumberHint &&
												<div className={`${classes.ticketNumber} ${classes.ticketNumberPC}`}>
													<Typography className={classes.ticketNumberText}>
														<FormattedMessage
															id='service1.ticketcode'
															defaultMessage='Ticket Code'
														/>
													&nbsp;&nbsp;
														{ showNumberHint(
															serviceTicketLogin.ticketParamKey,
															serviceTicketLogin.ticketNumberHint
														)}
													</Typography>
													<div
														className={classes.ticketNumberBtn}
														onClick={disconnect}
													>
														<FormattedMessage
															id='service1.disconnect'
															defaultMessage='Disconnect'
														/>
													</div>
												</div>
												}
												<div className={`${classes.informationBox} ${classes.informationBoxPC}`}>
													{ 	serviceTicketLogin.duration === -1 ?
														<div className={classes.expiryDateNoLimit}>
															<Typography className={classes.expiryDateText
															}
															>
																<FormattedMessage
																	id='service.period'
																	defaultMessage='Expiration'
																/>
															</Typography>
															<Typography className={classes.expiryDateText
															}
															>
																{showExpiryTime(serviceTicketLogin.expiryTimestamp)}
															</Typography>
														</div>
														: (serviceTicketLogin.expiryTimestamp
														||serviceTicketLogin.expiryTimestamp === 0) ?
															<Typography
																className={free ? `${classes.expiryDateText} ${classes.expiryDateTextFree} ${classes.expiryDateTextWithLimit}`
																	: `${classes.expiryDateText} ${classes.expiryDateTextWithLimit}`
																}
															>
																<FormattedMessage
																	id='service.period'
																	defaultMessage='Expiration'
																/>&nbsp;&nbsp;&nbsp;&nbsp;
																{showExpiryTime(serviceTicketLogin.expiryTimestamp)}
															</Typography>
															: null
													}
													{ serviceTicketLogin.duration === -1 ?
														null
														: free ?
															<Typography className={classes.remainingTime}>
																<Typography className={classes.remainingTimeTextFree}>
																	<FormattedMessage
																		id='freeservice3.vaildTime'
																		defaultMessage='Free use once [maximum 5 minutes]'
																	/>
																</Typography>
															</Typography>
															:
															<Typography className={classes.remainingTime}>
																<div className={classes.remainingTimeLine}>
																	<Typography className={classes.remainingTimeText}>
																		<FormattedMessage
																			id='service1.vaildTime'
																			defaultMessage='Currently available'
																		/>
																	</Typography>
																	<Typography className={classes.remainingTimeText}>
																		<FormattedMessage
																			id='paidservice.time'
																			defaultMessage='{minutesPurchased} minutes'
																			values={{
																				minutesPurchased : calcRemainingMinutes(0)
																			}}
																		/>
																	</Typography>
																</div>
															</Typography>
													}
													{ serviceTicketLogin.duration === -1 ?
														null
														: free ?
															<Typography
																className={`${classes.explanation} ${classes.explanationFree}`}
															>
																<FormattedMessage
																	id='freeservice3.caution'
																	defaultMessage='When the call exceeds {minutes} minutes, automatically disconnected'
																	values={{
																		minutes : calcRemainingMinutes(2)
																	}}
																/>
															</Typography>
															:
															<Typography className={classes.explanation}>
																<FormattedMessage
																	id='service1.caution'
																	defaultMessage='When remaning time become 0, Max {minutes} minutes extended.'
																	values={{
																		minutes : 2
																	}}
																/>
															</Typography>
													}
												</div>
											</div>
											<div
												className={classes.buttonSection}
											>
												<div className={classes.buttonAreaPC} ref={buttonAreaRef}>
													<Typography className={classes.buttonBoxTitle}>
														<FormattedMessage
															id='service.selectLaunguage'
															defaultMessage='Select language and start interpreting'
														/>
													</Typography>
													<>
														{ [ ...Array(btnAreaRows.PC) ].map((_r, rowIndex) =>
														{
															return (
																<div className={classes.buttonRow} key={rowIndex}>
																	<>
																		{[ ...Array(btnAreaContent.PC.columns) ]
																			.map((_c, columnIndex) =>
																				(
																					<ServiceButton
																						type={'service'}
																						key={`${rowIndex}-${columnIndex}`}
																						rowIndex={rowIndex}
																						columnIndex={columnIndex}
																						buttons={buttons}
																						btnAreaContentVal={btnAreaContent.PC}
																						btnFlagConds={btnFlagConds}
																						roomAvailable={roomAvailable}
																						handleJoin={handleJoin}
																					/>
																				)
																			)
																		}
																	</>
																</div>
															);
														}
														)}
													</>
												</div>
											</div>
											<div className={classes.naviSection}>
												<div className={classes.naviItemWrapper}>
													<div className={classes.naviItem}>
														<img
															alt={'home'}
															src={homeImg}
															className={classes.naviImg}
														/>
														<Typography className={classes.naviTextHome}>
															<FormattedMessage
																id='service.homeTab'
																defaultMessage='Home'
															/>
														</Typography>
													</div>
													<div
														className={`${classes.naviItem} ${classes.naviItemLink}`}
														onClick={moveToPurchasePage}
													>
														<img
															alt={'purchase'}
															src={purchaseImg}
															className={classes.naviImg}
														/>
														<Typography className={classes.naviTextLink}>
															<FormattedMessage
																id='service.buyTicketTab'
																defaultMessage='Buy ticket'
															/>
														</Typography>
													</div>
												</div>
											</div>
										</div>

									</>
								}
								<JoinRoomNoticeDialog
									type={'service'}
									serviceConnectionData={serviceConnectionData}
								/>
							</div>
						}
					</div>
				</>
			}
		</MuiThemeProvider>
	);
};

ServiceHome.propTypes =
{
	room                        : PropTypes.object.isRequired,
	classes                     : PropTypes.object.isRequired,
	intlObj                     : PropTypes.object.isRequired,
	updateIntl                  : PropTypes.func.isRequired,
	setJoinRoomNoticeDialogOpen : PropTypes.func.isRequired,
	serviceTicketLogin          : PropTypes.object.isRequired,
	setServiceTicketLogin       : PropTypes.func.isRequired,
	serviceGuestLoggedIn        : PropTypes.bool.isRequired,
	setServiceGuestLoggedIn     : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		room                 : state.room,
		intlObj              : state.intl,
		serviceTicketLogin   : state.me.serviceTicketLogin,
		serviceGuestLoggedIn : state.me.serviceGuestLoggedIn
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setJoinRoomNoticeDialogOpen : (joinRoomNoticeDialogOpen) =>
		{
			dispatch(roomActions.setJoinRoomNoticeDialogOpen(joinRoomNoticeDialogOpen));
		},
		updateIntl : (args) =>
		{
			dispatch(intlActions.updateIntl({ ...args }));
		},
		setServiceTicketLogin : (serviceTicketLogin) =>
		{
			dispatch(meActions.setServiceTicketLogin(serviceTicketLogin));
		},
		setServiceGuestLoggedIn : (open) =>
		{
			dispatch(meActions.setServiceGuestLoggedIn(open));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room &&
				prev.intl === next.intl &&
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList &&
				prev.intl.messages === next.intl.messages &&
				prev.me === next.me &&
				prev.me.serviceTicketLogin === next.me.serviceTicketLogin &&
				prev.me.serviceGuestLoggedIn === next.me.serviceGuestLoggedIn
			);
		}
	}
)(withStyles(styles)(ServiceHome)));
