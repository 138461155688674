import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import DatePickerYearMonth from './DatePickerYearMonth';

import { createRowsServiceHistory } from '../helper';

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : 'var(--background-color-btn-close)',
		color           : theme.palette.common.white,
		borderRight     : '1px solid rgba(224, 224, 224, 1)',
		'&:last-child'  : {
			borderRight : 'none'
		},
		padding : '16px 12px'
	},
	body : {
		fontSize       : 14,
		height         : 23,
		padding        : '16px 12px',
		borderRight    : '1px solid rgba(224, 224, 224, 1)',
		'&:last-child' : {
			borderRight : 'none'
		}
	},
	'@media (max-width: 768px)' : {
		head : {
			fontSize : '12px'
		}
	}
}))(TableCell);

const StyledTableRow = withStyles(() => ({
	root : {
		'&:nth-of-type(odd)' : {
			backgroundColor : '#FFF'
		},
		'&:nth-of-type(even)' : {
			backgroundColor : '#f4f5f5'
		},
		'& td' : {
			borderBottom : 'none'
		},
		'&:last-child td:first-child' : {
			borderBottomLeftRadius : '8px'
		},
		'&:last-child td:last-child' : {
			borderBottomRightRadius : '8px'
		}
	}
}))(TableRow);

const styles = () => ({
	tableRowStyles : {
		container : {
			padding : '10px'
		},
		cellHead : {
			borderRight : '1px solid #c3c3c3'
		},
		head : {
			borderRadius : '5px'
		},
		table : {
			marginTop    : '10px',
			minWidth     : 700,
			boxShadow    : '0px 4px 6px -2px #c3c3c3',
			borderRadius : '8px'
		},
		lastRow : {
			'& tr' : {
				marginTop : '10px'
			},
			'& td' : {
				borderBottom : '2px solid #cacaca'
			}
		},
		categoryCell : {
			borderBottom : '2px solid #cacaca'
		}
	},
	table : {
		table : {
			marginTop    : '10px',
			minWidth     : 700,
			boxShadow    : '0px 4px 6px -2px #c3c3c3',
			borderRadius : '8px'
		},
		minWidth  : 500,
		marginTop : 0
	},
	cellHead : {
		borderRight : '1px solid #c3c3c3'
	},
	hostTableRow : {
		'& th:first-child' : {
			borderRadius : '8px 0 0 0'
		},
		'& th:last-child' : {
			borderRadius : '0 8px 0 0'
		}
	},
	hostGlobalText : {
		'& th, & td' : {
			color : '#939292'
		}
	},
	tableContainer : {
		width              : '100%',
		maxHeight          : '80%',
		borderRadius       : '8px',
		overflow           : 'hidden',
		display            : 'flex',
		justifyContent     : 'start',
		alignItems         : 'center',
		flexDirection      : 'column',
		padding            : '20px',
		'& .MuiPaper-root' : {
			border       : '1px solid rgba(224, 224, 224, 1)',
			boxShadow    : 'none',
			borderRadius : '8px'
		}
	},
	titleContainer : {
		display        : 'flex',
		justifyContent : 'center',
		alignItems     : 'center',
		gap            : '6px '
	},
	icon : {
		width  : '64px',
		height : '64px'
	},
	title : {
		fontSize : '24px'
	},
	backgroundColorGray : {
		backgroundColor : '#929291'
	},
	'@media (max-width: 768px)' : {
		tableContainer : {
			maxHeight : '70%',
			padding   : '10px'
		},
		icon : {
			width  : '46px',
			height : '46px'
		},
		title : {
			fontSize : '16px'
		},
		table : {
			minWidth : 300
		}
	},
	tableWrapper : {
		maxHeight       : '100%',
		overflow        : 'auto',
		backgroundColor : '#00B5A5',
		'& tbody'       : {
			display  : 'block',
			overflow : 'auto'
		},
		'& thead' : {
			display     : 'table',
			width       : '100%',
			tableLayout : 'fixed'
		},
		'& thead tr' : {
			display     : 'table',
			width       : '100%',
			tableLayout : 'fixed'
		},
		'& tbody tr' : {
			display     : 'table',
			width       : '100%',
			tableLayout : 'fixed'
		}
	}
});

const TableTranslationHistory = ({ classes, calls, buttons }) =>
{
	const [ dateYearMonth, setDateYearMonth ] = useState(new Date());

	return (
		<TableContainer component={Paper} className={classes.tableContainer}>
			<DatePickerYearMonth
				dateYearMonth={dateYearMonth}
				setDateYearMonth={setDateYearMonth}
			/>
			<div className={classes.tableWrapper}>
				<Table stickyHeader className={classes.table}>
					<TableHeader classes={classes} />
					<TableBodyServiceHistory
						classes={classes}
						timestamp={dateYearMonth}
						calls={calls}
						buttons={buttons}
					/>
				</Table>
			</div>
		</TableContainer>
	);
};

const TableHeader = ({ classes }) =>
{
	const intl = useIntl();

	const tableHeads = [
		{ id: 'connect.table.header.datetime' },
		// { id: 'connect.table.header.country' },
		{ id: 'connect.table.header.language' },
		{ id: 'connect.table.header.duration' }
	];

	return (
		<TableHead>
			<TableRow className={classes.hostTableRow}>
				{tableHeads.map((head) => (
					<StyledTableCell key={head.id} className={classes.cellHead}>
						{intl.formatMessage({ id: head.id })}
					</StyledTableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const TableBodyServiceHistory = ({ classes, timestamp, calls, buttons }) =>
{
	const intl = useIntl();
	const rows = createRowsServiceHistory({ timestamp, calls });

	if (rows.length === 0)
	{
		return (
			<TableBody className={classes.hostGlobalText}>
				<StyledTableRow>
					<StyledTableCell colSpan={4} style={{ textAlign: 'center', height: 300 }}>
						{intl.formatMessage({ id: 'connect.table.empty' })}
					</StyledTableCell>
				</StyledTableRow>
			</TableBody>
		);
	}

	const showSkillText = (row, btns) =>
	{
		// スキルなし
		if (!row.skill || row.skill === '_')
		{
			return '';
		}
		else
		{
			// 翻訳テキストを探す
			const translationText= intl.formatMessage({ id: `connect.service.${row.skill?.toLowerCase()}` });

			// 翻訳あり(ない場合は`connect.service.${row.skill?.toLowerCase()}`の値になる)
			if (translationText !== `connect.service.${row.skill?.toLowerCase()}`)
			{
				return translationText;
			}
			else
			{
				// buttonsから探す
				const targetBtn = btns.find((btn) =>
					btn.skillId === row.skill && btn.subdomain === row.subdomain);

				if (targetBtn)
				{
					// ボタンlabelあり
					if (targetBtn.label)
					{
						return targetBtn.label;
					}
					// なければskillId
					else
					{
						return targetBtn.skillId;
					}
				}
				else
				{
					return 'Unknown';
				}
			}
		}

	};

	return (
		<TableBody className={classes.hostGlobalText}>
			{rows.map((row, index) => (
				<StyledTableRow key={index}>
					<StyledTableCell>{row.timestamp}</StyledTableCell>
					{/* <StyledTableCell>
						{intl.formatMessage({ id: `connect.country.${row?.country?.toLowerCase() || 'unknown'}` })}
					</StyledTableCell> */}
					<StyledTableCell>
						{showSkillText(row, buttons)}
					</StyledTableCell>
					<StyledTableCell>{row.duration}</StyledTableCell>
				</StyledTableRow>
			))}
		</TableBody>
	);
};

export default withStyles(styles)(TableTranslationHistory);
