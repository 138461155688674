import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Box, Modal } from '@material-ui/core';

import Overall from './Parts/overall/Overall';
import Skill from './Parts/skill/Skill';
import Header from './Parts/header/Header';
import Operator from './Parts/operator';

// firestore
import { firestore } from '../../../../lib/firebase';

const styles = (theme) => ({
	ReportContainer : {
		zIndex : 1
	},
	hostContainerReport : {
		width  : '100%',
		height : '100vh'
	},
	hostContainerOperator : {
		display             : 'grid',
		gridTemplateColumns : '35% auto',
		gap                 : '10px'
	},
	hostModal : {
		padding : '30px'
	},
	Report : {
		backgroundColor        : theme.palette.background.default,
		borderRadius           : '7px',
		height                 : 'calc(97vh - 30px)',
		overflow               : 'auto',
		padding                : '30px',
		'&::-webkit-scrollbar' : {
			display : 'none'
		},
		scrollbarWidth       : 'none',
		'-ms-overflow-style' : 'none'
	},
	hostContainerOperatorSkill : {
		display             : 'grid',
		gridTemplateColumns : '35% auto',
		gap                 : '10px',
		marginBottom        : '20px'
	},
	hostTableWrapper : {
		overflowX               : 'auto', // Horizontal scrolling for large tables
		WebkitOverflowScrolling : 'touch' // Smooth scrolling on iOS
	},
	table : {
		borderCollapse : 'collapse',
		width          : '100%',
		minWidth       : '800px', // Ensures tables have a minimum width
		marginBottom   : '20px'
	},
	// Media queries
	'@media (max-width: 1680px)' : {
		hostContainerOperatorSkill : {
			gridTemplateColumns : '1fr',
			gap                 : '20px'
		},
		hostContainerOperator : {
			gridTemplateColumns : '1fr',
			gap                 : '20px',
			marginBottom        : '20px'
		},
		Report : {
			width   : '100%',
			margin  : '0 auto',
			padding : '10px'
		},
		hostHeaderContainer : {
			textAlign : 'center',
			padding   : '0 15px'
		},
		hostTableWrapper : {
			overflowX               : 'auto', // Allows horizontal scrolling
			WebkitOverflowScrolling : 'touch' // Smooth scrolling on iOS
		},
		table : {
			width    : '100%',
			minWidth : '600px' // Ensures tables have a minimum width
		}
	},
	'@media (max-width: 768px)' : {
		ReportContainer : {
			width : '100vw'
		},
		hostModal : {
			padding : '20px 10px',
			width   : '100vw'
		},
		Report : {
			padding : '5px',
			height  : 'calc(97vh - 10px)'
		},
		hostHeaderContainer : {
			button : {
				marginTop : '10px',
				width     : '100%'
			}
		}
	}
});

const Report = ({
	classes,
	show,
	connectionAccountId,
	subdomains,
	buttons,
	close
}) =>
{
	const [ calls, setCalls ] = useState([]);
	const [ roomsSnapshots, setRoomsSnapshots ] = useState([]);
	const [ queueResults, setQueueResults ] = useState([]);

	const fetchCalls = useCallback((accountId, btns) =>
	{
		if (accountId)
		{
			firestore.collection('calls').where('connectionAccountId', '==', accountId)
				.onSnapshot((snapshot) =>
				{
					const callsAr = [];

					snapshot.forEach((doc) =>
					{
						const docData = doc.data();

						const matchButtonsCond = btns.some((btn) =>
							btn.subdomain === docData.subdomain
							&& btn.skillId === docData.skillId
						);

						if (
							matchButtonsCond
						)
						{
							callsAr.push({
								id : doc.id,
								...docData
							});
						}
					});
					setCalls(callsAr);

					callsAr.sort((a, b) =>
					{
						if (a.timestamp < b.timestamp) { return -1; }
						if (a.timestamp > b.timestamp) { return 1; }

						return 0;
					});
				});
		}
	}, [ ]);

	const fetchRoomsSnapshots = useCallback((sdomains, btns) =>
	{
		if (sdomains && sdomains.length > 0)
		{
			firestore.collection('roomsSnapshots').where('subdomain', 'in', subdomains)
				.onSnapshot((snapshot) =>
				{
					const roomsSnapshotsAr = [];

					snapshot.forEach((doc) =>
					{

						const docData = doc.data();

						// btnsの中で中でsubdomain・skillの条件にマッチするものが一つでもあるか
						const matchButtonsCond = btns.some((btn) =>
						{
							// docData.roomsの中でsubdomain・skillの条件にマッチするものが一つでもあるか
							return docData.rooms.some((rm) =>
								docData.subdomain === btn.subdomain &&
								(btn.skillId === '_' || rm.skills.includes(btn.skillId))
							);
						});

						if (matchButtonsCond)
						{
							roomsSnapshotsAr.push({
								...doc.data()
							});
						}
					});

					roomsSnapshotsAr.sort((a, b) =>
					{
						if (a.timestamp < b.timestamp) { return 1; }
						if (a.timestamp > b.timestamp) { return -1; }

						return 0;
					});

					setRoomsSnapshots(roomsSnapshotsAr);
				});
		}
	}, [ subdomains ]);

	const fetchQueueResults = useCallback((connAccountId, btns) =>
	{
		if (connAccountId)
		{
			firestore.collection('queueResults').where('connectionAccountId', '==', connAccountId)
				.onSnapshot((snapshot) =>
				{
					const queueLogs = [];

					snapshot.forEach((doc) =>
					{
						const docData = doc.data();

						const matchButtonsCond = btns.some((btn) =>
							btn.subdomain === docData.subdomain
							&& btn.skillId === docData.skillId
						);

						if (
							matchButtonsCond
						)
						{
							queueLogs.push({
								id : doc.id,
								...docData
							});
						}
					});

					queueLogs.sort((a, b) =>
					{
						if (a.start < b.start) { return -1; }
						if (a.start > b.start) { return 1; }

						return 0;
					});

					setQueueResults(queueLogs);
				});
		}
	}, []);

	useEffect(() =>
	{
		if (show)
		{
			fetchCalls(connectionAccountId, buttons);
			fetchRoomsSnapshots(subdomains, buttons);
			fetchQueueResults(connectionAccountId, buttons);
		}
	}, [
		show,
		connectionAccountId,
		subdomains,
		buttons,
		fetchCalls,
		fetchRoomsSnapshots,
		fetchQueueResults
	]);

	return (
		<div className={classes.ReportContainer}>
			<Modal
				open={show}
				onClose={close}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className={classes.hostModal}
			>
				<Box>
					<div className={classes.Report}>
						<Header close={close} />
						<div>
							<div className={classes.hostContainerOperatorSkill}>
								<Overall
									calls={calls}
									roomsSnapshots={roomsSnapshots}
									queueResults={queueResults}
									subdomains={subdomains}
								/>
								<Skill
									calls={calls}
									roomsSnapshots={roomsSnapshots}
									queueResults={queueResults}
									buttons={buttons}
								/>
							</div>
							<div className={classes.hostContainerOperator}>
								<Operator
									calls={calls}
									roomsSnapshots={roomsSnapshots}
								/>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default withStyles(styles)(Report);
