import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
// Mui core
import { Box, Button, Typography } from '@material-ui/core';

const styles = () =>
	({
		root : {
			width      : '100%',
			margin     : '20px 0',
			lineHeight : 1
		},
		titleBox : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '20px 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		},
		inputLabel : {
			width         : '50%',
			fontSize      : '0.8rem',
			paddingTop    : '10px',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		label : {
			color        : 'white',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		messageBox : {
			marginTop : '10px',
			width     : '100%'
		},
		message : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		imageBox : {
			marginTop      : '10px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		image : {
			height : '60px',
			width  : 'auto'
		},
		startBtn : {
			marginTop       : '4px',
			height          : '1.8rem',
			backgroundColor : '#9ec317',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : '#9ec317',
				color           : '#FFF',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		startBtnComplete : {
			backgroundColor : '#f50057',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : '#f50057',
				color           : '#FFF',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		hiddenInput :
		{
			display : 'none'
		}
	});

const BtnImage = ({
	classes,
	btnKey,
	newBtnImages,
	setNewBtnImages,
	imageUrl,
	setImageUrl
}) =>
{
	const hiddenFileInput = useRef();

	const newImageFile = newBtnImages.find((item) => item.btnKey === btnKey);

	const getFile = () =>
	{
		hiddenFileInput.current.click();
	};

	const onUploadBtnImg = useCallback((event) =>
	{

		const fileUploaded = event.target.files[0];

		const fileReader = new FileReader();

		fileReader.onload = async function()
		{
			const fileName =fileUploaded.name;

			const fileExtensionAr = fileName.split('.');

			const fileExtension = fileExtensionAr[fileExtensionAr.length-1];

			if (fileExtension && fileUploaded)
			{
				// setBtnImageName(`${btnKey}.${fileExtension}`);
				// setBtnImageFile(fileUploaded);
				setNewBtnImages((state) =>
				{
					let prevImageUrl = imageUrl;

					let prevState = [ ...state ];

					// 複数回imageを変更していないか検知
					const sameBtnKeyItem = prevState.find((itm) => itm.btnKey === btnKey);

					// 複数回の変更の場合、前回までの変更は削除してアップロード取りやめ。prevImageUrlを引き継ぎ。
					if (sameBtnKeyItem)
					{
						prevImageUrl = sameBtnKeyItem.prevImageUrl;
						// 同じbtnKeyのアイテムを取り除く
						prevState = prevState.filter((itm) => itm.btnKey !== btnKey);
					}

					const timestamp = new Date().valueOf();

					const src = URL.createObjectURL(fileUploaded);

					return [
						...prevState,
						{
							btnKey       : btnKey,
							btnImageFile : fileUploaded,
							btnImageName : `${btnKey}-${timestamp}.${fileExtension}`,
							prevImageUrl : prevImageUrl,
							tempFile     : src
						}
					];
				});

				setImageUrl('');
			}
		};

		try
		{
			fileReader.readAsDataURL(fileUploaded);
		}
		catch (e)
		{
			// eslint-disable-next-line no-console
			console.log('file read error:', e);
		}

	}, [ setNewBtnImages, btnKey, imageUrl, setImageUrl ]);

	return (
		<Box className={classes.root}>
			<Box className={classes.titleBox}>
				<Box className={classes.inputLabel}>
					<Typography className={classes.label}>ボタン画像設定</Typography>
				</Box>
				<Button
					className={newImageFile?.tempFile ? classes.startBtnComplete : classes.startBtn}
					type='text'
					onClick={getFile}
				>
					{ newImageFile?.tempFile || imageUrl ? '画像を変更' :'画像を追加'}

				</Button>
			</Box>

			{ newImageFile?.tempFile &&
				<Box className={classes.messageBox}>
					<Typography className={classes.message}>ファイルがアップロードされました</Typography>
					<Typography className={classes.message}>アカウントを保存してください</Typography>
				</Box>
			}
			{ (newImageFile?.tempFile || imageUrl) &&
				<Box className={classes.imageBox}>
					<img
						className={classes.image}
						src={newImageFile?.tempFile || imageUrl}
						alt='buttonImage'
					/>
				</Box>
			}

			{/* hidden input in order to update file */}
			<input
				type='file'
				accept='image/*'
				ref={hiddenFileInput}
				onChange={onUploadBtnImg}
				className={classes.hiddenInput}
			/>
		</Box>
	);
};

BtnImage.propTypes =
{
	classes         : PropTypes.object.isRequired,
	btnKey          : PropTypes.string.isRequired,
	newBtnImages    : PropTypes.array.isRequired,
	setNewBtnImages : PropTypes.string.isRequired,
	imageUrl        : PropTypes.string,
	setImageUrl     : PropTypes.func.isRequired
};

export default withRoomContext(withStyles(styles)(BtnImage));
