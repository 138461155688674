import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// util
import { showTaxIncludedAmount, getDomainWithoutSubdomain, showDateTime } from '../../../utils';

// const
import { PAYMENT_MONTHLY, ISSUED_AT_SYSTEM_ADMIN_PAGE, ISSUED_AT_ADMIN_PAGE } from '../../../const';

// firestore
import { functions } from '../../../lib/firebase';

// dayjs
import dayjs from 'dayjs';

const styles = () =>
	({
		titleSection :
		{
			width    : '100%',
			position : 'relative'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'var(--text-color)',
			fontSize : '1.1rem'
		},
		label : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		messageSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginBottom   : '10px'
		},
		message : {
			fontSize : '0.8rem',
			color    : 'red'
		},
		table : {
			margin : '15px 0'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.2rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			'&:focus'       : {
				outline : 'none'
			}
		},
		priceListInputKey : {
			minWidth : '95%',
			maxWidth : '220px'
		},
		priceListInput :
		{
			minWidth : '85%',
			maxWidth : '160px'
		},
		noItemRow : {
			color : ' var(--text-color)'
		},
		errorText : {
			color : 'red'
		},
		dialogButtons :
		{
			marginTop      : '40px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		purchaseBtn : {
			padding         : '5px 30px',
			minWidth        : '115px',
			fontSize        : '0.9rem',
			border          : '1px solid red',
			backgroundColor : 'red',
			color           : 'white',
			margin          : '0 5%',
			'&:hover' :
			{
				backgroundColor : 'red',
				color           : 'white',
				filter          : 'saturate(95%) brightness(95%)'
			}
		},
		purchaseBtnDisabled : {
			backgroundColor : '#AAA !important',
			color           : '#FFF !important',
			border          : 'none !important',
			margin          : '0 5%',
			'&:hover' :
			{
				backgroundColor : '#AAA !important',
				filter          : 'saturate(95%) brightness(95%)'
			}
		},
		btnSection : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			margin         : '15px 0'
		},
		actionBtn : {
			color           : '#FFF',
			border          : '1px solid #00B79C',
			backgroundColor : '#00B79C',
			padding         : '5px 30px',
			minWidth        : '115px',
			'&:hover'       : {
				backgroundColor : '#00B79C',
				filter          : 'saturate(95%) brightness(95%)'
			}
		},
		cancelBtn :
		{
			padding         : '5px 30px',
			minWidth        : '115px',
			fontSize        : '0.9rem',
			border          : '1px solid var(--text-color)',
			backgroundColor : 'transparent',
			color           : 'var(--text-color)',
			margin          : '0 20px'
		},
		loading :
		{
			width           : '100%',
			height          : '100%',
			minWidth        : '25vw',
			minHeight       : '40vh',
			zIndex          : 9999999,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		},
		statusChanging : {
			width           : '100%',
			height          : '63px',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		},
		MuiTableCell : {
			root : {
				overflowWrap : 'break-word',
				padding      : '12px 8px'
			}
		}
	}
});

const showExpiryDate = (timestamp) =>
{
	if (timestamp === 0 || timestamp === '0')
	{
		return '';
	}
	else if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY.MM.DD H:mm');
	}
	else
	{
		return '';
	}
};

const showExpiryTerm = (term) =>
{
	if (term.value === 0)
	{
		return '無制限';
	}
	else
	{
		return `${term.value}${term.type === 'month' ? 'ヶ月' : '日'}`;
	}
};

const showEntryKey = (entryKey) =>
{

	if (entryKey.startsWith('flexDays'))
	{
		const entryKeyAr = entryKey.split('-');

		if (entryKeyAr.length >= 2)
		{
			return `${entryKeyAr[1]}日間`;
		}
		else
		{
			return '';
		}
	}
	else
	{
		return entryKey;
	}

};

const ConnectPurchaseContent = ({
	classes,
	closeMethod,
	accountId,
	paramKey,
	fileResult,
	priceListPurchase,
	buyer,
	page,
	setPage
}) =>
{

	const [ countPurchaseItems, setCountPurchaseItems ] = useState(0);

	const [ loading, setLoading ] = useState(false);

	const [ createdConnections, setCreatedConnections ] = useState([]);

	const [ message, setMessage ] = useState('');

	const closeDialog = () =>
	{
		setMessage('');
		setLoading(false);
		closeMethod();
	};

	const showDurationMinutes = (duration) =>
	{
		if (duration === -1)
		{
			return '無制限';
		}
		else if (duration > 0)
		{
			return `${Math.ceil(duration/60)}分`;
		}
		else
		{
			return '0分';
		}
	};

	useEffect(() =>
	{

		if (priceListPurchase)
		{
			let count = 0;

			priceListPurchase.forEach((item) =>
			{
				count += item.quantity;
			});

			setCountPurchaseItems(count);
		}

		if (fileResult)
		{
			let hasError = false;

			for (const item of fileResult)
			{
				if (item.status !== 'success')
				{
					hasError = true;
					break;
				}
			}

			if (hasError)
			{
				setMessage('読み込み失敗した行があります');
			}
		}

	}, [ priceListPurchase, fileResult ]);

	const purchaseConnections = async () =>
	{
		if (countPurchaseItems > 0)
		{
			setMessage('');

			const connectionCreateAPI = functions.httpsCallable('connectionCreate');

			const domain = getDomainWithoutSubdomain();

			setLoading(true);

			// 送信先メールアドレスごとのobject
			const destinations = {};

			fileResult.forEach((item) =>
			{
				if (item.email)
				{
					// 送信先emailがdestinationsに存在
					if (destinations[item.email])
					{
						const connectionIndex
							= destinations[item.email].findIndex((c) => c.entryKey === item.entryKey);

						// entryKeyが存在 => 数を増やす
						if (connectionIndex !== -1)
						{
							// quantityを増やす
							destinations[item.email][connectionIndex].quantity += item.quantity;
						}
						// entryKeyが存在しない => collectionsに追加
						else
						{
							const priceListItem
								= priceListPurchase.find((pi) => pi.entryKey === item.entryKey);

							if (priceListItem)
							{
								destinations[item.email].push({
									...priceListItem,
									...item
								});
							}
						}
					}
					// 送信先emailがdestinationsに存在しない => 追加
					else
					{
						const priceListItem
								= priceListPurchase.find((pi) => pi.entryKey === item.entryKey);

						if (priceListItem)
						{
							destinations[item.email] = [ {
								...priceListItem,
								...item
							} ];
						}
					}
				}
			});

			const destinationsAr = Object.keys(destinations);

			let destinationsNotComplate = [ ...destinationsAr ];

			// same createdAt for same order timing
			let createdAt = null;

			try
			{
				for await (const email of destinationsAr)
				{
					const result = await connectionCreateAPI({
						connectAccountId : accountId,
						accountParamKey  : paramKey,
						connections      : destinations[email],
						domain           : domain,
						buyer            : buyer,
						email            : email,
						salesChannel     : buyer === 'system-admin' ? ISSUED_AT_SYSTEM_ADMIN_PAGE : ISSUED_AT_ADMIN_PAGE,
						paymentMedhod    : PAYMENT_MONTHLY,
						createdAt        : createdAt
					});

					const data = result.data;

					if (data && data.success && data.connections)
					{
						destinationsNotComplate = destinationsNotComplate.filter((d) => d !== email);

						setCreatedConnections((state) =>
						{
							return [
								...state,
								...data.connections
							];
						});

						if (data.createdAt)
						{
							createdAt = data.createdAt;
						}
					}
				}

				if (destinationsNotComplate.length > 0)
				{
					let msg = '作成途中にエラーが発生しました。 送信失敗先:';

					destinationsNotComplate.forEach((d, index) =>
					{
						if (index === 0)
						{
							msg += d;
						}
						else
						{
							msg += ` ,${d}`;
						}
					});

					setMessage(msg);
				}

				setPage('complete');

				setLoading(false);
			}
			catch
			{
				setMessage(() =>
				{
					let msg = '作成途中にエラーが発生しました。';

					if (destinationsNotComplate.length > 0)
					{
						msg += ' 送信失敗先:';

						destinationsNotComplate.forEach((d, index) =>
						{
							if (index === 0)
							{
								msg += d;
							}
							else
							{
								msg += ` ,${d}`;
							}
						});
					}

					return msg;
				});

				setLoading(false);
			}

		}
	};

	const downloadFile = ({ data, fileName, fileType }) =>
	{
		const bom = new Uint8Array([ 0xEF, 0xBB, 0xBF ]);
		const blob = new Blob([ bom, data ], { type: fileType });
		const a = document.createElement('a');

		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);

		const clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });

		a.dispatchEvent(clickEvent);
		a.remove();
	};

	const csvDownload = () =>
	{

		const domain = getDomainWithoutSubdomain();

		if (createdConnections && createdConnections.length > 0)
		{
			const headers = [ 'メールアドレス,接続キー,利用可能時間(秒),通話時間(秒),定価, 卸売価格 ,手数料,有効期限,URL' ];

			const body = [];

			createdConnections.forEach((d) =>
			{
				const {
					email,
					accountParamKey,
					connectionKey,
					duration,
					salesAmount,
					holesaleAmount,
					commission,
					expiryTimestamp
				} = d;

				body.push([ email, connectionKey, duration, salesAmount, holesaleAmount, commission, showDateTime(expiryTimestamp), `https://${domain}/connect?account=${accountParamKey}&key=${connectionKey}` ].join(','));
			});

			downloadFile({
				data     : [ ...headers, ...body ].join('\n'),
				fileName : 'connection_calls.csv',
				fileType : 'text/csv'
			});
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			{ page === 'file' ?
				<>
					<div className={classes.titleSection}>
						<div className={classes.title}>
							<Typography className={classes.titleText}>
								ファイル読み込み情報
							</Typography>
						</div>
					</div>
					{ loading ?
						<Box className={classes.loading}>
							<CircularProgress />
						</Box>
						:
						<>
							{ message &&
								<Box className={classes.messageSection}>
									<Typography className={classes.message}>
										{message}
									</Typography>
								</Box>
							}

							<TableContainer component={Paper}>
								<Table
									className={classes.table}
									aria-label='call target table'
									stickyHeader
									style={{
										tableLayout : 'fixed'
									}}
								>
									<TableHead>
										<TableRow>
											<TableCell align='left'>条件</TableCell>
											<TableCell align='left'>メールアドレス</TableCell>
											<TableCell align='left'>顧客ID</TableCell>
											<TableCell align='left'>数量</TableCell>
											<TableCell align='left'>ステータス</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{	fileResult.map((item, index) => (
											<TableRow key={index}>
												<TableCell align='left'>
													{showEntryKey(item.entryKey)}
												</TableCell>
												<TableCell align='left'>
													{item.email}
												</TableCell>
												<TableCell align='left'>
													{item.customerId}
												</TableCell>
												<TableCell align='left'>
													{item.quantity}
												</TableCell>
												<TableCell align='left'>
													{item.status === 'success' ? <span>成功</span>
														: item.status === 'noId' ? <span className={classes.errorText}>IDエラー</span>
															: item.status === 'noCondition' ? <span className={classes.errorText}>条件エラー</span>
																: item.status === 'invalidEmail' ? <span className={classes.errorText}>メールアドレスエラー</span>
																	: item.status === 'noQuantity' ? <span className={classes.errorText}>数量エラー</span>
																		: item.status === 'wrongAccount' ? <span className={classes.errorText}>アカウントキーエラー</span>
																			: null}
												</TableCell>
											</TableRow>
										))
										}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					}

					<Box className={classes.dialogButtons}>

						<Button
							className={classes.cancelBtn}
							onClick={closeDialog}
							color='primary'
						>
							<FormattedMessage
								id='label.close'
								defaultMessage='Close'
							/>
						</Button>

						{ countPurchaseItems > 0 ?
							<Button
								className={classes.purchaseBtn}
								onClick={() => setPage('confirm')}
								color='primary'
							>
								購入確認
							</Button>
							:
							<Button
								className={`${classes.purchaseBtn} ${classes.purchaseBtnDisabled}`}
								onClick={() => {}}
								disabled
								color='primary'
							>
								購入確認
							</Button>
						}
					</Box>
				</>
				: page === 'confirm' ?
					<>
						<div className={classes.titleSection}>
							<div className={classes.title}>
								<Typography className={classes.titleText}>
									購入確認
								</Typography>
							</div>
						</div>
						{ loading ?
							<Box className={classes.loading}>
								<CircularProgress />
							</Box>
							:
							<>
								{ message &&
								<Box className={classes.messageSection}>
									<Typography className={classes.message}>
										{message}
									</Typography>
								</Box>
								}
								<TableContainer component={Paper}>
									<Table
										className={classes.table}
										aria-label='call target table'
										stickyHeader
										style={{
											tableLayout : 'fixed'
										}}
									>
										<TableHead>
											<TableRow>
												<TableCell align='left'>条件</TableCell>
												<TableCell align='left'>通話時間(分)</TableCell>
												<TableCell align='left'>期限</TableCell>
												<TableCell align='left'>定価</TableCell>
												<TableCell align='left'>卸売価格</TableCell>
												<TableCell align='left'>数量</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{ priceListPurchase && priceListPurchase.length > 0 ?
												<>
													{	priceListPurchase.map((item, index) => (
														<TableRow key={index}>
															<TableCell align='left'>
																<p
																	className={`${classes.inputField} ${classes.priceListInputKey}`}
																>
																	{showEntryKey(item.entryKey)}
																</p>
															</TableCell>
															<TableCell align='left'>
																{ item.minutes === -1 ?
																	<p
																		className={`${classes.inputField} ${classes.priceListInput}`}
																	>
																		無制限
																	</p>
																	:
																	<p
																		className={`${classes.inputField} ${classes.priceListInput}`}
																	>
																		{item.minutes}
																	</p>

																}
															</TableCell>
															<TableCell align='left'>
																<p
																	className={`${classes.inputField} ${classes.priceListInput}`}
																>
																	{showExpiryTerm(item.expiryTerm)}
																</p>

															</TableCell>
															<TableCell align='left'>
																{ item.usageBasedBilling && !item.salesAmount?
																	<p
																		className={`${classes.inputField} ${classes.priceListInput}`}
																	>
																		{'従量制'}
																	</p>
																	:
																	<p
																		className={`${classes.inputField} ${classes.priceListInput}`}
																	>
																		{item.salesAmount}
																	</p>
																}
															</TableCell>
															<TableCell align='left'>
																{ item.usageBasedBilling && !item.holesaleAmount?
																	<p
																		className={`${classes.inputField} ${classes.priceListInput}`}
																	>
																		{'従量制'}
																	</p>
																	:
																	<p
																		className={`${classes.inputField} ${classes.priceListInput}`}
																	>
																		{item.holesaleAmount}
																	</p>
																}

															</TableCell>
															<TableCell align='left'>
																<p
																	className={`${classes.inputField} ${classes.priceListInput}`}
																	disabled
																	type='number'
																>
																	{item.quantity}
																</p>
															</TableCell>
														</TableRow>
													))}
												</>
												:
												<TableRow>
													<TableCell
														align='center'
														className={classes.noItemRow}
														colSpan={4}
													>
														アイテムがありません
													</TableCell>
												</TableRow>

											}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						}
						{ !loading &&
							<Box className={classes.dialogButtons}>

								<Button
									className={classes.cancelBtn}
									onClick={closeDialog}
									color='primary'
								>
									<FormattedMessage
										id='label.close'
										defaultMessage='Close'
									/>
								</Button>
								<Button
									className={classes.purchaseBtn}
									onClick={purchaseConnections}
									color='primary'
								>
									購入
								</Button>
							</Box>
						}
					</>
					: page === 'complete' ?
						<>
							<div className={classes.titleSection}>
								<div className={classes.title}>
									<Typography className={classes.titleText}>
										購入完了
									</Typography>
								</div>
							</div>
							{ loading ?
								<Box className={classes.loading}>
									<CircularProgress />
								</Box>
								:
								<>
									<Box className={classes.btnSection}>
										<Button
											className={classes.actionBtn}
											onClick={csvDownload}
										>
											CSVダウンロード
										</Button>
									</Box>
									<Box className={classes.table}>
										<TableContainer component={Paper}>
											<Table
												aria-label='result table'
												stickyHeader
												style={{
													tableLayout : 'fixed'
												}}
											>
												<TableHead>
													<TableRow>
														<TableCell align='center' style={{ width: '20%' }}>メールアドレス</TableCell>
														<TableCell align='center' style={{ width: '30%' }}>接続キー</TableCell>
														<TableCell align='center' style={{ width: '10%' }}>顧客ID</TableCell>
														<TableCell align='center' style={{ width: '20%' }}>有効期限(購入後)</TableCell>
														<TableCell align='center' style={{ width: '10%' }}>通話時間</TableCell>
														<TableCell align='center' style={{ width: '10%' }}>卸売価格</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{createdConnections.map((row, index) => (
														<TableRow key={index}>
															<TableCell align='center' style={{ width: '20%' }}>{row.email}</TableCell>
															<TableCell align='center' style={{ width: '30%' }}>{row.connectionKey}</TableCell>
															<TableCell align='center' style={{ width: '10%' }}>{row.customerId}</TableCell>
															<TableCell align='center' style={{ width: '20%' }}>{showExpiryDate(row.expiryTimestamp)}</TableCell>
															<TableCell align='center' style={{ width: '10%' }}>{showDurationMinutes(row.duration)}</TableCell>
															{ row.usageBasedBilling && !row.holesaleAmount ?
																<TableCell align='center'style={{ width: '10%' }}>
																	従量制
																</TableCell>
																:
																<TableCell align='center' style={{ width: '10%' }}>
																	{showTaxIncludedAmount(row.holesaleAmount)}円(税込)
																</TableCell>
															}
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Box>
								</>
							}
							<Box className={classes.dialogButtons}>

								<Button
									className={classes.cancelBtn}
									onClick={closeDialog}
									color='primary'
								>
									<FormattedMessage
										id='label.close'
										defaultMessage='Close'
									/>
								</Button>
							</Box>
						</>
						: null
			}
		</MuiThemeProvider>
	);
};

ConnectPurchaseContent.propTypes =
{
	classes           : PropTypes.object.isRequired,
	buyer             : PropTypes.string.isRequired,
	closeMethod       : PropTypes.func.isRequired,
	accountId         : PropTypes.string.isRequired,
	paramKey          : PropTypes.string.isRequired,
	fileResult        : PropTypes.array.isRequired,
	priceListPurchase : PropTypes.array.isRequired,
	page              : PropTypes.string.isRequired,
	setPage           : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(ConnectPurchaseContent)));