// React
import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Prop types
import PropTypes from 'prop-types';

// Redux
import { withRoomContext } from '../../../../RoomContext';
import { connect } from 'react-redux';
import * as meActions from '../../../../actions/meActions';

// Components
import JoinRoomNoticeDialog from '../../Common/JoinRoomNoticeDialog';
import NoTalkTimeDialog from '../Parts/NoTalkTimeDialog';
import ConfirmDialog from '../../Common/ConfirmDialog';
import ServiceAppBar from '../Parts/ServiceAppBar';
import LandingPage from './LandingPage';
import ConnectButtonsList from './ConnectButtonsList';
import TranslationHistory from './translation-history/TranslationHistory';

import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';

// material ui 
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

// Firebase
import { firestore } from '../../../../lib/firebase';
// import { useAuthState } from 'react-firebase-hooks/auth';

// Daysjs
import dayjs from 'dayjs';

// const 
import { ROOM_ONLINE } from '../../../../const';

// util
import { parseUrlParams, getMilliSecondsNow } from '../../../../utils';

// locale
import { FormattedMessage } from 'react-intl';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const appBarHeight = 40;

const styles = () =>
	({
		root :
		{
			width           : '100%',
			height          : '100%',
			overflowX       : 'auto',
			backgroundColor : '#EAEAEA'
		},
		content : {
			width          : '100%',
			height         : '100%',
			minWidth       : '320px',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'flex-start',
			position       : 'relative'
		},
		loginContent : {
			width    : '100%',
			height   : '100%',
			minWidth : '320px'
		},
		naviSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginTop      : '20px'
		},
		naviItemWrapper : {
			width          : '80%',
			maxWidth       : '500px',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		naviItem : {
			width          : '50%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		naviItemLink : {
			cursor : 'pointer'
		},
		naviImg : {
			height : '28px',
			width  : 'auto'
		},
		naviTextHome : {
			color    : '#cd2c82',
			fontSize : '0.7rem'
		},
		naviTextLink : {
			color    : 'var(--text-color)',
			fontSize : '0.7rem'
		},
		imageContentPC : {
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100vw',
			maxHeight      : '100%'
		},
		imageContentSP : {
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100%',
			maxHeight      : '100vh'
		},
		fullCoveredScreen : {
			position        : 'fixed',
			top             : '0',
			left            : '0',
			height          : '100vh',
			width           : '100vw',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white'
		},
		loadingCircular : {
			color : '#cd2c82'
		},
		disabledIndicator : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white',
			padding         : '20px 25px',
			border          : '1px solid var(--text-color)',
			borderRadius    : '10px',
			maxWidth        : '90vw',
			marginTop       : '-10%'
		},
		disabledText : {
			fontSize  : '1.5rem',
			color     : 'var(--text-color)',
			textAlign : 'center'
		},
		backBtn : {
			padding         : '4px 20px',
			marginTop       : '3%',
			color           : '#FFF',
			backgroundColor : 'var(--text-color)',
			borderRadius    : '6px',
			fontSize        : '1.2rem',
			'&:hover'       : {
				backgroundColor : '#AAA9A9'
			}
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

let roomStatusListener = null;

let termsPage = 'default';

const ConnectHome = ({
	classes,
	connectInfos,
	setConnectInfos
}) =>
{
	const location = useLocation();

	// const [ serviceLogin ] = useAuthState(auth);

	const [ page, setPage ] = useState('landing');

	const [ authKey, setAuthKey ] = useState(null);

	const [ customerId, setCustomerId ] =useState(null);

	const [ accountParamKey, setAccountParamKey ] = useState('');

	const [ notalkTime, setNoTalkTime ] = useState(false);

	const [ connectPageData, setConnectPageData ] = useState({});

	// Image URls
	// const [ bgPCUrl, setBgPCUrl ] = useState('');
	// const [ bgSPUrl, setBgSPUrl ] = useState('');

	const [ buttons, setButtons ] = useState([]);

	// Button Area
	const [ btnAreaContent, setBtnAreaContent ] = useState({
		PC : {
			columns           : 2,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '16px',
			btnFontSizeSecond : '13px'
		},
		SP : {
			columns           : 2,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '16px',
			btnFontSizeSecond : '13px'
		}
	});

	const [ btnAreaRows, setBtnAreaRows ] = useState({
		PC : 1,
		SP : 1
	});

	// window size
	const [ aspectRatio, setAspectRatio ] = useState(window.innerWidth/window.innerHeight);

	// available room
	const [ roomAvailable, setRoomAvailable ] = useState([]);

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show    : false,
			title   : '',
			message : ''
		}
	);

	// nationalHolidays 
	const [ nationalHolidaysList, setNationalHolidaysList ] = useState([ [] ]);

	const [ calls, setCalls ] = useState();

	const [ loading, setLoading ] = useState(true);

	// const bgImgRef = useRef();
	// const bgImgAreaRef = useRef();

	const [ disabled, setDisabled ] = useState({ value: false, reason: '' });

	const [ termExpired, setTermExpired ] = useState(false);

	const updateDimensions = useCallback(() =>
	{
		setAspectRatio(window.innerWidth/window.innerHeight);

	}, [ ]);

	useEffect(() =>
	{
		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);

	}, [ updateDimensions ]);

	useEffect(() =>
	{
		// 取得が終わった段階で表示
		if (!loading)
		{
			updateDimensions();
		}

	}, [ updateDimensions, loading ]);

	const fetchInfos = useCallback(() =>
	{
		if (authKey && accountParamKey && customerId)
		{
			firestore.collection('connections')
				.where('accountParamKey', '==', accountParamKey)
				.where('connectionKey', '==', authKey)
				.where('customerId', '==', customerId)
				.get()
				.then((data) =>
				{
					if (data.docs && data.docs[0])
					{
						const connectionData = data.docs[0].data();

						if (connectionData.expiryTimestamp !== -1)
						{
							const milliSecondsNow = getMilliSecondsNow();

							if (!connectionData.expiryTimestamp
								|| milliSecondsNow > connectionData.expiryTimestamp)
							{
								setTermExpired(true);
								setLoading(false);

								return;
							}
						}

						setConnectInfos({ ...connectionData, id: data.docs[0].id });

						firestore.collection('connectAccounts')
							.doc(connectionData.accountId)
							.get()
							.then((account) =>
							{
								if (account.exists)
								{
									const connectAccountData = account.data();

									termsPage = connectAccountData.termsPage ? connectAccountData.termsPage : 'default';

									if (connectAccountData.browserTitle)
									{
										document.title = connectAccountData.browserTitle;
									}

									setDisabled(connectAccountData.disabled ? { value: true, reason: 'suspended' }: { value: false, reason: '' });

									setBtnAreaContent(connectAccountData.btnAreaContent
										?	connectAccountData.btnAreaContent
										: {
											PC : {
												columns           : 2,
												btnMargin         : '15px 15px',
												btnPadding        : '15px 40px',
												btnFontSize       : '1.5vw',
												btnFontSizeSecond : '1vw'
											},
											SP : {
												columns           : 2,
												btnMargin         : '15px 15px',
												btnPadding        : '15px 40px',
												btnFontSize       : '2vw',
												btnFontSizeSecond : '1.5vw'
											}
										}
									);

									if (connectAccountData.buttons)
									{

										const accountButtons = [ ...connectAccountData.buttons ];

										// 24時間265日対応のボタン型メッセージを表示
										if (connectAccountData.showBtnMessage)
										{
											accountButtons.push({ message: true });
										}

										// how many rows in btnArea
										const pcRows = connectAccountData.btnAreaContent?.PC?.columns
											? Math.ceil(
												accountButtons.length
											/connectAccountData.btnAreaContent.PC.columns
											) : 1;

										const spRows = connectAccountData.btnAreaContent?.SP?.columns
											? Math.ceil(
												accountButtons.length
											/connectAccountData.btnAreaContent.SP.columns
											) : 1;

										setBtnAreaRows(
											{
												PC : pcRows,
												SP : spRows
											});

										setButtons(accountButtons);
									}
									else
									{
										setButtons([]);
									}

									const buttonSubdomains = [];

									connectAccountData.buttons.forEach((b) =>
									{
										if (b.subdomain && !buttonSubdomains.includes(b.subdomain))
										{
											buttonSubdomains.push(b.subdomain);
										}
									});

									setLoading(false);

									roomStatusListener = firestore.collection('rooms')
										.where('subdomain', 'in', buttonSubdomains)
										.onSnapshot((snapshot) =>
										{
											// ボタンごとにavailable/not availableをチェック
											// {'buttonId1': true, 'buttonId2': false}
											const subdomainSkillsAvalilable = [];

											snapshot.forEach((r) =>
											{
												if (r.exists)
												{
													const roomData = r.data();

													if (roomData.status === ROOM_ONLINE)
													{
														subdomainSkillsAvalilable.push({
															subdomain : roomData.subdomain,
															skills    : roomData.skills || []
														});
													}
												}
											});

											const roomAvailableAr = [];

											// 祝日の取得の要否。
											let getNationalHolidays = false;

											// buttonsに移し替える
											if (connectAccountData?.buttons)
											{
												connectAccountData.buttons.forEach((b, btnIndex) =>
												{
													// button with skillId
													if (b.skillId && b.skillId !== '_')
													{
														const subdomainSkillIndex = subdomainSkillsAvalilable
															.findIndex((item) =>
																item.subdomain === b.subdomain
																&& item.skills.includes(b.skillId));

														if (subdomainSkillIndex !== -1)
														{
															roomAvailableAr[btnIndex] = true;
														}
														else
														{
															roomAvailableAr[btnIndex] = false;
														}
													}
													else // button without skillId
													{
														const subdomainIndex = subdomainSkillsAvalilable
															.findIndex((item) => item.subdomain === b.subdomain);

														if (subdomainIndex !== -1)
														{
															roomAvailableAr[btnIndex] = true;
														}
														else
														{
															roomAvailableAr[btnIndex] = false;
														}
													}
													// 祝日休みならgetNationalHolidaysをtrueにして取得する
													if (b.nationalHoliday)
													{
														getNationalHolidays = true;
													}
												});

												setRoomAvailable([ ...roomAvailableAr ]);

												// 必要なら祝日データを取得
												if (getNationalHolidays === true)
												{
													firestore.collection('nationalHolidaysJP').orderBy('createdAt', 'desc')
														.get()
														.then((document) =>
														{
															const nationalHolidaysAr
																= document.docs[0]?.data()?.holidays;

															if (nationalHolidaysAr)
															{
																setNationalHolidaysList([
																	nationalHolidaysAr.filter(
																		(h) =>
																			Number(h.type) <= Number(1)
																	),
																	nationalHolidaysAr.filter(
																		(h) =>
																			Number(h.type) <= Number(2)
																	),
																	nationalHolidaysAr.filter(
																		(h) =>
																			Number(h.type) <= Number(3)
																	)
																]);
															}
														});
												}
											}
										});
								}
								else
								{
									setDisabled({ value: true, reason: 'notFound' });
									setLoading(false);
								}
							});
					}
					else
					{
						setDisabled({ value: true, reason: 'notFound' });
						setLoading(false);
					}
				});
		}
	}, [ authKey, customerId, setConnectInfos, accountParamKey ]);

	const fetchCalls = useCallback(() =>
	{
		setLoading(true);

		firestore.collection('calls')
			.where('connectionAccountId', '==', connectInfos.accountId)
			.where('connectionCustomerId', '==', connectInfos.customerId)
			.get()
			.then((data) =>
			{
				if (data.docs)
				{
					const callsData = [];

					data.docs.forEach((doc) =>
					{
						callsData.push({
							id : doc.id,
							...doc.data()
						});

					});

					callsData.sort((a, b) =>
					{
						if (a.timestamp < b.timestamp) { return 1; }
						if (a.timestamp > b.timestamp) { return -1; }

						return 0;
					});

					setCalls([ ...callsData ]);
					setLoading(false);

				}
				else
				{
					setLoading(false);
				}
			})
			.catch(() =>
			{
				setLoading(false);
			})
		;

	}, [ connectInfos?.customerId, connectInfos.accountId, setCalls, setLoading ]);

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		if (paramsValue)
		{
			const paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'account', 'key', 'id' ]
			});

			if (paramsObj['account'] && paramsObj['key'] && paramsObj['id'])
			{
				setAccountParamKey(paramsObj['account']);
				setAuthKey(paramsObj['key']);
				setCustomerId(paramsObj['id']);
			}
			else
			{
				setLoading(false);
				setDisabled({ value: true, reason: 'notFound' });
				setAccountParamKey('');
				setAuthKey(null);
				setCustomerId(null);
			}
		}
		else
		{
			setLoading(false);
			setDisabled({ value: true, reason: 'notFound' });
			setAccountParamKey('');
			setAuthKey(null);
			setCustomerId(null);
		}
	}, [ location.search ]);

	useEffect(() =>
	{
		if (authKey && customerId)
		{
			fetchInfos();
		}

		return () =>
		{
			if (roomStatusListener)
			{
				// stop listening
				try
				{
					roomStatusListener();
				}
				catch
				{
					// do nothing
				}
			}

			roomStatusListener = null;

		};
	}, [ fetchInfos, authKey, customerId ]);

	useEffect(() =>
	{
		if (page === 'history' && connectInfos?.customerId)
		{
			fetchCalls();
		}
	}, [ page, fetchCalls, connectInfos?.customerId ]);

	useEffect(() =>
	{

		if (connectInfos?.duration)
		{
			const consumedValue
				= connectInfos.consumedSeconds ? connectInfos.consumedSeconds : 0;

			const remainingSeconds
				= connectInfos.duration - consumedValue;

			if (remainingSeconds || connectInfos.duration === -1)
			{
				setNoTalkTime(false);
			}
			else
			{
				setNoTalkTime(true);
				localStorage.removeItem('loginTicketCode');
			}
		}
		else
		{
			setNoTalkTime(true);
		}

	}, [ connectInfos.duration, connectInfos.consumedSeconds ]);

	useEffect(() =>
	{
		return () =>
		{
			setPage('landing');
		};
	}, []);

	return (
		<MuiThemeProvider theme={theme}>
			{
				loading ?
					<div className={classes.fullCoveredScreen}>
						<CircularProgress className={classes.loadingCircular}/>
					</div>
					: disabled.value ?
						<div className={classes.fullCoveredScreen}>
							<div className={classes.disabledIndicator}>
								<Typography className={classes.disabledText}>
									{ disabled.reason === 'notFound' ?
										<FormattedMessage
											id='error.urlNotCorrect'
											defaultMessage='URL is not correct'
										/>
										:
										<FormattedMessage
											id='error.unusable'
											defaultMessage='Currently unavailable. Please contact the service operating company.'
										/>
									}
								</Typography>
							</div>
						</div>
						: page === 'landing' ?
							<LandingPage
								setPage={setPage}
							/>
							: page === 'history' ?
								<TranslationHistory
									calls={calls}
									setPage={setPage}
									customerId={connectInfos?.customerId}
									buttons={buttons}
								/>
								: termExpired ? // コードの期限切れ通知。page === 'home'のときのみ表示。
									<div className={classes.fullCoveredScreen}>
										<div className={classes.disabledIndicator}>
											<Typography className={classes.disabledText}>
												<FormattedMessage
													id='error.expiredTicket'
													defaultMessage='Ticket has expired'
												/>
											</Typography>
										</div>

										<Button
											className={classes.backBtn}
											onClick={() => setPage('landing')}
										>
											<FormattedMessage
												id='label.back'
												defaultMessage='Back'
											/>
										</Button>
									</div>
									:
									<>
										<ConfirmDialog
											show={confirmDialogState.show}
											title={confirmDialogState.title}
											message={confirmDialogState.message}
											accept={() => {}}
											cancel={() => setConfirmDialogState({ show: false, title: '', message: '' })}
											closeButtonOnly
										/>
										<div
											className={classes.root}
										>
											<div className={classes.content}>
												<NoTalkTimeDialog
													open={notalkTime}
													connectParamKey={''}
													type={'connect'}
												/>
												<ServiceAppBar
													appBarHeight={appBarHeight}
												/>
												< ConnectButtonsList
													roomAvailable={roomAvailable}
													termsPage={termsPage}
													aspectRatio={aspectRatio}
													btnAreaContent={btnAreaContent}
													btnAreaRows={btnAreaRows}
													buttons={buttons}
													nationalHolidaysList={nationalHolidaysList}
													setConnectPageData={setConnectPageData}
													setConfirmDialogState={setConfirmDialogState}
												/>
												<JoinRoomNoticeDialog
													type={'connect'}
													connectPageData={connectPageData}
												/>
											</div>
										</div>
									</>
			}
		</MuiThemeProvider>
	);
};

ConnectHome.propTypes =
{
	room            : PropTypes.object.isRequired,
	classes         : PropTypes.object.isRequired,
	connectInfos    : PropTypes.object.isRequired,
	setConnectInfos : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		room         : state.room,
		connectInfos : state.me.connectInfos
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setConnectInfos : (connectInfos) =>
		{
			dispatch(meActions.setConnectInfos(connectInfos));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room &&
				prev.me === next.me &&
				prev.me.connectInfos === next.me.connectInfos
			);
		}
	}
)(withStyles(styles)(ConnectHome)));
