// React
import React, { useRef } from 'react';
// Prop types
import PropTypes from 'prop-types';

// Redux
import { withRoomContext } from '../../../../RoomContext';
import { connect } from 'react-redux';
import * as roomActions from '../../../../actions/roomActions';

// Components
import ConnectButton from './ConnectButton';

import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';

// material ui 
import Typography from '@material-ui/core/Typography';

// Daysjs
import dayjs from 'dayjs';

// locale
import { useIntl, FormattedMessage } from 'react-intl';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const breakPointAspectRatio = 1;

const appBarHeight = 40;

const styles = (theme) =>
	({
		container : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			width           : '100%',
			height          : `calc( 100% - ${appBarHeight}px )`,
			backgroundColor : '#EAEAEA'
		},
		infomationArea : {
			width         : '98%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center',
			marginTop     : '25px'
		},
		connectHeader : {
			width          : '95%',
			display        : 'flex',
			justifyContent : 'end',
			paddingRight   : '1%',
			marginBottom   : '4px'
		},
		connectHeaderSP : {
			maxWidth : '650px'
		},
		connectHeaderPC : {
			maxWidth : '800px'
		},
		connectHeaderText : {
			paddingTop : '4px',
			color      : '#292929',
			fontSize   : '0.7rem',
			[theme.breakpoints.up('sm')] :
			{
				paddingTop : '2px',
				fontSize   : '0.8rem'
			}
		},
		connectHeaderBtn : {
			marginLeft      : '12px',
			color           : '#292929',
			fontSize        : '0.8rem',
			backgroundColor : '#FFF',
			padding         : '0 5px',
			lineHeight      : '20px',
			cursor          : 'pointer',
			borderRadius    : '4px',
			boxShadow       : '1px 1px rgba(0, 0, 0, 0.4)',
			marginBottom    : '1px',
			[theme.breakpoints.up('sm')] :
			{
				marginLeft : '16px'
			}
		},
		informationBox : {
			position        : 'relative',
			padding         : '4px 10px',
			backgroundColor : 'rgba(0,0,0,0.55)',
			borderRadius    : '20px',
			width           : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			alignItems      : 'center'
		},
		informationBoxSP : {
			maxWidth : '650px',
			minWidth : '315px',
			width    : '94%'
		},
		informationBoxPC : {
			width    : '98%',
			maxWidth : '800px'
		},
		expiryDateNoLimit : {
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%'
		},
		expiryDateText : {
			color    : '#FFF',
			fontSize : '0.9rem',
			padding  : '3px 10px'
		},
		expiryDateTextNoLimit : {
			margin : '0 5%'
		},
		expiryDateTextWithLimit :
		{
			width        : '90%',
			borderBottom : '1px solid #FFF'
		},
		expiryDateTextFree : {
			textAlign : 'center'
		},
		remainingTime : {
			width        : '90%',
			padding      : '3px 10px',
			borderBottom : '1px solid #FFF'
		},
		remainingTimeLine : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		remainingTimeText : {
			color    : '#FFF',
			fontSize : '1rem'
		},
		remainingTimeTextFree : {
			width     : '100%',
			textAlign : 'center',
			color     : '#F4DA23',
			fontSize  : '1rem'
		},
		explanation : {
			color    : '#FFF',
			fontSize : '0.7rem',
			width    : '90%',
			padding  : '3px 10px'
		},
		buttonSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		buttonBoxTitle : {
			color     : '#292929',
			fontSize  : '1rem',
			width     : '100%',
			textAlign : 'center'
		},
		buttonAreaPC :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			padding         : '20px 0 10px 0',
			marginTop       : '1%',
			width           : '98%',
			maxWidth        : '800px',
			backgroundColor : '#FFF',
			borderRadius    : '10px'
		},
		buttonAreaSP :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			padding         : '20px 0 10px 0',
			marginTop       : '1%',
			width           : '90%',
			maxWidth        : '650px',
			minWidth        : '300px',
			backgroundColor : '#FFF',
			borderRadius    : '10px'
		},
		buttonItemContainer :
		{
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%',
			padding        : '0 1%',
			margin         : '1% 0',
			minWidth       : '280px',
			maxWidth       : '500px'
		}
	});

const showExpiryTime = (timestamp) =>
{
	if (timestamp === 0 || timestamp === '0')
	{
		return '';
	}
	else if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY/MM/DD H:mm');
	}
	else
	{
		return '';
	}
};

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ConnectHome = ({
	classes,
	setJoinRoomNoticeDialogOpen,
	intlObj,
	connectInfos,
	roomAvailable,
	aspectRatio,
	termsPage,
	buttons,
	btnAreaRows,
	btnAreaContent,
	nationalHolidaysList,
	setConnectPageData,
	setConfirmDialogState
}) =>
{
	const intl = useIntl();

	const buttonAreaRef = useRef();

	const handleJoin = (itemIndex) =>
	{
		// なぜかリセットされないときがあるためリセット
		setConnectPageData({});

		if (buttons[itemIndex] && !buttons[itemIndex].message && connectInfos.id)
		{
			const targetBtn = buttons[itemIndex];

			const consumedValue
				= connectInfos.consumedSeconds ? connectInfos.consumedSeconds : 0;

			const remainingSeconds
				= connectInfos.duration - consumedValue;

			if (remainingSeconds || connectInfos.duration === -1)
			{
				setConnectPageData({
					connectionId : connectInfos.id,
					btnKey       : targetBtn.btnKey,
					subdomain    : targetBtn.subdomain,
					locale       : intlObj.locale ? intlObj.locale : '',
					termsPage    : termsPage
				});

				setJoinRoomNoticeDialogOpen(true);
			}
			else
			{
				setConfirmDialogState({
					show  : true,
					title : intl.formatMessage({
						id             : 'service.noTimeTitle',
						defaultMessage : 'No talk time'
					}),
					message : intl.formatMessage({
						id             : 'service.noTimeMessage',
						defaultMessage : 'The remaining time of ticket is not enough'
					})
				});
			}
		}
	};

	const disconnect = () =>
	{
		localStorage.removeItem('serviceConnectionId');
	};

	const calcRemainingMinutes = (addMinutes) =>
	{
		// if (serviceGuest.remainingSeconds)
		// {
		// 	return serviceGuest.remainingSeconds;
		// }
		// else
		if (connectInfos?.duration === -1)
		{
			return '-';
		}
		else if (connectInfos?.duration)
		{
			const consumedValue
				= connectInfos.consumedSeconds ? connectInfos.consumedSeconds : 0;

			const remainingSeconds
				= connectInfos.duration - consumedValue;

			if (!addMinutes)
			{
				addMinutes = 0;
			}

			if (remainingSeconds)
			{
				return (Math.ceil(remainingSeconds/60) + addMinutes).toString();
			}
			else
			{
				return (0 + addMinutes).toString();
			}
		}
		else
		{
			return 0;
		}
	};

	const btnFlagConds = {
		'english' : {
			alt : 'english',
			text :
	<FormattedMessage
		id='service.english'
		defaultMessage='English'
	/>
		},
		'korea' : {
			alt : 'korean',
			text :
	<FormattedMessage
		id='service.korean'
		defaultMessage='한국어（Korean）'
	/>
		},
		'korean' : {
			alt : 'korean',
			text :
	<FormattedMessage
		id='service.korean'
		defaultMessage='한국어（Korean）'
	/>
		},
		'china' : {
			alt : 'chinese',
			text :
	<FormattedMessage
		id='service.chinese'
		defaultMessage='中文（Chinese）'
	/>
		},
		'chinese' : {
			alt : 'chinese',
			text :
	<FormattedMessage
		id='service.chinese'
		defaultMessage='中文（Chinese）'
	/>
		},
		'spanish' : {
			alt : 'spanish',
			text :
	<FormattedMessage
		id='service.spanish'
		defaultMessage='Spanish'
	/>
		},
		'spain' : {
			alt : 'spanish',
			text :
	<FormattedMessage
		id='service.spanish'
		defaultMessage='Spanish'
	/>
		},
		'portuguese' : {
			alt : 'portuguese',
			text :
	<FormattedMessage
		id='service.portuguese'
		defaultMessage='Portuguese'
	/>
		},
		'portugal' : {
			alt : 'portuguese',
			text :
	<FormattedMessage
		id='service.portuguese'
		defaultMessage='Portuguese）'
	/>
		}
	};

	return (
		<MuiThemeProvider theme={theme}>

			{ aspectRatio < breakPointAspectRatio ?
				<>
					<div className={classes.container}>
						<div className={classes.infomationArea}>
							{ connectInfos.connectHeaderHint &&
							<div className={`${classes.connectHeader} ${classes.connectHeaderSP}`}>
								<div
									className={classes.connectHeaderBtn}
									onClick={disconnect}
								>
									<FormattedMessage
										id='service1.disconnect'
										defaultMessage='Disconnect'
									/>
								</div>
							</div>
							}
							<div className={`${classes.informationBox} ${classes.informationBoxSP}`}>
								{ 	connectInfos.duration === -1 ?
									<div className={classes.expiryDateNoLimit}>
										<Typography className={`${classes.expiryDateText} ${classes.expiryDateTextNoLimit}`
										}
										>
											<FormattedMessage
												id='service.period'
												defaultMessage='Expiration'
											/>
										</Typography>
										<Typography className={`${classes.expiryDateText} ${classes.expiryDateTextNoLimit}`
										}
										>
											{showExpiryTime(connectInfos.expiryTimestamp)}
										</Typography>
									</div>
									: (connectInfos.expiryTimestamp
														||connectInfos.expiryTimestamp === 0)
										? <Typography className={`${classes.expiryDateText} ${classes.expiryDateTextWithLimit}`
										} >
											<FormattedMessage
												id='service.period'
												defaultMessage='Expiration'
											/>&nbsp;&nbsp;&nbsp;&nbsp;
											{showExpiryTime(connectInfos.expiryTimestamp)}
										</Typography>
										: null
								}
								{ connectInfos.duration !== -1 &&
								<>
									<Typography className={classes.remainingTime}>
										<div className={classes.remainingTimeLine}>
											<Typography className={classes.remainingTimeText}>
												<FormattedMessage
													id='service1.vaildTime'
													defaultMessage='Currently available'
												/>
											</Typography>
											<Typography className={classes.remainingTimeText}>
												<FormattedMessage
													id='paidservice.time'
													defaultMessage='{minutesPurchased} minutes'
													values={{
														minutesPurchased : calcRemainingMinutes(0)
													}}
												/>
											</Typography>
										</div>
									</Typography>
									<Typography className={classes.explanation}>
										<FormattedMessage
											id='service1.caution'
											defaultMessage='If the available minutes ended 0 during a call, the call will be extended by up to 2 minutes.'
										/>
									</Typography>
								</>
								}
							</div>
						</div>
						<div
							className={classes.buttonSection}
						>
							<div className={classes.buttonAreaSP} ref={buttonAreaRef}>
								<Typography className={classes.buttonBoxTitle}>
									<FormattedMessage
										id='service.selectLaunguage'
										defaultMessage='Select language and start interpreting'
									/>
								</Typography>
								<>
									{ [ ...Array(btnAreaRows.SP) ].map((_r, rowIndex) =>
									{
										return (
											<div className={classes.buttonItemContainer} key={rowIndex}>
												<>
													{[ ...Array(btnAreaContent.SP.columns) ]
														.map((_c, columnIndex) =>
															(
																<ConnectButton
																	key={`${rowIndex}-${columnIndex}`}
																	rowIndex={rowIndex}
																	columnIndex={columnIndex}
																	buttons={buttons}
																	btnAreaContentVal={btnAreaContent.SP}
																	btnFlagConds={btnFlagConds}
																	roomAvailable={roomAvailable}
																	handleJoin={handleJoin}
																	nationalHolidaysList={
																		nationalHolidaysList
																	}
																/>
															)
														)
													}
												</>
											</div>
										);
									}
									)}
								</>

							</div>
						</div>
					</div>

				</>
				:
				<>

					<div className={classes.container}>
						<div className={classes.infomationArea}>
							<div className={`${classes.informationBox} ${classes.informationBoxPC}`}>
								{ 	connectInfos.duration === -1 ?
									<div className={classes.expiryDateNoLimit}>
										<Typography className={classes.expiryDateText
										}
										>
											<FormattedMessage
												id='service.period'
												defaultMessage='Expiration'
											/>
										</Typography>
										<Typography className={classes.expiryDateText
										}
										>
											{showExpiryTime(connectInfos.expiryTimestamp)}
										</Typography>
									</div>
									: (connectInfos.expiryTimestamp
														||connectInfos.expiryTimestamp === 0
									) ?
										<Typography
											className={`${classes.expiryDateText} ${classes.expiryDateTextWithLimit}`
											}
										>
											<FormattedMessage
												id='service.period'
												defaultMessage='Expiration'
											/>&nbsp;&nbsp;&nbsp;&nbsp;
											{showExpiryTime(connectInfos.expiryTimestamp)}
										</Typography>
										: null
								}
								{ connectInfos.duration !== -1 &&
								<>
									<Typography className={classes.remainingTime}>
										<div className={classes.remainingTimeLine}>
											<Typography className={classes.remainingTimeText}>
												<FormattedMessage
													id='service1.vaildTime'
													defaultMessage='Currently available'
												/>
											</Typography>
											<Typography className={classes.remainingTimeText}>
												<FormattedMessage
													id='paidservice.time'
													defaultMessage='{minutesPurchased} minutes'
													values={{
														minutesPurchased : calcRemainingMinutes(0)
													}}
												/>
											</Typography>
										</div>
									</Typography>
									<Typography className={classes.explanation}>
										<FormattedMessage
											id='service1.caution'
											defaultMessage='When remaning time become 0, Max {minutes} minutes extended.'
											values={{
												minutes : 2
											}}
										/>
									</Typography>
								</>
								}
							</div>
						</div>
						<div
							className={classes.buttonSection}
						>
							<div className={classes.buttonAreaPC} ref={buttonAreaRef}>
								<Typography className={classes.buttonBoxTitle}>
									<FormattedMessage
										id='service.selectLaunguage'
										defaultMessage='Select language and start interpreting'
									/>
								</Typography>
								<>
									{ [ ...Array(btnAreaRows.PC) ].map((_r, rowIndex) =>
									{
										return (
											<div className={classes.buttonItemContainer} key={rowIndex}>
												<>
													{[ ...Array(btnAreaContent.PC.columns) ]
														.map((_c, columnIndex) =>
															(
																<ConnectButton
																	key={`${rowIndex}-${columnIndex}`}
																	rowIndex={rowIndex}
																	columnIndex={columnIndex}
																	buttons={buttons}
																	btnAreaContentVal={btnAreaContent.PC}
																	btnFlagConds={btnFlagConds}
																	roomAvailable={roomAvailable}
																	handleJoin={handleJoin}
																	nationalHolidaysList={
																		nationalHolidaysList
																	}
																/>
															)
														)
													}
												</>
											</div>
										);
									}
									)}
								</>
							</div>
						</div>
					</div>

				</>
			}
		</MuiThemeProvider>
	);
};

ConnectHome.propTypes =
{
	classes                     : PropTypes.object.isRequired,
	intlObj                     : PropTypes.object.isRequired,
	setJoinRoomNoticeDialogOpen : PropTypes.func.isRequired,
	connectInfos                : PropTypes.object.isRequired,
	roomAvailable               : PropTypes.array.isRequired,
	termsPage                   : PropTypes.string.isRequired,
	aspectRatio                 : PropTypes.number.isRequired,
	btnAreaContent              : PropTypes.object.isRequired,
	btnAreaRows                 : PropTypes.object.isRequired,
	buttons                     : PropTypes.array.isRequired,
	nationalHolidaysList        : PropTypes.array.isRequired,
	setConnectPageData          : PropTypes.func.isRequired,
	setConfirmDialogState       : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		intlObj      : state.intl,
		connectInfos : state.me.connectInfos
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setJoinRoomNoticeDialogOpen : (joinRoomNoticeDialogOpen) =>
		{
			dispatch(roomActions.setJoinRoomNoticeDialogOpen(joinRoomNoticeDialogOpen));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.intl === next.intl &&
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList &&
				prev.intl.messages === next.intl.messages &&
				prev.me === next.me &&
				prev.me.connectInfos === next.me.connectInfos
			);
		}
	}
)(withStyles(styles)(ConnectHome)));
