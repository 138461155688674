import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Header from '../Header';
import TranslationContent from './component/TranslationContent';

const styles = () => ({
	translationHistoryContainer : {
		width           : '100%',
		height          : '100vh',
		backgroundColor : 'black',
		overflow        : 'hidden'
	}
});
const TranslationHistory = ({ classes, calls, setPage, customerId, buttons }) =>
{
	return (
		<div className={classes.translationHistoryContainer}>
			<Header setPage={setPage} page={'history'} />
			<TranslationContent calls={calls} customerId={customerId} buttons={buttons}/>
		</div>
	);
};

export default withStyles(styles)(TranslationHistory);
