import { useEffect } from 'react';
import { getYearMonthDay, getYearMonth } from '../../utils/formatDate';
import { CALL_STATUS_CATCH, CALL_STATUS_FINISHED, ROOM_ONLINE, ROOM_CALLING } from '../../../../../../const';

const filterCalls = (funcGetDate, dateTime, calls) =>
{
	const filteredCalls = calls.filter((item) =>
	{
		const date = funcGetDate(item.timestamp);

		return funcGetDate(dateTime) === date;
	});

	return filteredCalls;
};

const filterRoomSnapshots = (funcGetDate, dateTime, roomsSnapshots) =>
{
	const filteredSnapshots = roomsSnapshots.filter((item) =>
	{

		const date = funcGetDate(item.timestamp);

		return funcGetDate(dateTime) === date;
	});

	return filteredSnapshots;
};

// filter data operator by Year month
export function useYearMonthEffect(
	calls,
	hostRecievedCalls,
	dateYearMonth,
	setDataOverallYearMonth,
	roomsSnapshots,
	host
)
{
	useEffect(() =>
	{
		const filteredCalls = filterCalls(getYearMonth, dateYearMonth, calls);

		const filteredHostRecievedCalls
			= filterCalls(getYearMonth, dateYearMonth, hostRecievedCalls);

		const filteredRoomSnapshots
			= filterRoomSnapshots(getYearMonth, dateYearMonth, roomsSnapshots);

		// number call coming
		const numberOfIncomingCalls = filteredCalls.reduce((accumulator, item) =>
		{
			if (item.calls)
			{
				for (const c of item.calls)
				{
					if (c.hostId === host.id)
					{
						accumulator++;

						break;
					}
				}
			}

			return accumulator;
		}, 0);

		// number call received
		const numberOfCallsReceived = filteredHostRecievedCalls.reduce((accumulator, item) =>
		{

			if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
			{
				accumulator++;
			}

			return accumulator;
		}, 0);

		// total talk time
		const totalTalkSeconds = filteredHostRecievedCalls.reduce((accumulator, item) =>
		{
			accumulator += item.duration;

			return accumulator;
		}, 0);

		const totalTalkTime = Math.ceil((totalTalkSeconds/60)*10)/10;

		// average talk time
		const averageTalkSeconds = numberOfCallsReceived > 0
			? Math.ceil((totalTalkSeconds / numberOfCallsReceived) * 10) / 10 : 0;

		const averageTalkTime = Math.ceil((averageTalkSeconds/60)*10)/10;

		// call received rate
		let callReceivedRate = 0;

		if (numberOfIncomingCalls > 0)
		{
			const resultRate = (numberOfCallsReceived / numberOfIncomingCalls) * 100;
			const roundedResultRate = resultRate.toFixed(2);

			if (roundedResultRate.indexOf('.') !== -1 && parseFloat(roundedResultRate) % 1 === 0)
			{
				callReceivedRate = parseInt(roundedResultRate, 10);
			}
			else
			{
				callReceivedRate = parseFloat(roundedResultRate);
			}
		}

		const waitingRoomSnapshots = filteredRoomSnapshots.filter((item) =>
		{
			let activeOperatorRoomFound = false;

			if (item.rooms)
			{
				for (const rm of item.rooms)
				{
					if ((rm.status === ROOM_ONLINE || rm.status === ROOM_CALLING)
						&& rm.hostId === host.id)
					{
						activeOperatorRoomFound = true;
						break;
					}
				}
			}

			return activeOperatorRoomFound;
		});

		const timeWaiting = waitingRoomSnapshots.reduce((total, item) =>
		{

			total += item.intervalMinute ? item.intervalMinute : 5;

			return total;

		}, 0);

		// total operating time
		const upTime = Math.ceil((totalTalkTime + timeWaiting) * 10) / 10;

		setDataOverallYearMonth((prev) => ({
			...prev,
			numberOfIncomingCalls,
			numberOfCallsReceived,
			callReceivedRate,
			totalTalkTime,
			averageTalkTime,
			upTime
		}));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calls,
		hostRecievedCalls,
		dateYearMonth,
		setDataOverallYearMonth,
		roomsSnapshots
	]);
}

// filter data operator by day month
export function useMonthDayEffect(
	calls,
	hostRecievedCalls,
	dateMonthDay,
	setDataOverallMonthDay,
	roomsSnapshots,
	host
)
{
	useEffect(() =>
	{

		const filteredCalls = filterCalls(getYearMonthDay, dateMonthDay, calls);

		const filteredHostRecievedCalls
			= filterCalls(getYearMonthDay, dateMonthDay, hostRecievedCalls);

		const filteredRoomSnapshots
			= filterRoomSnapshots(getYearMonthDay, dateMonthDay, roomsSnapshots);

		// number call coming
		const numberOfIncomingCalls = filteredCalls.reduce((accumulator, item) =>
		{

			// accumulator = accumulator + item.calls.length;

			let calledTimes = 0;

			if (item.calls)
			{
				item.calls.forEach((c) =>
				{
					if (c.hostId === host.id) calledTimes++;
				});
			}

			accumulator = accumulator + calledTimes;

			return accumulator;
		}, 0);

		// number call received
		const numberOfCallsReceived = filteredHostRecievedCalls.reduce((accumulator, item) =>
		{

			if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
			{
				accumulator++;
			}

			return accumulator;
		}, 0);

		// call received date
		let callReceivedRate = 0;

		if (numberOfIncomingCalls > 0)
		{
			const resultRate = (numberOfCallsReceived / numberOfIncomingCalls) * 100;
			const roundedResultRate = resultRate.toFixed(2);

			if (roundedResultRate.indexOf('.') !== -1 && parseFloat(roundedResultRate) % 1 === 0)
			{
				callReceivedRate = parseInt(roundedResultRate, 10);
			}
			else
			{
				callReceivedRate = parseFloat(roundedResultRate);
			}
		}

		// total talk time
		const totalTalkSeconds = filteredHostRecievedCalls.reduce((accumulator, item) =>
		{

			accumulator = accumulator + item.duration;

			return accumulator;
		}, 0);

		const totalTalkTime = Math.ceil((totalTalkSeconds/60)*10)/10;

		// average talk time
		const averageTalkSeconds = numberOfCallsReceived > 0
			? Math.ceil((totalTalkSeconds / numberOfCallsReceived) * 10) / 10 : 0;

		const averageTalkTime = Math.ceil((averageTalkSeconds/60)*10)/10;

		const waitingRoomSnapshots = filteredRoomSnapshots.filter((item) =>
		{
			let activeOperatorRoomFound = false;

			if (item.rooms)
			{
				for (const rm of item.rooms)
				{
					if ((rm.status === ROOM_ONLINE || rm.status === ROOM_CALLING)
						&& rm.hostId === host.id)
					{
						activeOperatorRoomFound = true;
						break;
					}
				}
			}

			return activeOperatorRoomFound;
		});

		const timeWaiting = waitingRoomSnapshots.reduce((total, item) =>
		{

			total += item.intervalMinute ? item.intervalMinute : 5;

			return total;

		}, 0);

		// total operating time
		const upTime = Math.ceil((totalTalkTime + timeWaiting) * 10) / 10;

		setDataOverallMonthDay((prev) => ({
			...prev,
			numberOfIncomingCalls,
			numberOfCallsReceived,
			callReceivedRate,
			totalTalkTime,
			averageTalkTime,
			upTime
		}));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calls,
		hostRecievedCalls,
		dateMonthDay,
		setDataOverallMonthDay,
		roomsSnapshots
	]);
}
