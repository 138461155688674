// React
import React, { useState, useEffect, useCallback } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Mui icon
import ImageIcon from '@material-ui/icons/Image';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

// firebase
import { functions, storage } from '../../../lib/firebase';

// Components
import Dropzone from 'react-dropzone';
import EditRoleDialog from './EditRoleDialog';
import EditSkillGroupDialog from './EditSkillGroupDialog';
import WorkTimeSetting from './WorkTimeSetting';
import MemoCategoryDialog from './MemoCategoryDialog';

// Constants
import {
	PLANS,
	ROOM_ONLINE,
	ROOM_AFK
} from '../../../const';

// util
import { getDomainWithoutSubdomain } from '../../../utils';

const styles = () =>
	({
		root : {
			width           : '100%',
			height          : 'calc(100% - 3rem)',
			minWidth        : '1100px',
			position        : 'relative',
			display         : 'flex',
			flexDirection   : 'column',
			borderRadius    : '20px',
			backgroundColor : '#e5e5e2'
		},
		wrapper : {
			width           : '100%',
			backgroundColor : 'white',
			boxShadow       : '3px 3px 10px #aaa',
			padding         : '0.5% 1%'
		},
		group : {
			marginBottom : '2%'
		},
		pageTitle : {
			color      : 'var(--text-color)',
			fontSize   : '1.2rem',
			lineHeight : '1.4rem'
		},
		top : {
			display       : 'flex',
			flexDirection : 'row'
		},
		topLeft : {
			width : '50%'
		},
		topRight : {
			width : '50%'
		},
		topData : {
			height     : '2rem',
			display    : 'flex',
			margin     : '4% 0',
			alignItems : 'center',
			lineHeight : 1
		},
		topDataMulti :
		{
			margin : '4% 0'
		},
		dualItemRow :
		{
			justifyContent : 'space-between'
		},
		dualItem :
		{
			width          : '50%',
			display        : 'flex',
			justifyContent : 'end'
		},
		topDataTitle : {
			height     : '2rem',
			display    : 'flex',
			margin     : '0',
			alignItems : 'center',
			lineHeight : 1
		},
		inputLabel : {
			width          : '20%',
			fontSize       : '0.8rem',
			whiteSpace     : 'nowrap',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'flex-end',
			justifyContent : 'center'
		},
		inputLabelLeft : {
			fontSize       : '0.8rem',
			whiteSpace     : 'nowrap',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'flex-start',
			justifyContent : 'center'
		},
		inputLabelStart : {
			paddingLeft   : '3%',
			width         : '20%',
			fontSize      : '0.8rem',
			// whiteSpace    : 'nowrap',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		label : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		labelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabel : {
			color        : 'var(--text-color)',
			fontSize     : '0.7rem',
			lineHeight   : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.6rem',
			lineHeight   : '0.7rem',
			overflowWrap : 'break-word'
		},
		subLabelExSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.5rem',
			lineHeight   : '0.6rem',
			overflowWrap : 'break-word'
		},
		inputValue : {
			width    : '80%',
			fontSize : '0.8rem',
			padding  : '0 3%'

		},
		inputValueFlex : {
			width          : '80%',
			fontSize       : '0.8rem',
			padding        : '0 3%',
			display        : 'flex',
			justifyContent : 'start'
		},
		fullInputLine : {
			width          : '100%',
			fontSize       : '0.8rem',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '20%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#F6F6F6'
			}
		},
		fullWidth : {
			width : '100%'
		},
		inputSwitch : {
			marginLeft : '5%'
		},
		emailField : {
			width : 'calc( 100% - 75px )'
		},
		editEmailBtn : {
			marginLeft : '5px',
			padding    : '2px 4px',
			fontSize   : '0.9rem'
		},
		inputList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '12rem'
		},
		inputListSmall : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 0.3rem',
			color        : 'var(--text-color)',
			minWidth     : '4rem'
		},
		checkBox : {
			color                   : 'var(--text-color)',
			fontSize                : '0.8rem',
			'& .MuiTypography-root' : {
				fontSize   : '0.8rem',
				fontWeight : 300
			}
		},
		guestInputColSelect : {
			display     : 'block',
			marginRight : '1rem'
		},
		guestInputColInput : {
			display : 'block',
			width   : '100%'
		},
		imageGroup : {
			width   : '100%',
			display : 'flex'
		},
		imageLeft : {
			width       : '20%',
			marginRight : '2%'
		},
		imageRight : {
			width : '78%'
		},
		imageNameGroup : {
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		imageIcon : {
			color       : 'var(--text-color)',
			marginRight : '0.5rem'
		},
		imageBorder : {
			borderBottom : '1px solid var(--text-color)',
			padding      : '0 0.5rem'
		},
		imageName : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			whiteSpace : 'nowrap'
		},
		imageBox : {
			width          : '100%',
			height         : '10rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			padding        : '5% 3%',
			'&> img'       : {
				objectFit : 'contain'
			}
		},
		image : {
			width     : 'auto',
			height    : 'auto',
			display   : 'block',
			maxWidth  : '100%',
			maxHeight : '100%'
		},
		screenSetting : {
			display       : 'flex',
			flexDirection : 'row'
		},
		uptimeSetting : {
			width          : '40%',
			display        : 'flex',
			justifyContent : 'center',
			marginTop      : '1%'
		},
		buttonSetting : {
			width                : '15%',
			display              : 'flex',
			flexDirection        : 'column',
			alignItems           : 'center',
			marginTop            : '0%',
			'& > div:last-child' : {
				padding : '8% 0'
			}
		},
		buttonSettingList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '3rem'
		},
		screen : {
			width : '27%'
		},
		actionGroup : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			paddingBottom  : '1rem'
		},
		inputBtn : {
			marginLeft      : '4%',
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.3rem',
			padding         : '0.1rem 2rem',
			fontSize        : '0.8rem',
			color           : 'var(--text-color)'
		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		},
		buttonColorInputArea :
		{
			width : '25%'
		},
		buttonTextInputArea :
		{
			width : '30%'
		},
		gradientInputArea :
		{
			width : '42%'
		},
		settingLineStart : {
			display        : 'flex',
			justifyContent : 'start'
		},
		waitingScreenSettingLine :
		{
			marginTop : '10px',
			display   : 'flex'
		},
		buttonPosTitle :
		{
			marginTop : '5px'
		},
		buttonPosInput :
		{
			marginRight : '5px'
		},
		inputSpaceLeft :
		{
			marginLeft : '30px'
		},
		inputSpaceRight :
		{
			marginRight : '30px'
		},
		inputSpaceRightS :
		{
			marginRight : '15px'
		},
		inputFieldBtnPos :
		{
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '80px',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#EEE'
			}
		},
		inputHalf : {
			marginTop      : '10px',
			width          : '50%',
			display        : 'flex',
			justifyContent : 'space-between',
			padding        : '0 3%'
		},
		inputHalfLabel : {
			width : '45%'
		},
		inputHalfValue : {
			marginTop      : '5px',
			width          : '55%',
			display        : 'flex',
			justifyContent : 'end'
		},
		inputHalfField : {
			width  : '75%',
			height : '30px'
		},
		inputHalfUnit : {
			fontSize   : '1rem',
			marginTop  : '5px',
			marginLeft : '4px'
		},
		customRoleLine : {
			marginTop      : '10px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		inputItemCustomRole : {
			width          : '50%',
			fontSize       : '0.8rem',
			padding        : '0 3%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		inputLabelCustomRole :
		{
			width      : '75%',
			fontSize   : '0.8rem',
			// whiteSpace    : 'nowrap',
			display    : 'flex',
			alignItems : 'flex-start'
		},
		labelCustomRole : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word',
			padding      : '0 0 0 10px'
		},
		disabledRole : {
			fontSize : '1.4rem',
			color    : '#CCC',
			cursor   : 'pointer'
		},
		activatedRole : {
			fontSize : '1.4rem',
			color    : 'var(--submit-button-color)',
			cursor   : 'pointer'
		},
		roleEditButton : {
			color           : 'white',
			maxHeight       : '25px',
			padding         : '3px 5px',
			backgroundColor : 'var(--submit-button-color)',
			'&:hover'       : {
				backgroundColor : 'var(--hover-button-color)'
			}
		},
		memoCatBtn : {
			color           : 'white',
			maxHeight       : '25px',
			padding         : '3px 5px',
			backgroundColor : 'var(--submit-button-color)',
			'&:hover'       : {
				backgroundColor : 'var(--hover-button-color)'
			}
		},
		lobbyTableContainer :
		{
			margin : '5px 0 20px 0'
		},
		lobbyButton :
		{
			color           : 'white',
			backgroundColor : 'var(--submit-button-color)',
			'&:hover'       : {
				backgroundColor : 'var(--hover-button-color)'
			}
		},
		tableBtnRight :
		{
			marginLeft : '10px'
		},
		createLobbyBtnWrapper :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end'
		},
		lobbyLink :
		{
			cursor : 'pointer'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiSelect : {
			select : {
				'&:focus' : {
					borderRadius : '0.4rem'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const AdminUserEditView = ({
	classes,
	saveCb,
	closeCb,
	deleteCb,
	user,
	admin,
	saveSkillGroups,
	errors,
	setErrors,
	setLoading,
	setRoomsNum
}) =>
{

	const domain = getDomainWithoutSubdomain();

	const [ userNumber, setUserNumber ] = useState('');
	const [ userName, setUserName ] = useState('');
	const [ userEmail, setUserEmail ] = useState('');
	const [ userAdminPassword, setUserAdminPassword ] = useState('');
	const [ userServiceName, setUserServiceName ] = useState('');
	const [ userBrowserTitle, setUserBrowserTitle ] = useState();
	const [ userSubDomain, setUserSubDomain ] = useState('');
	const [ userRoomNum, setUserRoomNum ] = useState(0);
	const [ userCallableHostsLimit, setUserCallableHostsLimit ] = useState(10);
	const [ userTodoCheck, setUserTodoCheck ] = useState(false);
	const [ userCallCheck, setUserCallCheck ] = useState(false);
	const [ storageEnabled, setStorageEnabled ] = useState(false);
	const [ dataExpired, setDataExpired ] = useState(30);
	const [ roomStateEnter, setRoomStateEnter ] = useState(ROOM_ONLINE);
	const [ roomStateCallEnd, setRoomStateCallEnd ] = useState(ROOM_ONLINE);
	const [ queueEnabled, setQueueEnabled ] = useState(false);
	const [ userDirectModeCheck, setUserDirectModeCheck ] = useState(false);
	const [ userSendChatLog, setUserSendChatLog ] = useState(true);
	const [ userGuestHomeUrl, setUserGuestHomeUrl ] = useState('');
	const [ userPlan, setUserPlan ] = useState(0);
	const [ userRole, setUserRole ] = useState('common');
	const [ userBtnPositionObj, setUserBtnPositionObj ] = useState({
		PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
		SP : { top: '49', bottom: '', verticCenter: false }
	});

	// Logo File
	const [ uploadLogoFile, setUploadLogoFile ] = useState();
	const [ userLogoUrl, setUserLogoUrl ] = useState('');

	// BG File for PC
	const [ uploadBgPCFile, setUploadBgPCFile ] = useState();
	const [ userBgPCUrl, setUserBgPCUrl ] = useState('');

	// BG File for SP
	const [ uploadBgSPFile, setUploadBgSPFile ] = useState();
	const [ userBgSPUrl, setUserBgSPUrl ] = useState('');

	// OPEN/CLOSE TIME
	const [ userOpenTime, setUserOpenTime ] = useState('10:00');
	const [ userCloseTime, setUserCloseTime ] = useState('18:00');

	// Holiday
	const [ userHoliday, setUserHoliday ] = useState([ 0, 0, 0, 0, 0, 0, 0, 0 ]);

	const [ userNationalHoliday, setUserNationalHoliday ] = useState(0);

	// GuestInput
	const [ guestInputField1, setGuestInputField1 ] = useState(
		{
			type : 'other',
			key  : ''
		});
	const [ guestInputField2, setGuestInputField2 ] = useState(
		{
			type : 'other',
			key  : ''
		});

	// Tell
	const [ userTell, setUserTell ] = useState('');

	// accept only calls from portal
	const [ portalCallOnly, setPortalCallOnly ] = useState(false);

	// enable  personal  call
	const [ personalCallEnabled, setPersonalCallEnabled ] = useState(false);

	// enable  ROOM_APPOINTMENT status
	const [ appointmentStatusEnabled, setAppointmentStatusEnabled ] = useState(false);

	// additional call functions 
	const [ additionalCallFuncs, setAdditionalCallFuncs ]
		= useState({
			meeting            : false,
			internalSupport    : false,
			requestSupportCall : false,
			acceptSupportCall  : false
		});

	const [ socketServerUrl, setSocketServerUrl ] = useState('');

	const [ memoCategories, setMemoCategories ] = useState([]);

	const [ averageCallDuration, setAverageCallDuration ] = useState(300);

	// button
	const [ buttonColor, setButtonColor ] = useState('F15A24');
	const [ buttonText, setButtonText ] = useState('オンライン相談受付');
	const [ buttonPadding, setButtonPadding ] = useState('0.8vh 2vw');

	// background
	const [ bgPCGradient, setBgPCGradient ] = useState('#EEEEEE, #EEEEEE');
	const [ bgSPGradient, setBgSPGradient ] = useState('#EEEEEE, #EEEEEE');

	// buttonSizeSetting
	const [ btnPosDirectionSelector, setBtnPosDirectionSelector ] = useState({ verticalPC: 'top', horizontalPC: 'left', verticalSP: 'top' });

	// calling roles
	const [ customRoles, setCustomRoles ] = useState({
		sv1       : { name: 'SV1', active: true, operatorLogin: true, joinRoom: true, supervise: true, monitoring: true, expel: true, setPriority: true, watchPersonalInfo: true, showRecordedData: true, downloadRecordedData: true },
		sv2       : { name: 'SV2', active: false, operatorLogin: true, joinRoom: true, supervise: true, monitoring: true, expel: true, setPriority: true, watchPersonalInfo: true, showRecordedData: true, downloadRecordedData: true },
		operator1 : { name: 'オペレータ1', active: true, operatorLogin: true, joinRoom: true, supervise: false, monitoring: false, expel: false, setPriority: false, watchPersonalInfo: true, showRecordedData: false, downloadRecordedData: false },
		operator2 : { name: 'オペレータ2', active: false, operatorLogin: true, joinRoom: true, supervise: false, monitoring: false, expel: false, setPriority: false, watchPersonalInfo: true, showRecordedData: false, downloadRecordedData: false }
	});

	// dialog
	const [ roleEditDialogState, setRoleEditDialogState ] = useState(
		{
			roleCode   : '',
			roleNumber : 1,
			show       : false
		}
	);

	// additional lobby pages 
	const [ skillGroups, setSkillGroups ] = useState([]);

	const showRoleEditDialog = (roleCode, roleNumber) =>
	{
		setRoleEditDialogState({
			roleCode   : roleCode,
			roleNumber : roleNumber,
			show       : true
		});
	};

	const [ editEmail, setEditEmail ] = useState(false);

	const [ editSkillGroupDialog, setEditSkillGroupDialog ] = useState(
		{
			show       : false,
			skillGroup : {},
			newItem    : false
		});

	const [ memoCategoryDialog, setMemoCategoryDialog ] = useState(
		{
			show : false
		});

	const showEditSkillGroupDialog = (skillId, newItem) =>
	{
		// 新規登録が完了するまでは登録させない
		if (user)
		{
			let targetGroupIndex = -1;

			if (!newItem)
			{
				targetGroupIndex = skillGroups.findIndex((lobby) => lobby.skillId === skillId);
			}

			if (newItem || targetGroupIndex !== -1)
			{
				setEditSkillGroupDialog({
					show       : true,
					skillGroup : newItem ? {} : skillGroups[targetGroupIndex],
					skillId    : skillId,
					newItem    : newItem
				});
			}
		}
	};

	useEffect(() =>
	{
		if (user)
		{
			setUserNumber(user.number ? user.number : -1);
			setUserName(user.name ? user.name : '');
			setUserEmail(user.email ? user.email : '');
			setUserAdminPassword('非表示');
			setUserCallableHostsLimit(
				(user.callableHostsLimit || user.callableHostsLimit === 0)
					? user.callableHostsLimit : 10);
			setUserRoomNum(user.roomNum ? user.roomNum : 0);
			setRoomsNum(user.roomNum ? user.roomNum : 0);
			setUserServiceName(user.serviceName ? user.serviceName : '');
			setUserBrowserTitle(user.browserTitle ? user.browserTitle : '');
			setUserSubDomain(user.subdomain ? user.subdomain : '');
			setUserPlan(user.plan ? user.plan : 0);
			setUserRole(user.role ? user.role : 'common');
			setUserTodoCheck(user.optionTodo ? user.optionTodo : false);
			setUserCallCheck(user.optionCall ? user.optionCall : false);
			setQueueEnabled(user.queueEnabled ? user.queueEnabled : false);
			setUserDirectModeCheck(user.directMode ? user.directMode : false);
			setUserSendChatLog(user.sendChatLog ? user.sendChatLog : false);
			setUserGuestHomeUrl(user.guestHomeUrl ? user.guestHomeUrl : '');
			setUserLogoUrl(user.logoUrl ? user.logoUrl : '');
			setUserBgPCUrl(user.bgPCUrl ? user.bgPCUrl : '');
			setUserBgSPUrl(user.bgSPUrl ? user.bgSPUrl : '');
			setUserOpenTime(user.openTime ? user.openTime : '10:00');
			setUserCloseTime(user.closeTime ? user.closeTime : '18:00');
			setUserHoliday(user.holiday ? user.holiday : [ 0, 0, 0, 0, 0, 0, 0, 0 ]);
			setUserNationalHoliday(user.nationalHoliday ? user.nationalHoliday : 0);
			setUserTell(user.tell ? user.tell : '');
			setPersonalCallEnabled(user.personalCallEnabled ? true : false);
			setAppointmentStatusEnabled(user.appointmentStatusEnabled ? true : false);
			setPortalCallOnly(user.portalCallOnly ? true:false);
			setButtonColor(user.buttonColor ? user.buttonColor : 'F15A24');
			setButtonText(user.buttonText ? user.buttonText : 'オンライン相談受付');
			setButtonPadding(user.buttonPadding ? user.buttonPadding : '0.8vh 2vw');
			setBgPCGradient(user.bgPCGradient ? user.bgPCGradient : '#EEEEEE, #EEEEEE');
			setBgSPGradient(user.bgSPGradient ? user.bgSPGradient : '#EEEEEE, #EEEEEE');

			setGuestInputField1(user.guestInputField1 ? user.guestInputField1 : { type: 'other', key: '' });
			setGuestInputField2(user.guestInputField2 ? user.guestInputField2 : { type: 'other', key: '' });

			setAdditionalCallFuncs(
				user.additionalCallFuncs ? user.additionalCallFuncs
					: {
						meeting            : false,
						internalSupport    : false,
						requestSupportCall : false,
						acceptSupportCall  : false
					}
			);

			setSocketServerUrl(user.socketServerUrl ? user.socketServerUrl : '');

			setMemoCategories(user.memoCategories ? user.memoCategories : []);

			setAverageCallDuration(user.averageCallDuration || 300);

			setCustomRoles(user.customRoles ? user.customRoles : {
				sv1       : { name: 'SV1', active: true, operatorLogin: true, joinRoom: true, supervise: true, monitoring: true, expel: true, setPriority: true, watchPersonalInfo: true, showRecordedData: true, downloadRecordedData: true },
				sv2       : { name: 'SV2', active: false, operatorLogin: true, joinRoom: true, supervise: true, monitoring: true, expel: true, setPriority: true, watchPersonalInfo: true, showRecordedData: true, downloadRecordedData: true },
				operator1 : { name: 'オペレータ1', active: true, operatorLogin: true, joinRoom: true, supervise: false, monitoring: false, expel: false, setPriority: false, watchPersonalInfo: true, showRecordedData: false, downloadRecordedData: false },
				operator2 : { name: 'オペレータ2', active: false, operatorLogin: true, joinRoom: true, supervise: false, monitoring: false, expel: false, setPriority: false, watchPersonalInfo: true, showRecordedData: false, downloadRecordedData: false }
			});
			setDataExpired(user.dataExpired ? user.dataExpired : 30);
			setStorageEnabled(user.storageEnabled ? user.storageEnabled : false);
			setRoomStateEnter(user.roomStateEnter ? user.roomStateEnter : ROOM_ONLINE);
			setRoomStateCallEnd(user.roomStateCallEnd ? user.roomStateCallEnd : ROOM_ONLINE);
			setUserBtnPositionObj(
				user.btnPosition && user.btnPosition.PC && user.btnPosition.SP
					? user.btnPosition
					: {
						PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
						SP : { top: '49', bottom: '', verticCenter: false }
					});
			setSkillGroups(user.skillGroups ? user.skillGroups : []);

			// set initial value of button position direction selector, from db data
			setBtnPosDirectionSelector(() =>
			{
				let verticalPCVal = 'top';

				let horizontalPCVal = 'left';

				let verticalSPVal = 'top';

				if (user.btnPosition && user.btnPosition.PC && user.btnPosition.SP)
				{
					if (user.btnPosition.PC.bottom)
					{
						verticalPCVal = 'bottom';
					}
					else if (user.btnPosition.PC.verticCenter)
					{
						verticalPCVal = 'center';
					}

					if (user.btnPosition.PC.right)
					{
						horizontalPCVal = 'right';
					}
					else if (user.btnPosition.PC.horizCenter)
					{
						horizontalPCVal = 'center';
					}

					if (user.btnPosition.SP.bottom)
					{
						verticalSPVal = 'bottom';
					}
					else if (user.btnPosition.SP.verticCenter)
					{
						verticalSPVal = 'center';
					}
				}

				return {
					verticalPC   : verticalPCVal,
					horizontalPC : horizontalPCVal,
					verticalSP   : verticalSPVal
				};
			});
		}
		else
		{
			setUserNumber('');
			setUserName('');
			setUserEmail('');
			setUserAdminPassword('');
			setUserCallableHostsLimit(10);
			setUserRoomNum(0);
			setUserServiceName('');
			setUserBrowserTitle('');
			setUserSubDomain('');
			setUserPlan(0);
			setUserRole('common');
			setUserTodoCheck(false);
			setUserCallCheck(false);
			setUserDirectModeCheck(false);
			setUserSendChatLog(true);
			setUserGuestHomeUrl('');
			setUserLogoUrl('');
			setUserBgPCUrl('');
			setUserBgSPUrl('');
			setUserOpenTime('10:00');
			setUserCloseTime('18:00');
			setUserHoliday([ 0, 0, 0, 0, 0, 0, 0, 0 ]);
			setUserNationalHoliday(0);
			setGuestInputField1({ type: 'other', key: '' });
			setGuestInputField2({ type: 'other', key: '' });
			setAdditionalCallFuncs({
				meeting            : false,
				internalSupport    : false,
				requestSupportCall : false,
				acceptSupportCall  : false
			});
			setSocketServerUrl('');
			setMemoCategories([]);
			setAverageCallDuration(300);
			setCustomRoles({
				sv1       : { name: 'SV1', active: true, operatorLogin: true, joinRoom: true, supervise: true, monitoring: true, expel: true, setPriority: true, watchPersonalInfo: true, showRecordedData: true, downloadRecordedData: true },
				sv2       : { name: 'SV2', active: false, operatorLogin: true, joinRoom: true, supervise: true, monitoring: true, expel: true, setPriority: true, watchPersonalInfo: true, showRecordedData: true, downloadRecordedData: true },
				operator1 : { name: 'オペレータ1', active: true, operatorLogin: true, joinRoom: true, supervise: false, monitoring: false, expel: false, setPriority: false, watchPersonalInfo: true, showRecordedData: false, downloadRecordedData: false },
				operator2 : { name: 'オペレータ2', active: false, operatorLogin: true, joinRoom: true, supervise: false, monitoring: false, expel: false, setPriority: false, watchPersonalInfo: true, showRecordedData: false, downloadRecordedData: false }
			});
			setDataExpired(30);
			setStorageEnabled(false);
			setRoomStateEnter(ROOM_ONLINE);
			setRoomStateCallEnd(ROOM_ONLINE);
			setUserTell('');
			setPersonalCallEnabled(false);
			setAppointmentStatusEnabled(false);
			setAdditionalCallFuncs(false);
			setButtonColor('F15A24');
			setButtonText('オンライン相談受付');
			setButtonPadding('0.8vh 2vw');
			setBgPCGradient('#EEEEEE, #EEEEEE');
			setBgSPGradient('#EEEEEE, #EEEEEE');
			setUserBtnPositionObj({
				PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
				SP : { top: '49', bottom: '', verticCenter: false }
			});
			setSkillGroups([]);
			setBtnPosDirectionSelector({ verticalPC: 'top', horizontalPC: 'left', verticalSP: 'top' });
		}
	// eslint-disable-next-line
	}, [ user ]);

	const handleChangeUserNumber = (e) =>
	{
		setUserNumber(e.target.value);
	};

	const handleChangeUserName = (e) =>
	{
		setUserName(e.target.value);
	};

	const handleChangeUserEmail = (e) =>
	{
		setUserEmail(e.target.value);
	};

	const handleChangeUserTell = (e) =>
	{
		setUserTell(e.target.value);
	};

	const handleChangePersonalCallEnabled = (e) =>
	{
		setPersonalCallEnabled(e.target.checked);
	};

	const handleChangeAppointmentStatusEnabled = (e) =>
	{
		setAppointmentStatusEnabled(e.target.checked);
	};

	const handleChangePortalCallOnly = (e) =>
	{
		setPortalCallOnly(e.target.checked);
	};

	const handleChangeUserAdminPassword = (e) =>
	{
		setUserAdminPassword(e.target.value);
	};

	const handleChangeUserCallableHostsLimit = (e) =>
	{
		setUserCallableHostsLimit(Number(e.target.value));
	};

	const handleChangeUserRoomNum = (e) =>
	{
		setUserRoomNum(Number(e.target.value));
	};

	const handleChangeUserPlan = (e) =>
	{
		setUserPlan(e.target.value);
	};

	const handleChangeUserServiceName = (e) =>
	{
		setUserServiceName(e.target.value);
	};

	const handleChangeUserBrowserTitle = (e) =>
	{
		setUserBrowserTitle(e.target.value);
	};

	const handleChangeUserSubDomain = (e) =>
	{
		setUserSubDomain(e.target.value);
	};

	const handleChangeRole = (e) =>
	{
		setUserRole(e.target.value);
	};

	const toggleCustomRoleActive = (roleName) =>
	{
		setCustomRoles((state) =>
		{
			const newState = { ...state };

			newState[roleName]['active'] = !newState[roleName]['active'];

			return { ...newState };

		});
	};

	const handleChangeUserTodoCheck = (e) =>
	{
		setUserTodoCheck(e.target.checked);
	};

	const handleChangeUserCallCheck = (e) =>
	{
		setUserCallCheck(e.target.checked);
	};

	const handleChangeUserStorageCheck = (e) =>
	{
		setStorageEnabled(e.target.checked);
	};

	const handleChangeUserDataExpiredSelect = (e) =>
	{
		setDataExpired(e.target.value);
	};

	const handleChangeQueueEnabled = (e) =>
	{
		setQueueEnabled(e.target.checked);
	};

	const handleChangeUserDirectModeCheck = (e) =>
	{
		setUserDirectModeCheck(e.target.checked);
	};

	const handleChangeUserSendChatLog = (e) =>
	{
		setUserSendChatLog(e.target.checked);
	};

	const handleChangeUserGuestHomeUrl = (e) =>
	{
		setUserGuestHomeUrl(e.target.value);
	};

	const handleChangeUserRoomStateEnter = (e) =>
	{
		setRoomStateEnter(Number(e.target.value));
	};
	const handleChangeUserRoomStateCallEnd = (e) =>
	{
		setRoomStateCallEnd(Number(e.target.value));
	};

	const handleChangeHolidayCallback = (day) =>
	{

		if (userHoliday[day] === 1)
		{
			userHoliday[day] = 0;
		}
		else
		{
			userHoliday[day] = 1;
		}
		setUserHoliday([ ...userHoliday ]);
	};

	const handleChangeNationalHolidayCallback = (e) =>
	{
		setUserNationalHoliday(Number(e.target.value));
	};

	const handleChangeGuestInputField = (e, num) =>
	{

		if (num === 1)
		{
			setGuestInputField1({
				type : 'other',
				key  : e.target.value
			});
		}
		else if (num === 2)
		{
			setGuestInputField2({
				type : 'other',
				key  : e.target.value
			});
		}
	};

	const handleChangeAdditionalCallFunctions = (key, e) =>
	{
		setAdditionalCallFuncs((state) =>
		{
			const stateObj = { ...state };

			let value = false;

			if (e.target.value)
			{
				value = true;
			}

			stateObj[key] = value;

			return { ...stateObj };

		});

	};

	const handleChangeSocketServerUrl = (e) =>
	{
		setSocketServerUrl(e.target.value);
	};

	const handleAverageTalkDuration = (e) =>
	{
		setAverageCallDuration(Number(e.target.value));
	};

	const handleChangeUserBtnPositionObj = (screen, prop, value) =>
	{
		setUserBtnPositionObj((state) =>
		{
			const updatedState = { ...state };

			updatedState[screen][prop] = value;

			return {
				...updatedState
			};

		});
	};

	const onDropLogo = useCallback((acceptedFiles) =>
	{
		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUserLogoUrl(src);
			setUploadLogoFile(acceptedFiles[0]);
		}

	}, []);

	const onDropBgPC = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUserBgPCUrl(src);
			setUploadBgPCFile(acceptedFiles[0]);
		}

	}, []);

	const onDropBgSP = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUserBgSPUrl(src);
			setUploadBgSPFile(acceptedFiles[0]);
		}

	}, []);

	const uploadLogo = async () =>
	{
		let url = userLogoUrl ? userLogoUrl : '';

		if (uploadLogoFile && uploadLogoFile.name)
		{
			const storageref = storage.ref(`/images/${userSubDomain}/logo_${uploadLogoFile.name}`);
			const snapshot = await storageref.put(uploadLogoFile);

			url = await snapshot.ref.getDownloadURL();
			setUserLogoUrl(url);
		}

		return url;
	};

	const uploadBgPC = async () =>
	{
		let url = userBgPCUrl ? userBgPCUrl : '';

		if (uploadBgPCFile && uploadBgPCFile.name)
		{
			const storageref = storage.ref(`/images/${userSubDomain}/logo_${uploadBgPCFile.name}`);
			const snapshot = await storageref.put(uploadBgPCFile);

			url = await snapshot.ref.getDownloadURL();
			setUserBgPCUrl(url);
		}

		return url;
	};

	const uploadBgSP = async () =>
	{
		let url = userBgSPUrl ? userBgSPUrl : '';

		if (uploadBgSPFile && uploadBgSPFile.name)
		{
			const storageref = storage.ref(`/images/${userSubDomain}/logo_${uploadBgSPFile.name}`);
			const snapshot = await storageref.put(uploadBgSPFile);

			url = await snapshot.ref.getDownloadURL();
			setUserBgSPUrl(url);
		}

		return url;
	};

	const handleChangeButtonColor = (value) =>
	{
		setButtonColor(`${ value.replace(/[^0-9a-fA-F]/, '')}`);
	};

	const handleChangeGuestInput1Select = (e) =>
	{

		if (e.target.value === 'other')
		{
			setGuestInputField1({
				type : 'other',
				key  : ''

			});
		}
		else if (e.target.value === 'none')
		{
			setGuestInputField1({
				type : e.target.value,
				key  : ''
			});
		}
		else if (e.target.value === 'email')
		{
			setGuestInputField1({
				type : e.target.value,
				key  : 'Eメール'

			});
		}
		else if (e.target.value === 'phone')
		{
			setGuestInputField1({
				type : e.target.value,
				key  : '電話番号'

			});
		}
	};

	const handleChangeGuestInput2Select = (e) =>
	{

		if (e.target.value === 'other')
		{
			setGuestInputField2({
				type : 'other',
				key  : ''
			});
		}
		else if (e.target.value === 'none')
		{
			setGuestInputField2({
				type : e.target.value,
				key  : ''
			});
		}
		else if (e.target.value === 'email')
		{
			setGuestInputField2({
				type : e.target.value,
				key  : 'Eメール'
			});
		}
		else if (e.target.value === 'phone')
		{
			setGuestInputField2({
				type : e.target.value,
				key  : '電話番号'
			});
		}
	};

	const addSkillGroup = (skillGroup) =>
	{
		const newPages = [ ...skillGroups ];

		newPages.push(skillGroup);

		newPages.sort((a, b) =>
		{
			if (a.skillId < b.skillId) { return -1; }
			if (a.skillId > b.skillId) { return 1; }

			return 0;
		});

		setSkillGroups([ ...newPages ]);

		saveSkillGroups(user.id, newPages);
	};

	const updateSkillGroup = (skillGroup, skillId) =>
	{
		const newPages = [ ...skillGroups ];

		const skillIndex = newPages.findIndex((item) => item.skillId === skillId);

		newPages[skillIndex] = { ...skillGroup };

		setSkillGroups([ ...newPages ]);

		saveSkillGroups(user.id, newPages);
	};

	const removeSkillGroup = (skillId) =>
	{
		const newPages = skillGroups.filter((item) => item.skillId !== skillId);

		setSkillGroups([ ...newPages ]);
		saveSkillGroups(user.id, newPages);
	};

	const handleChangeBtnPosDirection = (prop, val) =>
	{

		// set value
		setBtnPosDirectionSelector((state) =>
		{
			const stateToUpdate = { ...state };

			stateToUpdate[prop] = val;

			return { ...stateToUpdate };
		});

		if (val === 'center')
		{
			if (prop==='verticalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop==='horizontalPC')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'top')
		{
			if (prop==='verticalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '50';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '50';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'bottom')
		{
			if (prop==='verticalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.bottom = '50';
					stateToUpdate.PC.top = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.bottom = '50';
					stateToUpdate.SP.top = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'left')
		{
			if (prop==='horizontalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '50';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'right')
		{
			if (prop==='horizontalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.right = '50';
					stateToUpdate.PC.left = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
	};

	const handleSave = async () =>
	{
		if (saveCb)
		{
			const logUrl = await uploadLogo();
			const bgPCUrl = await uploadBgPC();
			const bgSPUrl = await uploadBgSP();

			// edit
			if (user)
			{
				// don't change email, subdomain, password, adminPassword
				saveCb({
					id                       : user.id,
					number                   : userNumber,
					name                     : userName,
					tell                     : userTell,
					roomNum                  : userRoomNum,
					callableHostsLimit       : userCallableHostsLimit,
					serviceName              : userServiceName,
					browserTitle             : userBrowserTitle,
					subdomain                : user.subdomain,
					status                   : user && user.status ? user.status : 0,
					plan                     : userPlan,
					optionTodo               : userTodoCheck,
					optionCall               : userCallCheck,
					queueEnabled             : queueEnabled,
					directMode               : userDirectModeCheck,
					sendChatLog              : userSendChatLog,
					guestHomeUrl             : userGuestHomeUrl,
					logoUrl                  : logUrl,
					bgPCUrl                  : bgPCUrl,
					bgSPUrl                  : bgSPUrl,
					portalCallOnly           : portalCallOnly,
					personalCallEnabled      : personalCallEnabled,
					appointmentStatusEnabled : appointmentStatusEnabled,
					btnPosition              : userBtnPositionObj,
					holiday                  : userHoliday,
					nationalHoliday          : userNationalHoliday,
					guestInputField1         : guestInputField1,
					guestInputField2         : guestInputField2,
					additionalCallFuncs      : additionalCallFuncs,
					socketServerUrl          : socketServerUrl,
					memoCategories           : memoCategories,
					averageCallDuration      : averageCallDuration,
					dataExpired              : dataExpired,
					storageEnabled           : storageEnabled,
					roomStateEnter           : roomStateEnter,
					roomStateCallEnd         : roomStateCallEnd,
					openTime                 : userOpenTime,
					closeTime                : userCloseTime,
					buttonColor              : buttonColor,
					buttonText               : buttonText,
					buttonPadding            : buttonPadding,
					bgPCGradient             : bgPCGradient,
					bgSPGradient             : bgSPGradient,
					role                     : userRole,
					customRoles              : customRoles,
					skillGroups              : skillGroups,
					remarks                  : ''
				});
			}
			// 新規
			else
			{
				saveCb({
					id                       : -1,
					number                   : userNumber,
					name                     : userName,
					email                    : userEmail,
					tell                     : userTell,
					adminPassword            : userAdminPassword,
					roomNum                  : userRoomNum,
					callableHostsLimit       : userCallableHostsLimit,
					serviceName              : userServiceName,
					browserTitle             : userBrowserTitle,
					subdomain                : userSubDomain,
					status                   : user && user.status ? user.status : 0,
					plan                     : userPlan,
					optionTodo               : userTodoCheck,
					optionCall               : userCallCheck,
					queueEnabled             : queueEnabled,
					directMode               : userDirectModeCheck,
					sendChatLog              : userSendChatLog,
					guestHomeUrl             : userGuestHomeUrl,
					logoUrl                  : logUrl,
					bgPCUrl                  : bgPCUrl,
					bgSPUrl                  : bgSPUrl,
					portalCallOnly           : portalCallOnly,
					personalCallEnabled      : personalCallEnabled,
					appointmentStatusEnabled : appointmentStatusEnabled,
					btnPosition              : userBtnPositionObj,
					holiday                  : userHoliday,
					nationalHoliday          : userNationalHoliday,
					guestInputField1         : guestInputField1,
					guestInputField2         : guestInputField2,
					additionalCallFuncs      : additionalCallFuncs,
					socketServerUrl          : socketServerUrl,
					memoCategories           : memoCategories,
					averageCallDuration      : averageCallDuration,
					dataExpired              : dataExpired,
					storageEnabled           : storageEnabled,
					roomStateEnter           : roomStateEnter,
					roomStateCallEnd         : roomStateCallEnd,
					openTime                 : userOpenTime,
					closeTime                : userCloseTime,
					buttonColor              : buttonColor,
					buttonText               : buttonText,
					buttonPadding            : buttonPadding,
					bgPCGradient             : bgPCGradient,
					bgSPGradient             : bgSPGradient,
					role                     : userRole,
					customRoles              : customRoles,
					skillGroups              : skillGroups,
					remarks                  : ''
				});
			}
		}
	};

	const updateEmail = () =>
	{
		if (user && user.id && userEmail)
		{
			setErrors({
				show   : false,
				errors : []
			});

			const updateAuthEmailAPI = functions.httpsCallable('updateAuthEmail');

			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

			if (!emailPattern.test(userEmail))
			{
				setErrors({
					show   : true,
					errors : [ 'メールアドレスが正しくありません。' ]
				});

				return;
			}

			setLoading(true);

			updateAuthEmailAPI({
				type  : 'user',
				id    : user.id,
				email : userEmail
			})
				.then((response) =>
				{
					if (response.data?.success)
					{
						setErrors({
							show   : false,
							errors : []
						});
						setEditEmail(false);
						setLoading(false);
					}
					else
					{
						setErrors({
							show   : true,
							errors : [ 'メールアドレスの変更に失敗しました' ]
						});
						setLoading(false);
					}
				})
				.catch(() =>
				{
					setErrors({
						show   : true,
						errors : [ 'メールアドレスの変更に失敗しました' ]
					});
					setLoading(false);
				});

		}

	};

	const handleDelete = () =>
	{
		if (deleteCb)
		{
			deleteCb({
				id        : user.id,
				subdomain : user.subdomain
			});
		}
	};

	const handleCancel = () =>
	{
		if (closeCb)
		{
			closeCb();
			setErrors({
				show   : false,
				errors : []
			});
		}
	};

	const EXPIRED_DURATION_LIST = [
		{ name: '1日', value: 1 },
		{ name: '30日', value: 30 },
		{ name: '60日', value: 60 },
		{ name: '90日', value: 90 },
		{ name: '120日', value: 120 },
		{ name: '180日', value: 180 },
		{ name: '365日', value: 365 }
	];

	return (
		<MuiThemeProvider theme={theme}>

			<EditRoleDialog
				show={roleEditDialogState.show}
				roleCode={roleEditDialogState.roleCode}
				roleNumber={roleEditDialogState.roleNumber}
				closeMethod={() => setRoleEditDialogState({ roleCode: '', roleNumber: 1, show: false })}
				customRoles={customRoles}
				setCustomRoles={setCustomRoles}
			/>

			<EditSkillGroupDialog
				show={editSkillGroupDialog.show}
				skillGroup={editSkillGroupDialog.skillGroup}
				newItem={editSkillGroupDialog.newItem}
				skillGroups={skillGroups}
				subdomain={userSubDomain}
				updateSkillGroup={updateSkillGroup}
				addSkillGroup={addSkillGroup}
				closeMethod={() => setEditSkillGroupDialog({
					show       : false,
					skillGroup : {},
					skillId    : null,
					newItem    : false
				})}
			/>

			<MemoCategoryDialog
				show={memoCategoryDialog.show}
				memoCategories={memoCategories}
				setMemoCategories={setMemoCategories}
				closeMethod={() => setMemoCategoryDialog({ show: false })}
			/>

			<Box className={classes.root}>
				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Typography className={classes.pageTitle}>
						アカウント登録情報
					</Typography>
					{errors.show === true &&
					<Box className={classes.errorBox}>
						{errors.errors.map((err, index) =>
							(<Typography className={classes.errMsg} key={index}>
								・{err}
							</Typography>)
						)}
					</Box>

					}
					<Box className={classes.top}>
						<Box className={classes.topLeft}>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>顧客 No.</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userNumber}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserNumber}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>契約社名</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userName}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserName}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>サービス名</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userServiceName}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserServiceName}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>オーナー ID</Typography>
									<Typography className={classes.subLabel}>(メールアドレス)</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userEmail}
										className={`${classes.inputField} ${classes.emailField}`}
										onChange={handleChangeUserEmail}
										type='text'
										disabled={user && !editEmail}
									/>
									{	editEmail ?
										<Button
											variant='contained'
											color='secondary'
											onClick={updateEmail}
											disabled={!user?.id}
											className={classes.editEmailBtn}
										>
											更新
										</Button>
										:
										<Button
											variant='contained'
											color='primary'
											onClick={() => setEditEmail(true)}
											disabled={!user?.id}
											className={classes.editEmailBtn}
										>
											編集
										</Button>
									}
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>SMS通知用電話番号</Typography>
									<Typography className={classes.subLabel}>( ハイフンなし )</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userTell}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserTell}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>オーナーパスワード</Typography>
									<Typography className={classes.subLabelSmall}>
										(半角英数字8文字以上)
									</Typography>
									<Typography className={classes.subLabelSmall}>
										(英大文字を含む)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userAdminPassword}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserAdminPassword}
										type='text'
										disabled={user}
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>サブドメイン</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userSubDomain}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserSubDomain}
										type='text'
										disabled={user}
									/>
								</Box>
							</Box>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>録音データ管理</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={storageEnabled}
													onChange={handleChangeUserStorageCheck}
												/>
											}
											label='ストレージ契約'
										/>
										<Box className={`${classes.inputLabel} ${classes.inputSpaceRight}`}>
											<Typography className={classes.labelSmall}>データ削除期間</Typography>
										</Box>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={dataExpired}
											defaultValue={0}
											onChange={handleChangeUserDataExpiredSelect}
										>
											{ EXPIRED_DURATION_LIST.map((item) =>
											{
												return (
													<MenuItem
														key={item.value}
														value={item.value}
													>
														{item.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormGroup>
								</div>
							</div>

							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>roomステータス</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row>
										<Box className={`${classes.inputLabelLeft} ${classes.inputSpaceRightS}`}>
											<Typography className={classes.labelSmall}>入室時</Typography>
										</Box>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={roomStateEnter}
											onChange={handleChangeUserRoomStateEnter}
										>
											<MenuItem value={ROOM_ONLINE}>待機</MenuItem>
											<MenuItem value={ROOM_AFK}>離席</MenuItem>
										</Select>
										<Box className={`${classes.inputLabelLeft} ${classes.inputSpaceRightS} ${classes.inputSpaceLeft}`}>
											<Typography className={classes.labelSmall}>通話終了時</Typography>
										</Box>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={roomStateCallEnd}
											onChange={handleChangeUserRoomStateCallEnd}
										>
											<MenuItem value={ROOM_ONLINE}>待機</MenuItem>
											<MenuItem value={ROOM_AFK}>離席</MenuItem>
										</Select>
									</FormGroup>
								</div>
							</div>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>ゲスト入力欄1</Typography>
								</Box>
								<Box className={classes.inputValueFlex}>
									<Box className={classes.guestInputColSelect}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={guestInputField1.type}
											onChange={handleChangeGuestInput1Select}
										>
											<MenuItem value={'phone'}>電話番号</MenuItem>
											<MenuItem value={'email'}>Eメール</MenuItem>
											<MenuItem value={'other'}>その他</MenuItem>
											<MenuItem value={'none'}>なし</MenuItem>
										</Select>
									</Box>

									<Box className={classes.guestInputColInput}>
										<input
											value={guestInputField1.key}
											disabled={guestInputField1.type !== 'other'}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeGuestInputField(e, 1)}
											type='text'
										/>
									</Box>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ゲスト入力欄2</Typography>
								</Box>
								<Box className={classes.inputValueFlex}>
									<Box className={classes.guestInputColSelect}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={guestInputField2.type}
											onChange={handleChangeGuestInput2Select}
										>
											<MenuItem value={'phone'}>電話番号</MenuItem>
											<MenuItem value={'email'}>Eメール</MenuItem>
											<MenuItem value={'other'}>その他</MenuItem>
											<MenuItem value={'none'}>なし</MenuItem>
										</Select>
									</Box>

									<Box className={classes.guestInputColInput}>
										<input
											value={guestInputField2.key}
											disabled={guestInputField2.type !== 'other'}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeGuestInputField(e, 2)}
											type='text'
										/>
									</Box>
								</Box>
							</Box>
							<Box className={classes.topDataMulti}>
								<Box className={classes.topDataTitle}>
									<Box className={classes.inputLabelStart}>
										<Typography className={classes.label}>役職設定</Typography>
									</Box>
								</Box>
								<Box className={classes.customRoleLine}>
									<Box className={classes.inputItemCustomRole}>
										<Box className={classes.inputLabelCustomRole}>
											{	customRoles.operator1?.active ?
												<CheckCircleOutlineIcon
													className={classes.activatedRole}
													onClick={() => toggleCustomRoleActive('operator1')}
												/>
												:
												<NotInterestedIcon
													className={classes.disabledRole}
													onClick={() => toggleCustomRoleActive('operator1')}
												/>
											}
											<Typography className={classes.labelCustomRole}>
												オペレータ1
											</Typography>
										</Box>

										<Button
											variant='contained'
											size='small'
											classes={{
												root : classes.roleEditButton
											}}
											onClick={() => showRoleEditDialog('operator1', 1)}
										>
											編集
										</Button>

									</Box>
									<Box className={classes.inputItemCustomRole}>
										<Box className={classes.inputLabelCustomRole}>
											{	customRoles.operator2?.active ?
												<CheckCircleOutlineIcon
													className={classes.activatedRole}
													onClick={() => toggleCustomRoleActive('operator2')}
												/>
												:
												<NotInterestedIcon
													className={classes.disabledRole}
													onClick={() => toggleCustomRoleActive('operator2')}
												/>
											}
											<Typography className={classes.labelCustomRole}>
												オペレータ2
											</Typography>
										</Box>

										<Button
											variant='contained'
											size='small'
											classes={{
												root : classes.roleEditButton
											}}
											onClick={() => showRoleEditDialog('operator2', 2)}
										>
											編集
										</Button>

									</Box>
								</Box>

								<Box className={classes.customRoleLine}>
									<Box className={classes.inputItemCustomRole}>

										<Box className={classes.inputLabelCustomRole}>
											{	customRoles.sv1?.active ?
												<CheckCircleOutlineIcon
													className={classes.activatedRole}
													onClick={() => toggleCustomRoleActive('sv1')}
												/>
												:
												<NotInterestedIcon
													className={classes.disabledRole}
													onClick={() => toggleCustomRoleActive('sv1')}
												/>
											}
											<Typography className={classes.labelCustomRole}>
												SV1
											</Typography>
										</Box>

										<Button
											variant='contained'
											size='small'
											classes={{
												root : classes.roleEditButton
											}}
											onClick={() => showRoleEditDialog('sv1', 1)}
										>
											編集
										</Button>
									</Box>
									<Box className={classes.inputItemCustomRole}>
										<Box className={classes.inputLabelCustomRole} >
											{	customRoles.sv2?.active ?
												<CheckCircleOutlineIcon
													className={classes.activatedRole}
													onClick={() => toggleCustomRoleActive('sv2')}
												/>
												:
												<NotInterestedIcon
													className={classes.disabledRole}
													onClick={() => toggleCustomRoleActive('sv2')}
												/>
											}
											<Typography className={classes.labelCustomRole}>
												SV2
											</Typography>
										</Box>
										<Button
											variant='contained'
											size='small'
											classes={{
												root : classes.roleEditButton
											}}
											onClick={() => showRoleEditDialog('sv2', 2)}
										>
											編集
										</Button>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box className={classes.topRight}>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>通話担当ID数</Typography>
									<Typography className={classes.subLabelSmall}>
										(SV、オペレータ)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input value={userCallableHostsLimit} className={classes.inputField} onChange={handleChangeUserCallableHostsLimit} type='number' min='0'/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ルーム数</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input value={userRoomNum} className={classes.inputField} onChange={handleChangeUserRoomNum} type='number' min='0'/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>プラン</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={userPlan}
										defaultValue={0}
										onChange={handleChangeUserPlan}
									>
										{ PLANS.map((plan) =>
										{
											return (
												<MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
											);
										})}
									</Select>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>管理権限</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={userRole}
										defaultValue={'common'}
										onChange={handleChangeRole}
										disabled={!admin.rights?.changeRole}
									>
										<MenuItem value={'admin'}>管理者</MenuItem>
										<MenuItem value={'superUser'}>編集者</MenuItem>
										<MenuItem value={'common'}>なし</MenuItem>
									</Select>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ブラウザタイトル</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userBrowserTitle}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserBrowserTitle}
										type='text'
									/>
								</Box>
							</Box>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>通話後URL</Typography>
									<Typography className={classes.subLabelSmall}>
										(通話後ゲストを転送)
									</Typography>
								</Box>
								<div className={classes.inputValue}>
									<input
										value={userGuestHomeUrl}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeUserGuestHomeUrl(e)}
										type='text'
									/>
								</div>
							</div>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>内部通話</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row className={classes.dualItemRow}>
										<Box className={classes.dualItem}>
											<Box className={`${classes.inputLabelLeft} ${classes.inputSpaceRightS}`}>
												<Typography className={classes.labelSmall}>ミーティング</Typography>
											</Box>
											<Select
												classes={{ root: classes.inputListSmall }}
												value={additionalCallFuncs.meeting}
												onChange={(e) => { handleChangeAdditionalCallFunctions('meeting', e); }}
											>
												<MenuItem value>オン</MenuItem>
												<MenuItem value={false}>オフ</MenuItem>
											</Select>
										</Box>
										<Box className={classes.dualItem}>
											<Box className={`${classes.inputLabelLeft} ${classes.inputSpaceRightS} ${classes.inputSpaceLeft}`}>
												<Typography className={classes.labelSmall}>三者通話</Typography>
											</Box>
											<Select
												classes={{ root: classes.inputListSmall }}
												value={additionalCallFuncs.internalSupport}
												onChange={(e) => { handleChangeAdditionalCallFunctions('internalSupport', e); }}
											>
												<MenuItem value>オン</MenuItem>
												<MenuItem value={false}>オフ</MenuItem>
											</Select>
										</Box>
									</FormGroup>
								</div>
							</div>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>外部サポート</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row className={classes.dualItemRow}>
										<Box className={classes.dualItem}>
											<Box className={`${classes.inputLabelLeft} ${classes.inputSpaceRightS}`}>
												<Typography className={classes.labelSmall}>発信</Typography>
											</Box>
											<Select
												classes={{ root: classes.inputListSmall }}
												value={additionalCallFuncs.requestSupportCall}
												onChange={(e) => { handleChangeAdditionalCallFunctions('requestSupportCall', e); }}
											>
												<MenuItem value>オン</MenuItem>
												<MenuItem value={false}>オフ</MenuItem>
											</Select>
										</Box>
										<Box className={classes.dualItem}>
											<Box className={`${classes.inputLabelLeft} ${classes.inputSpaceRightS} ${classes.inputSpaceLeft}`}>
												<Typography className={classes.labelSmall}>受信</Typography>
											</Box>
											<Select
												classes={{ root: classes.inputListSmall }}
												value={additionalCallFuncs.acceptSupportCall}
												onChange={(e) => { handleChangeAdditionalCallFunctions('acceptSupportCall', e); }}
											>
												<MenuItem value>オン</MenuItem>
												<MenuItem value={false}>オフ</MenuItem>
											</Select>
										</Box>
									</FormGroup>
								</div>
							</div>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>通話サーバURL</Typography>
									<Typography className={classes.subLabelSmall}>
										(通話サーバが別の場合)
									</Typography>
								</Box>
								<div className={classes.inputValue}>
									<input
										value={socketServerUrl}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeSocketServerUrl(e)}
										type='text'
									/>
								</div>
							</div>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>メモカテゴリー</Typography>
								</Box>
								<div className={classes.inputValue}>
									<Button
										variant='contained'
										size='small'
										classes={{
											root : classes.memoCatBtn
										}}
										onClick={() => setMemoCategoryDialog({ show: true })}
									>
										編集
									</Button>
								</div>
							</div>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>平均通話秒数</Typography>
									<Typography className={classes.subLabelSmall}>
										(待ち時間計測用)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input value={averageCallDuration} className={classes.inputField} onChange={handleAverageTalkDuration} type='number' min='1'/>
								</Box>
							</Box>

							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>オプション</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={userTodoCheck}
													onChange={handleChangeUserTodoCheck}
												/>
											}
											label='TODO リスト'
										/>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={userCallCheck}
													onChange={handleChangeUserCallCheck}
												/>
											}
											label='着信リスト'
										/>
									</FormGroup>
								</div>
							</div>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>着信</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={queueEnabled}
													onChange={handleChangeQueueEnabled}
												/>
											}
											label='待ち呼'
										/>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={userDirectModeCheck}
													onChange={handleChangeUserDirectModeCheck}
												/>
											}
											label='ダイレクトモード'
										/>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={portalCallOnly}
													onChange={(e) => handleChangePortalCallOnly(e)}
												/>
											}
											label='ポータル以外拒否'
										/>
									</FormGroup>
								</div>
							</div>

							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>指名機能</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row>

										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={personalCallEnabled}
													onChange={(e) => handleChangePersonalCallEnabled(e)}
												/>
											}
											label='指名着信'
										/>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={appointmentStatusEnabled}
													onChange={(e) => handleChangeAppointmentStatusEnabled(e)}
												/>
											}
											label='呼び出し待機'
										/>
									</FormGroup>
								</div>
							</div>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>チャットログ</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<FormGroup row>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={userSendChatLog}
													onChange={handleChangeUserSendChatLog}
												/>
											}
											label='送信画面表示'
										/>
									</FormGroup>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className={`${classes.imageGroup} ${classes.group}`}>
					<Box className={`${classes.imageLeft} ${classes.wrapper}`}>
						<Box height='1.4rem'/>
						<Box>
							<Box className={classes.imageNameGroup}>
								<ImageIcon className={classes.imageIcon}/>
								<Box className={classes.imageBorder}>
									<Typography className={classes.imageName}>
										ロゴファイル
									</Typography>
									<Dropzone
										onDrop={onDropLogo}
										className={classes.imageName}
										accept={{
											'image/png'  : [],
											'image/jpeg' : [],
											'image/gif'  : [],
											'image/jpg'  : []
										}}
									>
										{ ({ getRootProps, getInputProps }) => (
											<section>
												<div {...getRootProps()}>
													<input {...getInputProps()} />
													{uploadLogoFile ?
														<Typography>{uploadLogoFile.name}</Typography>
														:
														<Typography>ドラッグ or クリック</Typography>
													}
												</div>
											</section>
										)}
									</Dropzone>
								</Box>
							</Box>
							<Box className={classes.imageBox}>
								{ userLogoUrl &&
								<img
									alt='logo'
									src={userLogoUrl}
									height='100%'
									width='100%'
								/>
								}
							</Box>
						</Box>
					</Box>
					<Box className={`${classes.imageRight} ${classes.wrapper}`}>
						<Typography className={classes.pageTitle}>
							待受画面設定
						</Typography>
						<Box className={classes.screenSetting}>
							<Box className={classes.uptimeSetting}>
								<WorkTimeSetting
									holiday={userHoliday}
									handleChangeHolidayCallback={handleChangeHolidayCallback}
									openTime={userOpenTime}
									setOpenTime={setUserOpenTime}
									closeTime={userCloseTime}
									setCloseTime={setUserCloseTime}
									nationalHoliday={userNationalHoliday}
									handleChangeNationalHolidayCallback={
										handleChangeNationalHolidayCallback
									}
								/>
							</Box>
							<Box className={classes.screen}>
								<Box className={classes.imageNameGroup}>
									<ImageIcon className={classes.imageIcon}/>
									<Box className={classes.imageBorder}>
										<Typography className={classes.imageName}>
											PC 待受け背景ファイル
										</Typography>
										<Dropzone onDrop={onDropBgPC} className={classes.imageName}>
											{ ({ getRootProps, getInputProps }) => (
												<section>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														{uploadBgPCFile ?
															<Typography>{uploadBgPCFile.name}</Typography>
															:
															<Typography>ドラッグ or クリック</Typography>
														}
													</div>
												</section>
											)}
										</Dropzone>
									</Box>
								</Box>
								<Box className={classes.imageBox}>
									{ userBgPCUrl &&
									<img
										alt='logo'
										src={userBgPCUrl}
										height='100%'
										width='100%'
									/>
									}
								</Box>
							</Box>
							<Box className={classes.screen}>
								<Box className={classes.imageNameGroup}>
									<ImageIcon className={classes.imageIcon}/>
									<Box className={classes.imageBorder}>
										<Typography className={classes.imageName}>
											スマホ待受け背景ファイル
										</Typography>
										<Dropzone onDrop={onDropBgSP} className={classes.imageName}>
											{ ({ getRootProps, getInputProps }) => (
												<section>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														{uploadBgSPFile ?
															<Typography>{uploadBgSPFile.name}</Typography>
															:
															<Typography>ドラッグ or クリック</Typography>
														}
													</div>
												</section>
											)}
										</Dropzone>
									</Box>
								</Box>
								<Box className={classes.imageBox}>
									{ userBgSPUrl &&
									<img
										alt='logo'
										src={userBgSPUrl}
										height='100%'
										width='100%'
									/>
									}
								</Box>
							</Box>
						</Box>

						<Box className={classes.waitingScreenSettingLine} >
							<Box className={`${classes.buttonColorInputArea} ${classes.inputSpaceRight}`}>
								<Typography >
									ボタンカラー
								</Typography>
								<Box>
									<input
										maxLength={7}
										value={`#${buttonColor}`}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeButtonColor(e.target.value.substring(1))}
										type='text'
									/>
								</Box>

							</Box>

							<Box className={`${classes.buttonTextInputArea} ${classes.inputSpaceRight}`}>
								<Typography>
									ボタンテキスト
								</Typography>
								<Box>
									<input
										value={buttonText}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => setButtonText(e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.buttonTextInputArea}>
								<Typography>
									ボタン内部余白(padding)
								</Typography>
								<Box>
									<input
										value={buttonPadding}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => setButtonPadding(e.target.value)}
										type='text'
									/>
								</Box>
							</Box>
						</Box>

						<Box className={classes.waitingScreenSettingLine} >
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									PC 縦ボタン位置
								</Typography>
								<Box className={classes.settingLineStart} >
									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.verticalPC}
											onChange={(e) => handleChangeBtnPosDirection('verticalPC', e.target.value)}
										>
											<MenuItem value={'top'}>上(%)</MenuItem>
											<MenuItem value={'bottom'}>下(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={userBtnPositionObj.PC.verticCenter ? '' : userBtnPositionObj['PC'][btnPosDirectionSelector.verticalPC]}
											disabled={btnPosDirectionSelector.verticalPC === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeUserBtnPositionObj(
												'PC',
												btnPosDirectionSelector.verticalPC,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>
							</Box>
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									PC 横ボタン位置
								</Typography>
								<Box className={classes.settingLineStart} >
									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.horizontalPC}
											onChange={(e) => handleChangeBtnPosDirection('horizontalPC', e.target.value)}
										>
											<MenuItem value={'left'}>左(%)</MenuItem>
											<MenuItem value={'right'}>右(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={userBtnPositionObj.PC.horizCenter ? '' : userBtnPositionObj['PC'][btnPosDirectionSelector.horizontalPC]}
											disabled={btnPosDirectionSelector.horizontalPC === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeUserBtnPositionObj(
												'PC',
												btnPosDirectionSelector.horizontalPC,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>

							</Box>
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									スマホ 縦ボタン位置
								</Typography>
								<Box className={classes.settingLineStart} >

									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.verticalSP}
											onChange={(e) => handleChangeBtnPosDirection('verticalSP', e.target.value)}
										>
											<MenuItem value={'top'}>上(%)</MenuItem>
											<MenuItem value={'bottom'}>下(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={userBtnPositionObj.SP.verticCenter ? '' : userBtnPositionObj['SP'][btnPosDirectionSelector.verticalSP]}
											disabled={btnPosDirectionSelector.verticalSP === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeUserBtnPositionObj(
												'SP',
												btnPosDirectionSelector.verticalSP,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>
							</Box>
						</Box>

						<Box className={classes.waitingScreenSettingLine} >

							<Box className={`${classes.gradientInputArea} ${classes.inputSpaceRight}`}>
								<Typography>
									PC 背景グラディエーション
								</Typography>
								<Box>
									<input
										value={bgPCGradient}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => setBgPCGradient(e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.gradientInputArea}>
								<Typography>
									スマホ 背景グラディエーション
								</Typography>
								<Box>
									<input
										placeholder='#FFF, #FFF'
										value={bgSPGradient}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => setBgSPGradient(e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

						</Box>
					</Box>
				</Box>

				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Typography className={classes.pageTitle}>
						スキルグループ管理
					</Typography>
					<Box className={classes.createLobbyBtnWrapper}>
						<Button
							variant='contained'
							classes={{
								root : classes.lobbyButton
							}}
							onClick={() => showEditSkillGroupDialog(null, true)}
							disabled={!user}
						>
							追加
						</Button>
					</Box>

					<TableContainer component={Paper} className={classes.lobbyTableContainer}>
						<Table aria-label='call target table'>
							<TableHead>
								<TableRow>
									<TableCell align='left'>スキルID</TableCell>
									<TableCell align='left'>スキル名</TableCell>
									<TableCell align='left'>URL</TableCell>
									<TableCell align='right'>操作</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ skillGroups && skillGroups.length > 0 ?
									<>
										{	skillGroups.map((s) => (
											<TableRow key={s.skillId}>
												<TableCell align='left'>{s.skillId}</TableCell>
												<TableCell align='left'>{s.skillName}</TableCell>
												<TableCell align='left'>
													{ userSubDomain &&
													<a
														className={classes.lobbyLink}
														href={`https://${userSubDomain}.${domain}/lobby?id=${s.skillId}`}
														target='_blank' rel='noreferrer'
													>
														{`https://${userSubDomain}.${domain}/lobby?id=${s.skillId}`}
													</a>
													}
												</TableCell>
												<TableCell align='right'>
													<Button
														variant='contained'
														classes={{
															root : classes.lobbyButton
														}}
														onClick={
															() => showEditSkillGroupDialog(s.skillId, false)
														}
													>
														編集
													</Button>
													<Button
														variant='contained'
														color='secondary'
														onClick={
															() => removeSkillGroup(s.skillId)
														}
														className={classes.tableBtnRight}
													>
														削除
													</Button>
												</TableCell>
											</TableRow>
										))}
									</>
									:
									<TableRow>
										<TableCell
											align='center'
											className={classes.noItemRow}
											colSpan={4}
										>
											アイテムがありません
										</TableCell>
									</TableRow>

								}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>

				<Box className={classes.actionGroup}>
					<Button
						onClick={handleSave}
						className={classes.inputBtn}
						variant='contained'
						disabled={
							(!user && !admin.rights?.createUser)
							|| (user && !admin.rights?.editUser)
						}
					>
						保存
					</Button>
					<Button
						onClick={handleDelete}
						className={classes.inputBtn}
						variant='contained'
						disabled={!user || !admin.rights?.deleteUser}
					>
						削除
					</Button>
					<Button onClick={handleCancel} className={classes.inputBtn} variant='contained'>
						キャンセル
					</Button>
				</Box>
			</Box>
		</MuiThemeProvider>
	);

};

AdminUserEditView.propTypes =
{
	classes         : PropTypes.object.isRequired,
	user            : PropTypes.object,
	admin           : PropTypes.object,
	saveCb          : PropTypes.func.isRequired,
	closeCb         : PropTypes.func.isRequired,
	deleteCb        : PropTypes.func.isRequired,
	saveSkillGroups : PropTypes.func.isRequired,
	errors          : PropTypes.object,
	setErrors       : PropTypes.func,
	setRoomsNum     : PropTypes.func,
	setLoading      : PropTypes.func
};

export default withStyles(styles)(AdminUserEditView);
