import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

// material ui
import { Button, Typography } from '@material-ui/core';

// components
import ConnectPurchaseDialog from './ConnectPurchaseDialog';

// utils
import { handleConnectFilePurchase } from '../../../../utils';

const styles = () =>
	({
		root : {
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'end'
		},
		keys : {
			fontSize  : '0.8rem',
			color     : 'var(--text-color)',
			textAlign : 'end',
			margin    : '0',
			padding   : '0 2px'
		},
		button : {
			padding         : '3px 20px',
			fontSize        : '1rem',
			backgroundColor : '#8eba14',
			color           : 'white',
			maxWidth        : '260px',
			'&:hover'       : {
				filter : 'saturate(105%) brightness(105%)'
			}
		},
		hiddenInput :
		{
			display : 'none'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ConnectPurchaseButton = ({
	classes,
	paramKey,
	priceList,
	priceListTerm,
	email,
	defaultExpiryTerm,
	accountId
}) =>
{
	const hiddenFileInput = useRef();

	const [ fileArray, setFileArray ] = useState([]);

	const [ fileResult, setFileResult ] = useState([]);

	const [ priceListPurchase, setPriceListPurchase ] = useState([]);

	const [ showConnectPurchaseDialog, setShowConnectPurchaseDialog ] = useState(false);

	const closeShowConnectPurchaseDialog = () =>
	{
		setFileArray([]);
		setFileResult([]);
		setPriceListPurchase([]);
		setShowConnectPurchaseDialog(false);
	};

	const startPurchaseByCsv = () =>
	{
		hiddenFileInput.current.click();
	};

	const csvFileToArray = (string) =>
	{
		const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
		const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

		const array = csvRows.map((row) =>
		{
			const values = row.split(',');

			const obj = {};

			csvHeader.forEach((header, index) =>
			{
				let headerStr = '';

				if (header)
				{
					headerStr = header.trim();
				}

				let valueVal = values[index] || '';

				if (headerStr === 'id' && values[index])
				{
					valueVal = values[index].trim();
				}
				// remove space
				obj[headerStr] = valueVal;
			});

			return obj;
		});

		setFileArray(array);
	};

	const handleCSV = (event) =>
	{
		event.preventDefault();

		const fileUploaded = event.target.files[0];

		const fileReader = new FileReader();

		fileReader.onload = async function(e)
		{
			const text = e.target.result;

			csvFileToArray(text);

		};

		try
		{
			fileReader.readAsText(fileUploaded);
		}
		catch (e)
		{
			// eslint-disable-next-line no-console
			console.log('file read error:', e);
		}
	};

	useEffect(() =>
	{
		if (fileArray && fileArray.length > 0)
		{

			const { resultList, purchaseList } = handleConnectFilePurchase({
				fileArray,
				priceList,
				priceListTerm,
				defaultExpiryTerm,
				paramKey
			});

			setFileResult(resultList);

			setPriceListPurchase(purchaseList);

			setShowConnectPurchaseDialog(true);
		}
	}, [ fileArray, priceList, priceListTerm, paramKey, defaultExpiryTerm, email ]);

	return (
		<MuiThemeProvider theme={theme}>
			<div className={classes.root}>
				<Typography className={classes.keys}>
					id:顧客側識別番号、email:メールアドレス、quantity:数量、entry_key:エントリーキー または days: 日数
				</Typography>
				<Button
					variant='contained'
					className={classes.button}
					onClick={startPurchaseByCsv}
				>
					CSVファイルから購入
				</Button>
				<input
					type='file'
					accept='.csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values'
					ref={hiddenFileInput}
					onChange={handleCSV}
					className={classes.hiddenInput}
				/>
				<ConnectPurchaseDialog
					show={showConnectPurchaseDialog}
					buyer={'system-admin'}
					closeMethod={
						closeShowConnectPurchaseDialog
					}
					accountId={accountId}
					paramKey={paramKey}
					fileResult={fileResult}
					priceListPurchase={priceListPurchase}
				/>
			</div>
		</MuiThemeProvider>
	);
};

ConnectPurchaseButton.propTypes =
{
	classes           : PropTypes.object.isRequired,
	paramKey          : PropTypes.string.isRequired,
	email             : PropTypes.string.isRequired,
	defaultExpiryTerm : PropTypes.object.isRequired,
	priceList         : PropTypes.array.isRequired,
	priceListTerm     : PropTypes.array.isRequired,
	accountId         : PropTypes.string.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectPurchaseButton)));
