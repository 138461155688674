import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getDateJst = (timestamp) =>
{
	return dayjs
		.unix(timestamp / 1000)
		.utcOffset(540)
		.locale('ja')
		.format('MM/DD HH:mm');
};

const convertSecondsToMinutes = (seconds) =>
{
	if (seconds === 0) return '0 分 00 秒';
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;

	return `${minutes} 分 ${String(remainingSeconds).padStart(2, '0')} 秒`;
};

export const createRowsServiceHistory = ({ timestamp, calls }) =>
{
	const targetMonth = dayjs.unix(timestamp / 1000).month();
	const targetYear = dayjs.unix(timestamp / 1000).year();

	if (calls)
	{
		return calls
			.filter((call) =>
			{
				const callDate = dayjs.unix(call.timestamp / 1000);

				return callDate.month() === targetMonth && callDate.year() === targetYear;
			})
			.map((call) =>
			{
				return {
					timestamp : getDateJst(call.timestamp),
					country   : call.country,
					skill     : call.skillId,
					subdomain : call.subdomain,
					duration  : convertSecondsToMinutes(call.duration)
				};
			});
	}
	else
	{
		return [];
	}
};
