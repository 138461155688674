import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import { FormattedMessage } from 'react-intl';

// material ui
import { Box, Typography } from '@material-ui/core';

// Daysjs
import dayjs from 'dayjs';

const styles = () =>
	({
		joinButton : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			position       : 'relative',
			borderRadius   : '5px',
			boxShadow      : '#CCC 5px 5px 5px',
			fontSize       : '1.2rem',
			minWidth       : '130px',
			aspectRatio    : '1',
			'&:hover'      : {
				filter : 'saturate(105%) brightness(105%)'
			},
			'&:only-child' : {
				marginRight : 'auto !important',
				marginLeft  : 'auto !important'
			}
		},
		buttonWithImage : {
			height         : '100%',
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		buttonImgBox : {
			width          : 'auto',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			height         : 'auto',
			flexGrow       : 1,
			maxHeight      : '150px'
		},
		buttonImg : {
			width  : 'auto',
			height : '100%'
		},
		buttonText : {
			width          : 'calc( 100% - 4px )',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		joinButtonText : {
			width     : '100%',
			textAlign : 'center'
		},
		btnMessage : {
			backgroundColor : '#00B5A5'
		},
		btnMessageText : {
			width     : '100%',
			textAlign : 'center',
			color     : '#FFF'
		},
		businessHoursLine : {
			position   : 'absolute',
			margin     : 'auto',
			left       : '50%',
			transform  : 'translateX(-50%)',
			whiteSpace : 'nowrap',
			bottom     : '-26px'
		},
		businessHoursText : {
			fontSize : '1rem',
			color    : '#292929'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const getWidthFromString = (string) =>
{
	// padding値を分割（空白で区切る）
	const paddingValues = string.trim().split(/\s+/);

	// paddingの形式に応じて左右の値を特定
	let valueLeft = 0;

	let valueRight = 0;

	switch (paddingValues.length)
	{
		case 1: // すべての方向が同じ値: "10px"
			valueLeft = parseFloat(paddingValues[0]);
			valueRight = parseFloat(paddingValues[0]);
			break;
		case 2: // 上下、左右: "10px 20px"
			valueLeft = parseFloat(paddingValues[1]);
			valueRight = parseFloat(paddingValues[1]);
			break;
		case 3: // 上、左右、下: "10px 20px 15px"
			valueLeft = parseFloat(paddingValues[1]);
			valueRight = parseFloat(paddingValues[1]);
			break;
		case 4: // 上、右、下、左: "10px 20px 15px 25px"
			valueRight = parseFloat(paddingValues[1]);
			valueLeft = parseFloat(paddingValues[3]);
			break;
		default:
			valueLeft = 0;
			valueRight = 0;
	}

	// 合計値を返す
	return (valueLeft || 0) + (valueRight || 0);
};

const ServiceButton = ({
	classes,
	rowIndex,
	columnIndex,
	buttons,
	btnAreaContentVal,
	btnFlagConds,
	roomAvailable,
	handleJoin,
	nationalHolidaysList
}) =>
{

	const btnImageRef = useRef();
	const btnTextRef = useRef();

	const isWorkTime = (btn) =>
	{
		if (btn.workTimeSetting)
		{
			const now = dayjs();

			// dayjs => sunday 0 , userHolidy => monday 0
			let wkDay = now.weekday() - 1;

			if (wkDay === -1)
			{
				wkDay = 6;
			}

			// 曜日
			if (btn.btnHoliday[wkDay] === 1)
			{
				return false;
			}

			const year = now.year();
			const month = now.month()+1;
			const date = now.date();

			let isHoliday = false;

			if (btn.nationalHoliday)
			{
				nationalHolidaysList[btn.nationalHoliday-1].forEach((d) =>
				{
					if (year === d.year && month === d.month && date === d.day)
					{
						isHoliday = true;
					}
				});

				if (isHoliday)
				{
					return false;
				}
			}

			const openTimeString = `${year}-${month}-${date} ${btn.penTime}:00`;
			const closeTimeString = `${year}-${month}-${date} ${btn.loseTime}:00`;

			const openTimeObj = dayjs(openTimeString);
			const closeTimeObj = dayjs(closeTimeString);

			if (now > openTimeObj && now < closeTimeObj)
			{
				return true;
			}

			return false;
		}
		else
		{
			return true;
		}
	};

	const itemIndex = rowIndex*btnAreaContentVal.columns + columnIndex;

	let btnWidth = '80%';

	if (btnAreaContentVal)
	{
		const paddingWidth = getWidthFromString(btnAreaContentVal.btnPadding);
		const marginWidth = getWidthFromString(btnAreaContentVal.btnMargin);

		const widthPerBtn = 100/parseInt(btnAreaContentVal.columns);

		const btnWidthVal = Math.round((widthPerBtn - paddingWidth - marginWidth)*100)/100;

		btnWidth = `${btnWidthVal}%`;

	}

	const updateDimensions = useCallback(() =>
	{
		if (btnImageRef.current && btnTextRef.current)
		{
			const textHeight = btnTextRef.current.clientHeight;

			btnImageRef.current.style.height = `calc(100% - ${textHeight}px)`;
		}
	}, []);

	useEffect(() =>
	{
		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);

	}, [ updateDimensions ]);

	if (buttons[itemIndex])
	{
		const btnData = buttons[itemIndex];

		// btnFlagCondsで事前に決められたsubdomain, skillIdと一致した場合、
		// 決まったテキスト・イメージを使用
		const preDefinedBtnCond = btnFlagConds[btnData.subdomain] ?
			btnFlagConds[btnData.subdomain]
			: btnFlagConds[btnData.skillId] ?
				btnFlagConds[btnData.skillId]
				: null;

		return (
			<MuiThemeProvider theme={theme}>
				{
					btnData.message ?
						<Box
							key={columnIndex}
							style={{
								padding
								: btnAreaContentVal.btnPadding,
								margin : btnAreaContentVal.btnMargin,
								width  : btnWidth
							}}
							className={`${classes.joinButton} ${classes.btnMessage}`}
						>
							<Typography
								className={classes.btnMessageText}
								style={{
									fontSize : `min(${btnAreaContentVal.btnFontSize}, 26px)`
								}}
							>
								<FormattedMessage
									id='connect.serviceTime1'
									defaultMessage='24 hours'
								/>
							</Typography>
							<Typography
								className={classes.btnMessageText}
								style={{
									fontSize : `min(${btnAreaContentVal.btnFontSize}, 26px)`
								}}
							>
								<FormattedMessage
									id='connect.serviceTime2'
									defaultMessage='365 days a year'
								/>
							</Typography>
						</Box>
						:
						<Box
							key={columnIndex}
							style={{
								backgroundColor : `#${btnData.btnColor}`,
								padding
								: btnAreaContentVal.btnPadding,
								margin : btnAreaContentVal.btnMargin,
								width  : btnWidth,
								cursor : 'pointer'
							}}
							className={classes.joinButton}
							onClick={() => handleJoin(itemIndex)}
							disabled={!isWorkTime(btnData)}
						>
							<Box className={classes.buttonWithImage}>
								{ btnData.imageUrl &&
									<Box
										ref={btnImageRef}
										className={
											classes.buttonImgBox
										}
									>
										<img
											alt={preDefinedBtnCond?.alt || 'button Icon'}
											src={btnData.imageUrl}
											className={classes.buttonImg}
											onLoad={updateDimensions}
										/>
									</Box>
								}
								<Box
									ref={btnTextRef}
									className={
										classes.buttonText
									}
								>
									<Typography
										className={classes.joinButtonText}
										style={{
											color    : `#${btnData.textColor}`,
											fontSize : `min(${btnAreaContentVal.btnFontSize}, 26px)`
										}}
									>
										{
											btnData.btnText ?
												btnData.btnText
												: preDefinedBtnCond?.text ?
													preDefinedBtnCond.text
													: ''
										}
									</Typography>
									<Typography
										className={
											classes.joinButtonText
										}
										style={{
											color    : roomAvailable[itemIndex] && btnData.textColorAvailable ? `#${btnData.textColorAvailable}`:`#${btnData.textColor}`,
											fontSize : `min(${btnAreaContentVal.btnFontSizeSecond}, 18px)`
										}}
									>
										{
											roomAvailable[itemIndex] ?
												<FormattedMessage
													id='service.englishStatus1'
													defaultMessage='Available Now'
												/>
												:
												<FormattedMessage
													id='service.englishStatus2'
													defaultMessage='Crowded Now'
												/>
										}
									</Typography>
								</Box>
							</Box>

							{ btnData.businessHours &&
							<div
								className={classes.businessHoursLine}
							>
								<Typography
									className={classes.businessHoursText}
								>
									<FormattedMessage
										id='service.englishReception'
										defaultMessage='BUSINESS HOURS'
									/>&nbsp;:&nbsp;
									{btnData.businessHours}
								</Typography>
							</div>
							}
						</Box>
				}
			</MuiThemeProvider>
		);
	}
	else
	{
		return null;
	}
};

ServiceButton.propTypes =
{
	classes              : PropTypes.object.isRequired,
	rowIndex             : PropTypes.number,
	columnIndex          : PropTypes.number,
	buttons              : PropTypes.array.isRequired,
	btnAreaContentVal    : PropTypes.object.isRequired,
	btnFlagConds         : PropTypes.object.isRequired,
	roomAvailable        : PropTypes.array.isRequired,
	handleJoin           : PropTypes.func.isRequired,
	nationalHolidaysList : PropTypes.array

};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceButton)));
